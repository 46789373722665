import React, { useState, useEffect } from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import axios from "axios"
import { config } from '../../utils/config';
import CombinationTableRowsCreate from "./CombinationTableRowsCreate"

import CombinationTableRows from './CombinationTableRows';

const btnStyle = {
    color: "white",
    width: "100px",
    borderRadius: "20px"
}


export default function VairantTables({ productTypeNmae, id, setdataSet, dataSet, datatype, submitFun, variatType, productIdVariantsData, tableData, settableData, hasVariants, product, upDateVariantsHandler, manDatoryAttr, getVariantsData, handleOpen, handleClose, deletHandlerWhileCreate }: any) {
    console.log("tableData-----", tableData)
    console.log("dataSet-----", dataSet)
    console.log("hasVariants-----", hasVariants)

    const { apiEndpoint, awsKeyId, awsSecretKey, awsRegion, awsBucket } = config;
    const [inputValues, setInputValues] = React.useState<any>({});
    const [iopen, setiopen] = React.useState(false);
    const [productimageIndex, setproductImageIndex] = useState<any>([]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>, index: string | number, combination: any) => {
        const { name, value } = e.target;
        let y = combination[index];
        const variantArray: any = [];

        for (const key in y) {
            if (Object.hasOwnProperty.call(y, key)) {
                variantArray.push({
                    variant_value: y[key],
                    variant_name: key
                });
            }
        }

        settableData((prevTableData: any) => {
            const updatedTableData: any = [...prevTableData];
            updatedTableData[index] = {
                ...updatedTableData[index],
               [name]: value, variant_group: variantArray.map((item :any, i: any) => {
                    return {...item, attr_id: updatedTableData[index]?.variant_group[i]?.attr_id }
                })
            };
            return updatedTableData;
        });

    };

    const handlePrice = (e: React.ChangeEvent<HTMLInputElement>, index: string | number, size: string) => {
        const { name, value } = e.target;
        if (product.tax_included) {
            settableData((prevTableData: any) => {
                const updatedTableData: any = [...prevTableData];

                updatedTableData[index] = {
                    ...updatedTableData[index], selling_price: value, tax_included_price: value

                };
                return updatedTableData;
            })
        } else {
            const percentAmmount = (parseInt(product.tax) / 100) * parseInt(value);
            settableData((prevTableData: any) => {
                const updatedTableData: any = [...prevTableData];

                updatedTableData[index] = {
                    ...updatedTableData[index], selling_price: value, tax_included_price: percentAmmount + parseInt(value)

                };
                return updatedTableData;
            })
        }
    };

    const [isDeleting, setisDeleting] = useState(false);

    async function deletHandlerWhileEdit(indexItem: any, index: any) {
        setisDeleting(true);
        handleClose()
        let updatedTableData = [...tableData];
        updatedTableData.splice(index, 1);
        const elementtoremove: any = document.getElementById(`row_${index}`);
        elementtoremove.remove();
        if (id === undefined) {
            setisDeleting(false)
            return
        }

        try {
            const { data } = await axios({
                url: `${apiEndpoint}/deleteVarattr`,
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
                },
                method: "POST",
                data: {
                    id: indexItem?.id
                }
            })
            if (data.status === 200) {
                setisDeleting(false)
                toast.success("Variants Deleted!");

            } else {
                setisDeleting(false)
                toast.error("Something Went Wrong!")
            }
        } catch (err) {
            setisDeleting(false)
            toast.error("Something Went Wrong!")
        }

    }


    const aws = require("aws-sdk");

    const productImageHandler = async (e: any, index: any) => {

        try {
            const files = e.target.files;

            const uploadPromises = Array.from(files).map(async (file: any) => {
                let myFile = file.name.split(".");
                let myFileType = myFile[myFile?.length - 1];

                const s3 = new aws.S3({
                    accessKeyId: awsKeyId,
                    secretAccessKey: awsSecretKey,
                    region: awsRegion
                });

                const params = {
                    Bucket: "ondc-marketplace",
                    Key: `images/${uuidv4()}.${myFileType}`,
                    Body: file,
                    ContentType: file.type
                };

                let result = await s3.upload(params).promise();
                return result.Location;
            });

            const locations = await Promise.all(uploadPromises);
            settableData((prev: any) => {
                let q = [...prev];

                if (q?.length === 0) {
                    q[index] = { ...q[index], image: [...locations] }
                } else {
                    if (q[index].hasOwnProperty('image')) {
                        q[index] = { ...q[index], image: [...q[index]?.image, ...locations] }
                    } else {
                        q[index] = { ...q[index], image: [...locations] }
                    }

                    console.log("productimageIndex------>", productimageIndex)
                    if (productimageIndex?.length > 0) {
                        let t = [...productimageIndex];
                        t[0].image = [...q[index].image]
                    }

                }


                // setproductImageIndex([v[imageIndex],imageIndex ])
                return q
            })

        } catch (exception) {
            toast.error("Error in Adding Image")
            console.log(exception);

        }
    };


    const handleImageOpen = (item: any, index: string | number) => { setiopen(true); setproductImageIndex([item, index]) };
    const handleimageClose = () => setiopen(false);

    function removeImageHandler(index: any, imageIndex: any) {
        let v = [...tableData];
        let t = [...productimageIndex];
        t[0].image.splice(index, 1)
        let y = v.findIndex((item) => item.variant_value === t[0].variant_value);
        v[y].image = t[0].image;
        settableData([...v])
        setproductImageIndex([...t])
    }

    const combinations: any = [];
    const dataSetKeys = Object.keys(dataSet);
    const nonEmptyProperties = dataSetKeys.filter(key => dataSet[key]?.length > 0);

    // Generate combinations
    const generateCombinations = (currentCombination: any, index: any) => {

        if (index === nonEmptyProperties.length) {
            combinations.push({ ...currentCombination });
            return;
        }
        const currentProperty = nonEmptyProperties[index];
        dataSet[currentProperty].forEach((value: any) => {
            currentCombination[currentProperty] = value;
            generateCombinations({ ...currentCombination }, index + 1);
        });
    };

    generateCombinations({}, 0);

    function indexd(combination: any) {
        let d = tableData?.findIndex((item: any, index: any) => {
            const hasColour = item?.variant_group.some((entry: any) => entry.variant_value === combination?.colour);
            const hasSize = item?.variant_group.some((entry: any) => entry.variant_value === combination?.size);
            if (hasColour && hasSize) {
                return true
            }
        })
        return d
    }
    console.log("nonEmptyProperties-----", nonEmptyProperties)
    console.log("combinations-----", combinations)
    return (
        <div className='mt-3'>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 350 }}>
                    {!isDeleting ? <Table stickyHeader aria-label="sticky table">

                        {(Object.values(dataSet).some((property: any) => property?.length > 0)) ? <TableHead >
                            <TableRow>
                                <TableCell key="variant_value" align="center">Variant Value</TableCell>
                                <TableCell key="mrp" align="center">MRP (To Show Price)</TableCell>
                                <TableCell key="selling_price" align="center">Selling Price</TableCell>
                                <TableCell key="tax_included_price" align="center">Tax Included Price</TableCell>
                                <TableCell key="quantity" align="center">Quantity</TableCell>
                                <TableCell key="sku" align="center">SKU</TableCell>
                                <TableCell key="reference_id" align="center">Reference ID</TableCell>
                                <TableCell key="hsn" align="center">HSN</TableCell>
                                <TableCell key="uom" align="center">UOM</TableCell>
                                <TableCell key="weight" align="center"> Weight (gram)    </TableCell>
                                {
                                    manDatoryAttr?.map((item: any) => {
                                        return <TableCell key="action" align="center"> {item?.mandatory_attribute} </TableCell>
                                        //  <div className="col-md-4">
                                        //     <div className="form-group">
                                        //         <label className='genericlabel'>{item?.mandatory_attribute}</label>
                                        //         <span className="text-danger" > * </span>
                                        //         <input type="text" className="form-control" id=""
                                        //             name={`${item?.mandatory_attribute}`}                                                        
                                        //             placeholder={`Enter ${item?.mandatory_attribute}`}

                                        //         />
                                        //     </div>
                                        // </div>
                                    })


                                }
                                <TableCell key="image" align="center"> Image </TableCell>
                                <TableCell key="state_price" align="center"> State Price </TableCell>
                                <TableCell key="action" align="center"> Action </TableCell>

                            </TableRow>
                        </TableHead> : null}

                        {/* -------------------------FOR COMBINATION START----------------------------------- */}

                        {hasVariants === "true" && <> {(Object.values(dataSet).some((property: any) => property?.length > 0)) ? <> {combinations.map((combination: any, index: any) => {
                            console.log("code is coming to line 278")
                            function shouldRenderLineItem() {
                                console.log("combination?.colour---------", combination);
                                if (combination.colour && combination.size) {
                                    return tableData?.some((item: any) => {
                                        const hasColour = item?.variant_group.some((entry: any) => entry.variant_value === combination?.colour);
                                        const hasSize = item?.variant_group.some((entry: any) => entry.variant_value === combination?.size);

                                        return hasColour && hasSize;
                                    }) || false; // Ensure that `false` is returned if `tableData` is undefined or empty.
                                }

                                if (combination.colour && !combination.size) {
                                    return tableData?.some((item: any) => {
                                        const hasColour = item?.variant_group.some((entry: any) => entry.variant_value === combination?.colour);
                                        // const hasSize = item?.variant_group.some((entry: any) => entry.variant_value === combination?.size);

                                        return hasColour;
                                    }) || false;
                                }

                                if (!combination.colour && combination.size) {
                                    return tableData?.some((item: any) => {
                                        const hasSize = item?.variant_group.some((entry: any) => entry.variant_value === combination?.size);
                                        // const hasSize = item?.variant_group.some((entry: any) => entry.variant_value === combination?.size);

                                        return hasSize;
                                    }) || false;
                                }
                                if (combination?.variant) {
                                    return tableData?.some((item: any) => {
                                        const hasVariant = item?.variant_group.some((entry: any) => entry.variant_value === combination?.variant);
                                        // const hasSize = item?.variant_group.some((entry: any) => entry.variant_value === combination?.size);

                                        return hasVariant;
                                    }) || false;
                                }

                            }


                            if (shouldRenderLineItem()) {
                                console.log("code is coming to line 290")
                                return <TableRow key={index} id={`row_${index}`} >
                                    <TableCell align="center" style={{ position: "relative" }}>
                                        {nonEmptyProperties.map(property => combination[property]).join('/')}
                                    </TableCell>
                                    <CombinationTableRows
                                        manDatoryAttr={manDatoryAttr} inputValues={inputValues} combinations={combinations}
                                        combination={combination} handleChange={handleChange}
                                        productImageHandler={productImageHandler} product={product}
                                        tableData={tableData} productTypeNmae={productTypeNmae}
                                        index={index} handleImageOpen={handleImageOpen} removeImageHandler={removeImageHandler}
                                        handleimageClose={handleimageClose}
                                        iopen={iopen} productimageIndex={productimageIndex} handlePrice={handlePrice}
                                    />
                                    <TableCell align="center">
                                        <DeleteIcon style={{ color: "red", cursor: "pointer" }} onClick={() => deletHandlerWhileEdit(tableData[indexd(combination)], index)} />
                                    </TableCell>
                                </TableRow>
                            }
                        })}</> : null} </>}


                        {hasVariants === null && <> {(Object.values(dataSet).some((property: any) => property?.length > 0)) ? <> {combinations.map((combination: any, index: any) => {

                            return <TableRow key={index} id={`row_${index}`} >
                                <TableCell align="center" style={{ position: "relative" }}>
                                    {nonEmptyProperties.map(property => combination[property]).join('/')}
                                </TableCell>
                                <CombinationTableRowsCreate
                                    manDatoryAttr={manDatoryAttr} inputValues={inputValues} combinations={combinations} handleChange={handleChange}
                                    productImageHandler={productImageHandler} product={product} tableData={tableData} productTypeNmae={productTypeNmae}
                                    index={index} handleImageOpen={handleImageOpen} removeImageHandler={removeImageHandler}
                                    handleimageClose={handleimageClose} iopen={iopen} productimageIndex={productimageIndex} handlePrice={handlePrice}
                                />
                                <TableCell align="center">
                                    <DeleteIcon style={{ color: "red", cursor: "pointer" }} onClick={() => deletHandlerWhileCreate(tableData[index], index)} />
                                </TableCell>
                            </TableRow>

                        })}</> : null} </>}

                        {/* -------------------------FOR COMBINATION END----------------------------------- */}


                    </Table> : "Please wait"}
                </TableContainer>

            </Paper>

            <div className='d-flex justify-content-end'>
                {hasVariants !== "true" ?

                    <button className='btn mt-3' style={{ background: "#105293", ...btnStyle }} onClick={() => { submitFun(tableData, variatType, "create") }}>Submit</button> :

                    <button className='btn btn-success  mt-3' style={btnStyle} onClick={() => upDateVariantsHandler(tableData, variatType)} >Update</button>}
            </div>

        </div>
    )
}
