/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/rules-of-hooks */
// import styles from "../transaction/transaction.module.css"
import axios from "axios";
import { baseUrlDefiner } from "../../utils/config";
import React, { useState, useEffect, memo } from "react";
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";
import Variants from "../../components/Variants/Variants";
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Papa from 'papaparse';
import ImageAdd from '../../components/ImageAdd/ImageAdd';
import ThemeMaker from "../../components/thememaker";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import * as XLSX from 'xlsx';
import * as excelJs from 'exceljs';
import CircularProgress from '@mui/material/CircularProgress';
import styles from "./style.module.css";
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import { Multiselect } from "multiselect-react-dropdown"
import { IndianStates } from "../../utils/rawdata";
import { SizeData, ColourData, FabricData, daysData, UOMData, MaterialData, ShoeSizedata, SizeDataBoyGirl } from '../../utils/rawdata';
const { apiEndpoint, awsKeyId, awsSecretKey, awsRegion, awsBucket } = config;
const aws = require("aws-sdk");
const smstyle = {
  position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "80%", bgcolor: 'background.paper', boxShadow: 24, borderRadius: "10px", p: 1, maxHeight: 600, overflow: "scroll", color: "black",
};
const sPmstyle = {
  position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "80%", bgcolor: 'background.paper', boxShadow: 24, borderRadius: "10px", p: 1, overflow: "scroll", color: "black",
};
const Sstyle = {
  position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', borderRadius: "10px", bgcolor: 'background.paper', border: '1px solid #000', boxShadow: 24, p: 2,
};
const anchorStyle = { fontSize: "12px", textDecoration: "underline", cursor: "pointer" }

const createProduct = () => {
  // const fullUrl = window.location.host;
  const decode = tokenDecode();
  let current_sellerid = decode.data.user.seller_id;
  const [brandlist, setbrandlist] = useState([]);
  const [sampleCSVdata, setSampleCSV] = useState({ category: "", product_type: "", sub_category: "", product_type_array: [], gender_specification: "" })
  const [mopen, setmopen] = React.useState(false);
  const [StateBasedPriceingopen, setStateBasedPriceingopen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [iopen, setiopen] = React.useState(false);
  const [featureopen, setfeatureopen] = React.useState(false);
  const [categoryOption, setCategoryOption] = React.useState("");
  const handleOpen = () => setmopen(true);
  const handleImageOpen = () => setiopen(true);
  const handleFeaturesOpen = () => setfeatureopen(true);
  const handleClose = () => setmopen(false);
  const handleStateBasedPricingModalClose = () => setStateBasedPriceingopen(false);
  const handleimageClose = () => setiopen(false);
  const handleFeaturesClose = () => setfeatureopen(false);

  const [attributesState, setattributeState] = useState({
    data: [],
    attr_is_loading: false,
    submitting: false,
    parent_category_id: "",
    product_type_id: "",
    editing_attr_id: "",
    editing_attr_value: "",
    adding_new_attr: false,
    new_attr_value: "",
    new_attr_options: "",
    new_attr_type: "",
    stateName: "",
    price: "",
    status: false,
    start_date: "",
    end_date: "",
    mrp: "",
    end_date: "",
    isUpdatingItem: false,
    makeDisable: false
  })

  const [Oweekdays, setOweekdays] = useState([
    ...IndianStates
  ])
  const [selectedODays, setSelectedODays] = useState();


  const returnWindowLength = new Array(1, 2, 3, 4, 5, 6, 7, 8, 9, 10);
  const timeToShipLength = new Array(1, 2, 3, 4, 5, 6, 7, 8, 9, 10);
  const [taxData, setTaxData] = useState([]);
  const [readOnly, setreadOnly] = useState(false);
  const rawData = {
    seller_id: decode?.data?.user?.seller_id,
    name: "",
    pc_manufacturer_or_packer_name: "",
    quantity: 0,
    short_description: "",
    description: "",
    // categories_id: Subcategorydata.parent_id,
    mrp: 0,
    selling_price: 0,
    tax_included_price: 0,
    tax: 0,
    tags: "",
    image: [],
    sku: "",
    hsn: "",
    position: 1,
    status: true,
    returnable: "",
    cancelable: "",
    return_window: "",
    seller_pickup_return: "",
    time_to_ship: "",
    cod: "",
    product_type: "",
    pc_imported_product_country_of_origin: "",
    pf_brand_owner_FSSAI_license_no: "",
    tax_included: true,
    country_of_origin: "",
    uom: "",
    customer_care_contact: "",
    replaceable: "",
    // brand: "",
    category_id: "",
    // product_type_name: "",
    pc_common_or_generic_name_of_commodity: "",
    pc_manufacturer_or_packer_address: "",
    pc_month_year_of_manufacture_packing_import: "",
    has_variations: false,
    reference_id: "",
    weight: "",
    features: "",
    transfer_price: ""



  };
  const rawDataError = {
    pc_month_year_of_manufacture_packing_import: false,
    pc_common_or_generic_name_of_commodity: false,
    pc_manufacturer_or_packer_address: false,
    product_type_name: false,
    category_id: false,
    name: false,
    short_description: false,
    description: false,
    categories_id: false,
    net_qty: false,
    pc_manufacturer_or_packer_name: false,
    quantity: false,
    mrp: false,
    selling_price: false,
    tax_included_price: false,
    tax: false,
    customer_care_contact: false,
    tags: false,
    image: false,
    sku: false,
    hsn: false,
    position: false,
    status: false,
    food_type_veg: false,
    returnable: false,
    cancelable: false,
    return_window: false,
    seller_pickup_return: false,
    time_to_ship: false,
    cod: false,
    product_type: false,
    preservatives: false,
    preservatives_details: false,
    flavours_and_spices: false,
    ready_to_cook: false,
    ready_to_eat: false,
    rice_grain_type: false,
    recommended_age: false,
    baby_weight: false,
    absorption_duration: false,
    scented_or_flavour: false,
    herbal_or_ayurvedic: false,
    theme_or_occasion_type: false,
    hair_type: false,
    mineral_source: false,
    caffeine_content: false,
    capacity: false,
    pf_brand_owner_FSSAI_license_no: false,
    tax_included: false,
    country_of_origin: false,
    uom: false,
    replaceable: false,
    brand: false,
    has_variations: false,
    reference_id: false,
    weight: false,
    pc_imported_product_country_of_origin: false

  };
  const [file, setFile] = useState(null);
  const [shipmentModalOpen, setshipmentModalOpen] = useState(false);
  const [downloadCSVModalOpen, setdownloadCSVModalOpen] = useState(false);
  const [productTypes, setproductTypes] = useState([]);
  const [product, setProduct] = useState(rawData);
  // console.log("productstate----------->", product)
  const [productErr, setProductErr] = useState(rawDataError);
  // const [allDynamicField, setallDynamicFiled] = useState({
  //   gender: "",
  //   size_chart: "",
  //   fabric: ""
  // })
  const [categoryList, setCategoryList] = useState([]);
  const [productTypeName, setproductTypeName] = useState("")
  let params = useParams();
  const { id } = params;
  const fullUrl = window.location.host;

  const [productIdVariantsData, setproductIdVariantsData] = useState([])
  useEffect(() => {
    taxListHanler(); categoryListing(); brandlisting(); getproductTypes()
    if (id) {
      getProduct();
    } else {
      getadditionaldetails()
    }
  }, []);

  const categoryListing = async () => {

    try {
      const { data } = await axios({
        url: `${apiEndpoint}/getcategories`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
        },
      });

      if (data.status === 200) {
        setCategoryList(data.response);

      }
    } catch (error) { }
  };


  async function getproductTypes() {

    try {

      const { data } = await axios({
        url: `${apiEndpoint}/getProtypeList`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
        },

      })

      if (data.status === 200) {
        setproductTypes(data?.data)

      } else {
        toast.error("Error In Loading Product Type");

      }
    } catch (err) {

      toast.error("Error in API")
    }
  }

  async function brandlisting() {

    try {
      const { data } = await axios({
        url: `${apiEndpoint}/getBrandList`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
        },
      })

      if (data.status === 200) {
        setbrandlist(data.data)
      }
    } catch (err) {
      console.log(err)
    }
  }

  async function getadditionaldetails() {

    const { data } = await axios({
      url: `${apiEndpoint}/sellerShop`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
      },

    });

    if (data.status === 200) {
      let adddata = data.response;
      setProduct({
        ...product, cod: adddata.cod, time_to_ship: adddata.time_to_ship, replaceable: adddata.replaceable, returnable: adddata.returnable, cancelable: adddata.cancelable, return_window: adddata.return_window,
        seller_pickup_return: adddata.seller_pickup_return
      })
    }
  }

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const hasVariants = urlParams?.get('q');


  const getProduct = async () => {

    try {
      const { data } = await axios({
        url: `${apiEndpoint}/getVarNonvarpro`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
        },
        data: {
          "has_variation": hasVariants,
          "id": id
        },
      });

      if (data.status === 200) {
        if (hasVariants == "false") {
          let imageArray = data.data[0].image.split(",");
          setProduct({ ...data.data[0], image: imageArray });
          const features = JSON.parse(JSON.parse(data?.data[0]?.features));
          let mandatoryAttributes = Object.keys(features)?.map((item) => { return { mandatory_attribute: item } });
          setmanDatoryAttr([...mandatoryAttributes]);
          setmanDatoryAttrValue(features);
          setreadOnly(true);

        } else {
          setProduct({ ...data.data[0] });
          let { product_type, category_id } = data?.data[0];
          getMandAttribute(product_type, category_id)

        }

      }
    } catch (error) { }
  };

  const [isVariantsLoading, setisVariantsLoading] = useState(false)
  async function getVariantsData() {

    setisVariantsLoading(true)
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/getvardata`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
        },
        data: {
          "has_variation": true,
          "product_id": id
        }
      });

      if (data.status === 200) {
        //manupulating the received data for features
        let modifiedData = data?.data?.map(item => {
          if (item?.features) {
            let { ...features } = item?.features;
            return { ...item, ...features }
          } else {
            return { ...item }
          }

        })
        setproductIdVariantsData(modifiedData);
        handleOpen(); //open the variation modal.
      }
      setisVariantsLoading(false)

    } catch (error) {
      setisVariantsLoading(false)
    }
  }


  const handleInput = async (e, operation) => {

    e.preventDefault();
    const showErrorToast = (fieldName) => {
      toast.error(`Please Enter '${fieldName}'!`);
    };


    let strgifiedFeatures;
    if (product.has_variations == false) {
      strgifiedFeatures = JSON.stringify(manDatoryAttrValue)
    } else if (product.has_variations == true) {
      manDatoryAttr.forEach(item => {
        product?.line_items?.forEach(entry => {
          // Iterate over keys of entry object
          Object.keys(entry).forEach(key => {
            if (item?.mandatory_attribute === key) {
              let obj = {};
              obj[key] = entry[key]; // Create an object with the matched key and its value

              // Add obj to entry.features array
              if (!entry.features) {
                entry.features = []; // Initialize features array if it doesn't exist
              }
              entry.features.push(obj);

            }
          });
        });
      });
    }

    let lineItems = [];
    if (product?.line_items?.length > 0) {
      lineItems = product?.line_items
      let stringifiedFeaturesLineItems = lineItems?.map(item => {
        return { ...item, features: JSON.stringify(item.features) }
      });
      lineItems = stringifiedFeaturesLineItems
    }

    const validateFields = () => {
      for (const field in product) {
        if (field !== "features" && field !== "transfer_price" && field !== "reference_id" && field !== "hsn" && field !== "quantity" && field !== "sku" && field !== "uom" && field !== "weight" && field !== "pf_brand_owner_FSSAI_license_no") {
          if (product.hasOwnProperty(field) && product[field] === "") {
            showErrorToast(field);
            return false;
          }
        }

      }
      return true;
    };

    if (product.has_variations == false) {
      if (!product.hasOwnProperty("image") || product.image.length === 0) {
        toast.error("Please select Images!");
        return false;
      }
    }

    const product_name = productTypes.filter((item) => item.id == product.product_type);
    const categoryName = categoryList.filter((item) => item.id == product.category_id);

    if (manDatoryAttr?.length > 0 && product.has_variations == false) {
      for (let key in manDatoryAttrValue) {
        if (manDatoryAttrValue.hasOwnProperty(key)) {
          if (manDatoryAttrValue[key] === "" || manDatoryAttrValue[key] === undefined) {
            toast.error("Mandatory Attributes value is missing !")
            return
          }
        }
      }
    }
    if (categoryName[0]?.cat_name == "Grocery") {
      if ((product.pf_brand_owner_FSSAI_license_no === "")) {
        toast.error("FSSAI number is Required");
        return
      }

    }
    if (product?.has_variations && product?.line_items === undefined && id === undefined) {
      toast.error("Please Add Variants for the product!");
      return
    }
    let endpoint;
    if (operation === "create") {
      if (product.has_variations.toString() === "true") {
        endpoint = "createvarProducts"
      } else {
        endpoint = "createProducts"
      }
    }
    if (operation === "update") {
      if (product?.has_variations.toString() === "true") {
        endpoint = "variantUpdateProductsv2"
      } else {
        endpoint = "UpdateProductsv2"
      }
    }


    if (validateFields()) {
      try {

        const options = {
          method: "POST",
          url: `${apiEndpoint}/${endpoint}`,
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
          },
          data: { ...product, line_items: lineItems, source: "", product_type_name: product_name[0].name, features: strgifiedFeatures },
        };
        const data = await axios(options);
        if (data.status === 200) {
          setProduct(product);
          if (operation === "update") {
            toast.success("Product Updated successfully !");
          } else {
            toast.success("Product Created successfully !");
          }

        }
      } catch (error) {
        console.log(error);
        toast.error("Server error, please try later !");
      }
    } else {
      showErrorToast("all required fields");
    }

  };


  async function upDateVariantsHandler(tableData, variatType) {

    try {
      // console.log("tableData-----------", tableData)
      let line_items = tableData?.map((item) => ({ ...item, variant_group: item.variant_group }))

      let payload = { line_items: line_items, product_id: id, has_variations: true };

      manDatoryAttr.forEach(item => {
        payload?.line_items.forEach(entry => {
          // Iterate over keys of entry object
          Object.keys(entry).forEach(key => {
            if (item?.mandatory_attribute === key) {
              let obj = {};
              obj[key] = entry[key]; // Create an object with the matched key and its value
              // Add obj to entry.features array
              if (!entry.featuress) {
                entry.featuress = []; // Initialize features array if it doesn't exist
              }
              entry.featuress.push(obj);
            }
          });
        });
      });

      let lineItems = [];
      if (payload?.line_items?.length > 0) {
        lineItems = payload?.line_items
        let stringifiedFeaturesLineItems = lineItems?.map(item => {
          return { ...item, features: JSON.stringify(item.featuress) }
        });
        lineItems = stringifiedFeaturesLineItems
      }

      console.log("payload to update variants-------->", { ...payload, line_items: lineItems })
      const { data } = await axios({
        url: `${apiEndpoint}/variantUpdates`,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
        },
        method: "POST",
        data: { ...payload, line_items: lineItems }

      })
      if (data.status === 200) {
        toast.success("Variants Updated")
      } else {
        toast.error("Something Went Wrong!")
      }

    } catch (err) {
      console.log(err)
      toast.error("Something Went Wrong!")
    }
  }

  async function restoreData() {
    setisVariantsLoading(true)
    try {

      setproductIdVariantsData(product?.line_items);
      handleOpen();

      setisVariantsLoading(false)

    } catch (error) {
      setisVariantsLoading(false)
    }
  }


  const handleImport = async () => {

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet);
      console.log("json-------->", json)
      function commonFileds(item) {
        return {
          "variant_group_id": item?.variant_group_id,
          "name": item?.name,
          "pc_manufacturer_or_packer_name": item?.pc_manufacturer_or_packer_name,
          "short_description": item.description,
          "description": item?.description,
          "tax": 0,//check
          "tags": item?.tags,
          "hsn": item.hsn,
          "position": item?.position,
          "status": true,
          "returnable": item?.returnable,
          "cancelable": item?.cancelable,
          "return_window": item?.return_window,
          "seller_pickup_return": item?.seller_pickup_return,
          "time_to_ship": item?.time_to_ship,
          "cod": item?.cod,
          "product_type": item?.product_type,
          "product_type_name": item?.product_type_name,
          "pc_imported_product_country_of_origin": item?.pc_imported_product_country_of_origin,
          "pf_brand_owner_FSSAI_license_no": item?.pf_brand_owner_FSSAI_license_no,
          "tax_included": item?.tax_included,
          "country_of_origin": item?.country_of_origin,
          "customer_care_contact": item?.customer_care_contact,
          "replaceable": item?.replaceable,
          "brand": item?.brand,
          "category_id": item?.category_id,
          "pc_common_or_generic_name_of_commodity": item?.pc_common_or_generic_name_of_commodity,
          "pc_manufacturer_or_packer_address": item?.pc_manufacturer_or_packer_address,
          "pc_month_year_of_manufacture_packing_import": item?.pc_month_year_of_manufacture_packing_import,
          "has_variations": item.has_variations,
          "features": item?.features,
          "size_chart": item?.size_chart,
          "fabric": item?.fabric,
        }
      }

      function lineItems(item) {
        return {
          "mrp": item?.mrp,
          "selling_price": item.selling_price,
          "quantity": item?.quantity,
          "sku": item?.sku,
          "reference_id": item?.reference_id,
          "uom": item?.uom,
          "weight": item?.weight,
          "image": [item?.image],
          "variant_name": item?.variant_name,
          "variant_value": item?.variant_value
        }
      }
      let k = [];
      json.forEach((item) => {
        let v = k.some((product) => product.variant_group_id === item.variant_group_id);

        if (item.has_variations == true) {
          if (v) {
            let index = k.findIndex((obj) => obj.variant_group_id === item.variant_group_id);

            k[index].line_items.push({
              ...lineItems(item)
            })
          } else {
            k.push({
              ...commonFileds(item),
              "line_items": [
                {
                  ...lineItems(item)
                }
              ],
            })
          }
        } else {
          if (item.variant_group_id !== null) {
            k.push({ ...commonFileds(item) })
          }
        }

      })

      console.log("kkkk------", k)
      let dataToSend = json.filter(item => item.name !== null).map((item => {
        let tts = daysData.filter(items => items.name === item.time_to_ship)

        let rw = daysData.filter(items => items.name === item.return_window)

        let colourCode;
        if (item?.colour) {
          colourCode = ColourData.filter(items => items.name === item.colour);
        }



        return {
          // ...k
          // ...item,
          // time_to_ship: tts[0]?.value,
          // return_window: rw[0]?.value,
          // colour: colourCode ? colourCode[0]?.value : "#ffff",
          // colour_name: colourCode ? colourCode[0]?.name : "white"
        }
      }))
      sendDataToDb(dataToSend);
      // let jsonData = {
      //   orders: json
      // };
    };
    reader.readAsArrayBuffer(file);

  };
  const handleImportForProdXpressbazaar = async () => {
    try {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

          // Get the headers from the first row of the sheet
          const headers = json[0];

          // Convert the rest of the sheet to JSON format
          const rows = XLSX.utils.sheet_to_json(worksheet);

          let errors = [];
          let dataToSend = rows.filter(item => item.name !== null).map((item) => {
            // Check for missing fields, excluding image2, image3, and image4
            const missingFields = headers.filter(header => {
              return (item[header] === null || item[header] === undefined) &&
                !['image1', 'image2', 'image3', 'image4', 'pf_brand_owner_FSSAI_license_no', 'transfer_price'].includes(header);
            });

            if (missingFields.length > 0) {
              const errorMessage = `Error in SKU: ${item.sku} - Missing fields: ${missingFields.join(', ')}`;
              errors.push(errorMessage);
              console.log(errorMessage);
              return null; // Skip this item in dataToSend
            }

            let tts = daysData.filter(items => items.name === item.time_to_ship);
            let rw = daysData.filter(items => items.name === item.return_window);
            let colourCode = item?.colour ? ColourData.filter(items => items.name === item.colour) : null;

            return {
              ...item,
              time_to_ship: tts[0]?.value,
              return_window: rw[0]?.value,
              // colour: colourCode ? colourCode[0]?.value : "#ffff",
              // colour_name: colourCode ? colourCode[0]?.name : "white"
            };
          }).filter(item => item !== null); // Filter out invalid items

          if (errors.length > 0) {

            const fileData = JSON.stringify(errors, null, 2);
            const blob = new Blob([fileData], { type: 'text/plain' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.download = 'error.txt';
            link.href = url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            toast.error("Found error in excel! Error file is downloaded.")
          } else {
            sendDataToDb(dataToSend);
            console.log("datatosend -----------", dataToSend)
          }

        };
        reader.readAsArrayBuffer(file);
      }
    } catch (error) {
      console.error('Error handling import:', error.message);
    }
  };


  //-----------------------  THIS THE NEW FNCTION TO IMPORT THE PRODUCT--------------------------------------------


  const handleImportMulti = async () => {

    // console.log(file)
    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      complete: ({ data }) => {
        // console.log(data)
        let abc = data.filter(item => item.name !== null)
        let x = abc.map(item => {
          return {
            "name": item.name,
            "category_name": item.category_name,
            "product_type": item.product_type,
            "short_description": item.short_description,
            "description": item.description,
            "image": item.image,
            "image1": item.image1,
            "image2": item.image2,
            "image3": item.image3,
            "image4": item.image4,
            "image5": item.image5,
            "image6": item.image6,
            "image7": item.image7,
            "tags": item.tags,
            "net_qty": item.net_qty,
            "quantity": item.quantity,
            "country_of_origin": item.country_of_origin,
            "product_type_name": item.product_type_name,
            "weight": item.weight,
            "brand": item.brand,
            "returnable": item.returnable,
            "cancelable": item.cancelable,
            "return_window": item.return_window,
            "seller_pickup_return": item.seller_pickup_return,
            "time_to_ship": item.time_to_ship,
            "cod": item.cod,
            "replaceable": item.replaceable,
            "mrp": item.mrp,
            "transfer_price": item.transfer_price,
            "selling_price": item.selling_price,
            "customer_care_contact": item.customer_care_contact,
            "hsn": item.hsn,
            "sku": item.sku,
            "tax": item.tax,
            "tax_included": item.tax_included,
            "tax_included_price": item.tax_included_price,
            "uom": item.uom,
            "gender": item.gender,
            "colour": item.colour,
            "size": item.size,
            "size_chart": item.size_chart,
            "fabric": item.fabric,
            "has_variations": item.has_variations,
            "pc_manufacturer_or_packer_name": item.pc_manufacturer_or_packer_name,
            "pc_common_or_generic_name_of_commodity": item.pc_common_or_generic_name_of_commodity,
            "pc_manufacturer_or_packer_address": item.pc_manufacturer_or_packer_address,
            "pc_imported_product_country_of_origin": item.pc_imported_product_country_of_origin,
            "features": item.features,
            "variant_group_id": item.variant_group_id
          }
        })

        // console.log("xxxxxxxxxxxx------>", x)
        sendDataToDb(x)
      },
    });
  };

  async function sendDataToDb(jsonData) {

    let apE;
    if (fullUrl === "multipleselleradmin.thesellerapp.com") {
      apE = "importProducts"
    } else {
      apE = "importxpProducts"
    }
    try {
      setLoading(true);
      let result = await axios({
        url: `${apiEndpoint}/${apE}`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,

        },
        data: { "orders": jsonData },

      })
      setLoading(false);
      if (result.data.status == 200) {
        toast.success("Products added successfully !");
        setFile(null)
        document.getElementById('inputFile').value = ''
      } else {
        toast.error("Server Not Working, Please try later!");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Server Not Working, Please try later!");
    }


  }
  function handleShipementModalClose() {
    setshipmentModalOpen(false)
  }

  const errorHandler = (e) => {
    if (e.target.value) {
      setProductErr({ ...productErr, [e.target.name]: false });
    } else {
      setProductErr({ ...productErr, [e.target.name]: true });
    }
  };

  const taxListHanler = async () => {

    try {
      const { data } = await axios({
        url: `${apiEndpoint}/gettaxlist`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
        },
      });
      // console.log("tax listing", data);
      if (data.status === 200) {
        setTaxData(data.data);
      }
    } catch (error) { }
  };

  const priceHandler = (e) => {
    // console.log("e.target.name", e.target.name);
    if (e.target.name === "selling_price") {
      const percentAmmount =
        (parseFloat(product.tax) / 100) * parseFloat(e.target.value);
      setProduct((preState) => ({
        ...preState,
        [e.target.name]: parseFloat(e.target.value),
        tax_included_price: product?.tax_included
          ? e.target.value
          : parseFloat(e.target.value) + parseFloat(percentAmmount.toFixed(4)),
      }));
    } else if (e.target.name === "tax") {
      const percentAmmount =
        (parseFloat(e.target.value) / 100) * parseFloat(product.selling_price);
      setProduct((preState) => ({
        ...preState,
        [e.target.name]: parseFloat(e.target.value),
        tax_included_price: product?.tax_included
          ? product?.tax_included_price
          : parseFloat(product.selling_price) +
          parseFloat(percentAmmount.toFixed(4)),
      }));
    } else if (e.target.name === "tax_included") {
      const percentAmmount =
        (parseFloat(product.tax) / 100) * parseFloat(product.selling_price);
      setProduct({
        ...product,
        [e.target.name]: !product?.tax_included,
        tax_included_price: !product?.tax_included
          ? product.selling_price
          : percentAmmount + parseFloat(product.selling_price),
      });
    }
  };

  const productImageHandler = async (e, index) => {

    try {
      const files = e.target.files;

      const uploadPromises = Array.from(files).map(async (file) => {
        let myFile = file.name.split(".");
        let myFileType = myFile[myFile.length - 1];

        const s3 = new aws.S3({
          accessKeyId: awsKeyId,
          secretAccessKey: awsSecretKey,
          region: awsRegion
        });

        const params = {
          Bucket: "ondc-marketplace",
          Key: `images/${uuidv4()}.${myFileType}`,
          Body: file,
          ContentType: file.type
        };

        let result = await s3.upload(params).promise();
        return result.Location;
      });

      const locations = await Promise.all(uploadPromises);
      // Update state with all image locations
      setProduct((prevProduct) => ({
        ...prevProduct,
        image: [...prevProduct.image, ...locations]
      }));
    } catch (exception) {
      toast.error("Error in Adding Product Image");
      console.log(exception);
    }
  };


  const preventMinus = (e) => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  };
  async function submitFun(tableData, variantType, operation) {
    let line_items = tableData?.map((item) => ({ ...item, variant_group: item.variant_group }))
    setProduct({ ...product, line_items })
    toast.success("Variations Added!!")
  }


  function removeImageHandler(index, removeImageHandler) {
    // alert(index);
    let z = [...product.image]
    z.splice(index, 1)
    setProduct({ ...product, image: [...z] })
  }

  console.log("product--------->", product)

  const [optionValues, setoptionValues] = useState([0]);
  const [featureKeyValue, setfeatureKeyValue] = useState([{ code: "", value: "" }])

  function addHandler() {
    let x = [...optionValues, optionValues[optionValues.length - 1] + 1];
    setoptionValues((count) => [...count, count[count.length - 1] + 1]);

    setfeatureKeyValue((item) => [...item, { code: "", value: "" }])
  }

  function featureChangeHandler(index, e) {

    let z = [...featureKeyValue];
    z[index] = { ...z[index], [e.target.name]: e.target.value };
    setfeatureKeyValue([...z])
  }

  function onFeatureSaveHandler() {

    setProduct((prev) => {
      return { ...prev, features: JSON.stringify(featureKeyValue) }
    })

    toast.success("Feature Added! Please update the Product to save.")
  }


  const SizeChartImageHandler = async (e) => {

    try {
      const file = e.target.files[0];
      let myFile = file.name.split(".");
      let myFileType = myFile[myFile.length - 1];
      const s3 = new aws.S3({
        accessKeyId: "AKIAYJUL5VJOBYQMJDT7",
        secretAccessKey: "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"
      })
      const params = {
        Bucket: "ondc-marketplace",
        Key: `images/${uuidv4()}.${myFileType}`,
        Body: file,
        ContentType: file?.type
      }
      let result = await s3.upload(params).promise();
      const { Location } = result;


      // setallDynamicFiled((prev) => {
      //   return { ...prev, [e.target.name]: Location }
      // })

    } catch (exception) {
      toast.error("Error in Adding Image")
      console.log(exception);
    }
  };

  async function getSampleCsv() {

    try {

      const { data } = await axios({
        url: `${apiEndpoint}/download_product_csv`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`
        },
        data: {
          "product_category": sampleCSVdata?.category,
          "product_type": sampleCSVdata?.product_type
        }
      })
      if (data?.status == "200") {
        const headers = Object.keys(data?.data[0]); // Use the keys of the first object as headers
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(data?.data, { header: headers });
        XLSX.utils.book_append_sheet(wb, ws, 'Orders');
        const fileName = `Sample_ProductSheet_${new Date().getTime()}.csv`;
        XLSX.writeFile(wb, fileName);
        toast.success("(CSV File downloaded, please fill the CSV file and import the products)");
        setdownloadCSVModalOpen(false);
        setSampleCSV({ category: "", product_type: "", sub_category: "", product_type_array: [] })
      } else {
        toast.error("CSV not present for this category at this time, Please try later!")
      }
    } catch (err) {
      toast.error("Server not working, please try later!")
      console.log(err)
    }
  }


  async function getProductTypeforSelectedCategory(id) {

    try {

      const { data } = await axios({
        url: `${apiEndpoint}/get_product_types`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`
        },
        data: {
          product_category_name: id
        }
      })
      if (data?.status == "200") {
        setSampleCSV((prev) => {
          return { ...prev, product_type_array: data?.data }
        })
      }
    } catch (err) {
      toast.error("Server not working, please try later!")
      console.log(err)
    }

  }

  function determineSizeFilter() {
    if (sampleCSVdata.sub_category == "Formal Shoes" || sampleCSVdata.sub_category == "Casual Shoes" || sampleCSVdata.sub_category == "Sports Shoes" || sampleCSVdata.sub_category == "Boots" || sampleCSVdata.sub_category == "Outdoor Shoes" || sampleCSVdata.sub_category == "Work & Safety Shoes" || sampleCSVdata.sub_category == "Ethnic Shoes" || sampleCSVdata.sub_category == "Sandals & Floaters") {
      let shoeData = ShoeSizedata.map(name => ({ name }));
      return shoeData
    }

    if (sampleCSVdata?.gender_specification === "male" || sampleCSVdata?.gender_specification === "female") {
      let size = SizeData.map(name => ({ name }));
      return size
    }

    if (sampleCSVdata?.gender_specification === "boy" || sampleCSVdata?.gender_specification === "girl") {

      let size = SizeDataBoyGirl.map(name => ({ name }));
      return size

    }
  }


  let colours = ColourData.map(item => ({ name: item?.name }));
  let fabrics = FabricData.map(name => ({ name }));
  let uom = UOMData.map(name => ({ name }));
  let material = MaterialData.map(name => ({ name }));

  const mockStoreData = [
    { options: [{ name: "true" }, { name: "false" }] },
    { options: [{ name: '1 Day' }, { name: '2 Days' }, { name: '3 Days' }, { name: '4 Days' }, { name: '5 Days' }, { name: '6 Days' }, { name: '7 Days' }, { name: '8 Days' }, { name: '9 Days' }, { name: '10 Days' }] },
    { options: [...colours] },
    { options: determineSizeFilter() },
    { options: [{ name: "male" }, { name: "female" }, { name: "boy" }, { name: "girl" }, { name: "unisex" }, { name: "infant" }] },
    { options: [...fabrics] },
    { options: [...uom] },
    { options: [...material] },
    { options: [{ name: "yes" }, { name: "no" }] }
  ];
  const generateTemplate = async () => {
    try {

      const { data } = await axios({
        url: `${apiEndpoint}/download_product_csv`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`
        },
        data: {
          "product_category": sampleCSVdata?.category,
          "product_type": sampleCSVdata?.product_type
        }
      })
      if (data?.status == 200) {
        const headers = Object.keys(data?.data[0]); // Use the keys of the first object as headers
        const workbook = new excelJs.Workbook();
        const ws = workbook.addWorksheet('Test Worksheet');
        // Using mock data for myStore1, myStore2, and myStore3
        const options1 = mockStoreData[0]?.options?.map((opt) => opt.name);
        const options2 = mockStoreData[1]?.options?.map((opt) => opt.name);
        const options3 = mockStoreData[2]?.options?.map((opt) => opt.name);
        const options4 = mockStoreData[3]?.options?.map((opt) => opt.name);
        const options5 = mockStoreData[4]?.options?.map((opt) => opt.name);
        const optionsForFabric = mockStoreData[5]?.options?.map((opt) => opt?.name);
        const optionsForUOM = mockStoreData[6]?.options?.map((opt) => opt?.name);
        const optionsForMaterial = mockStoreData[7]?.options?.map((opt) => opt?.name);
        const optionsForPrescription = mockStoreData[8]?.options?.map((opt) => opt.name);
        // Add data to the worksheet
        ws.addRow([...headers]);
        ws.columns.map((col, index) => (col.width = 18));

        const headerRow = ws.getRow(1);
        const headerNamesToValidateforBoolean = ['replaceable', 'cod', 'returnable', 'cancelable', 'seller_pickup_return', 'tax_included', 'has_variations', 'is_inidividual_ship_charge'];
        const headerNamesToValidateforDays = ['time_to_ship', 'return_window'];
        const headerNamesToValidateforColour = ['colour',];
        const headerNamesToValidateforSize = ['size',];
        const headerNamesToValidateforGender = ['gender',];
        const headerNamesToValidateforFabric = ['fabric',];
        const headerNamesToValidateforUOM = ['uom',];
        const headerNamesToValidateforMaterial = ['material',];
        const headerNamesToValidateforYesNo = ['prescription_required',];
        const columnIndicesToValidate = {};
        const columnIndicesToValidateDays = {};
        const columnIndicesToValidateColour = {};
        const columnIndicesToValidatesize = {};
        const columnIndicesToValidategender = {};
        const columnIndicesToValidatefabric = {};
        const columnIndicesToValidateuom = {};
        const columnIndicesToValidatematerial = {};
        const columnIndicesToValidateYesNo = {};



        headerRow.eachCell((cell, index) => {
          if (headerNamesToValidateforBoolean.includes(cell.value)) {
            columnIndicesToValidate[cell.value] = index;
          }
          if (headerNamesToValidateforDays.includes(cell.value)) {
            columnIndicesToValidateDays[cell.value] = index;
          }
          if (headerNamesToValidateforColour.includes(cell.value)) {
            columnIndicesToValidateColour[cell.value] = index;
          }
          if (headerNamesToValidateforSize.includes(cell.value)) {
            columnIndicesToValidatesize[cell.value] = index;
          }
          if (headerNamesToValidateforGender.includes(cell.value)) {
            columnIndicesToValidategender[cell.value] = index;
          }
          if (headerNamesToValidateforFabric.includes(cell.value)) {
            columnIndicesToValidatefabric[cell.value] = index;
          }
          if (headerNamesToValidateforUOM.includes(cell.value)) {
            columnIndicesToValidateuom[cell.value] = index;
          }
          if (headerNamesToValidateforMaterial.includes(cell.value)) {
            columnIndicesToValidatematerial[cell.value] = index;
          }
          if (headerNamesToValidateforYesNo.includes(cell.value)) {
            columnIndicesToValidateYesNo[cell.value] = index;
          }
        });

        // Iterate over the default data and add it to the corresponding columns in the worksheet
        data.data.forEach((rowData, rowIndex) => {
          Object.keys(rowData).forEach((columnName) => {
            if (columnName == "category_name") {
              const cell = ws.getCell(rowIndex + 2, 2);
              cell.value = rowData[columnName];
            }
            if (columnName == "product_type_name") {
              const cell = ws.getCell(rowIndex + 2, 14);
              cell.value = rowData[columnName];
            }
            if (columnName == "product_type") {
              const cell = ws.getCell(rowIndex + 2, 15);
              cell.value = rowData[columnName];
            }
            const columnIndex = columnIndicesToValidate[columnName];
            if (columnIndex !== undefined) {
              const cell = ws.getCell(rowIndex + 2, columnIndex);
              cell.value = rowData[columnName]; // Add default data to the cell
            }
          });
        });

        // Apply data validation for each desired column
        headerNamesToValidateforBoolean.forEach((headerName) => {
          decider(columnIndicesToValidate, options1, headerName)

        });
        headerNamesToValidateforDays.forEach((headerName) => {
          decider(columnIndicesToValidateDays, options2, headerName)

        });
        headerNamesToValidateforColour.forEach((headerName) => {
          decider(columnIndicesToValidateColour, options3, headerName)
        });
        headerNamesToValidateforSize.forEach((headerName) => {
          decider(columnIndicesToValidatesize, options4, headerName)
        });
        headerNamesToValidateforGender.forEach((headerName) => {
          decider(columnIndicesToValidategender, options5, headerName)
        });
        headerNamesToValidateforFabric.forEach((headerName) => {
          decider(columnIndicesToValidatefabric, optionsForFabric, headerName)
        });
        headerNamesToValidateforUOM.forEach((headerName) => {
          decider(columnIndicesToValidateuom, optionsForUOM, headerName)
        });
        headerNamesToValidateforMaterial.forEach((headerName) => {
          decider(columnIndicesToValidatematerial, optionsForMaterial, headerName)
        });
        headerNamesToValidateforYesNo.forEach((headerName) => {
          decider(columnIndicesToValidateYesNo, optionsForPrescription, headerName)
        });

        function decider(columnIndices, options, headerName) {
          const columnIndex = columnIndices[headerName];
          if (columnIndex !== undefined) {
            function getColumnLetter(columnIndex) {
              let columnLetter = '';
              while (columnIndex > 0) {
                let remainder = (columnIndex - 1) % 26;
                columnLetter = String.fromCharCode(65 + remainder) + columnLetter;
                columnIndex = Math.floor((columnIndex - 1) / 26);
              }
              return columnLetter;
            }

            const columnLetter = getColumnLetter(columnIndex);
            const range = `${columnLetter}2:${columnLetter}99999`;
            ws?.dataValidations?.add(range, {
              type: 'list',
              allowBlank: false,
              formulae: [`"${options.join(',')}"`], // You can use options based on the header name
            });
          } else {
            console.error(`Column '${headerName}' not found.`);
          }
        }

        ws.getRow(1).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFADD8E6' },
        };

        ws.eachRow((row) => {
          row.eachCell((cell) => {
            cell.font = {
              name: 'Inter',
              size: 8,
            };
            cell.alignment = {
              horizontal: 'center',
            };
          });
        });

        setTimeout(async () => {
          const excelBlob = await workbook.xlsx.writeBuffer();
          const excelUrl = URL.createObjectURL(
            new Blob([excelBlob], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            })
          );
          const link = document.createElement('a');
          link.href = excelUrl; link.download = `product_sample_${sampleCSVdata?.category}_${sampleCSVdata?.sub_category}.xlsx`; document.body.appendChild(link); link.click();
          URL.revokeObjectURL(excelUrl); document.body.removeChild(link);
          toast.success("(CSV File downloaded, please fill the CSV file and import the products)");
          setdownloadCSVModalOpen(false);
          setSampleCSV({ category: "", product_type: "", sub_category: "", product_type_array: [] })

        }, 1000)
      } else {
        toast.error("CSV not present for this category at this time, Please try later!")
      }
    } catch (err) {
      toast.error("Server not working, please try later!")
      console.log(err)
    }
  }

  async function getProductTypeOnCategory(id) {

    try {
      setLoading(true)
      const { data } = await axios({
        url: `${apiEndpoint}/getProductTypeid`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`
        },
        data: {
          product_category_id: parseInt(id)
        }
      })
      if (data && (data.status === 200 || data.status === "200" || Number(data.status) === 200)) {
        setproductTypes(data?.data)
      } else {
        toast.error("Error In Loading Product Type");

      }
      // if (data?.status == "200") {
      //   setSampleCSV((prev) => {
      //     return { ...prev, product_type_array: data?.data }
      //   })
      // }
      setLoading(false)
    } catch (err) {
      toast.error("Server not working, please try later!")
      console.log(err)
    }

  }

  const getCategoryOption = async (e) => {
    setCategoryOption(e.target.selectedOptions[0].text)
  }

  const [manDatoryAttr, setmanDatoryAttr] = useState([]);
  const [manDatoryAttrValue, setmanDatoryAttrValue] = useState({})

  async function getMandAttribute(value, parent_category_id) {

    try {
      const { data } = await axios({
        url: `${apiEndpoint}/listmandattr`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`
        },
        data: {
          "parent_category_id": id ? parent_category_id : product?.category_id,
          "product_type_id": parseInt(value)
        }
      })
      if (data?.status == "200") {
        setmanDatoryAttr(data?.response);
        const initialAttributes = data?.response.reduce((acc, curr) => {
          acc[curr.mandatory_attribute] = "";
          return acc;
        }, {});

        // Set initial state
        setmanDatoryAttrValue(initialAttributes);
      }
    } catch (err) {
      toast.error("Server not working, please try later!")
      console.log(err)
    }

  }

  async function deletHandlerWhileCreate(indexItem, index) {
    handleClose();
    let v = product?.line_items;
    let updatedTableData = [...v];
    updatedTableData.splice(index, 1);
    setProduct((prev) => { return { ...prev, line_items: updatedTableData } })

  }

  useEffect(() => {
    const categoryId = product?.category_id;
    categoryId && getProductTypeOnCategory(categoryId);
  }, [product?.category_id]);

  function addMoreHandler() {
    let z = attributesState?.data?.map(item => {
      if (!item.price && !item.start_date && !item.end_date) {
        return { ...item, price: attributesState?.price, start_date: attributesState?.start_date, end_date: attributesState?.end_date, mrp: attributesState?.mrp }
      } else {
        return { ...item }
      }

    })
    setattributeState((prev) => { return { ...prev, stateName: "", price: "", data: [...z], start_date: "", end_date: "", mrp: "" } });
    setSelectedODays();
  }

  async function submitHandler(operation) {
    try {
      let z
      if (operation === "Update") {
        z = attributesState?.data?.map(item => {
          if (item.state_name === attributesState?.editing_attr_id) {
            return {
              ...item, price: attributesState?.editing_attr_value, start_date: attributesState.start_date, end_date: attributesState?.end_date, mrp: attributesState?.mrp
            }
          } else {
            return item
          }
        })
      } else {
        z = attributesState?.data?.map(item => {
          if (!item.price) {
            return { ...item, price: attributesState?.price, start_date: attributesState.start_date, end_date: attributesState?.end_date, mrp: attributesState?.mrp }
          } else {
            return { ...item }
          }

        })
      }

      setattributeState((prev) => { return { ...prev, stateName: "", price: "", data: [...z], editing_attr_id: "", editing_attr_value: "", start_date: "", end_date: "", submitting: true, mrp:""} });
      setSelectedODays();
      // let apiEndpoint = baseUrlDefiner();
      const { data } = await axios({
        url: `${apiEndpoint}/priceruleproduct`,
        method: "POST",
        headers: {
          "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`
        },
        data: {
          "allproducts": [
            {
              "id": id,
              "status": attributesState?.status,
              "has_variation": hasVariants === "true" ? true : false,
              "state": "Delhi",
              "compare_at_price": attributesState?.mrp,
              "selling_price": [...z],
              "validation_date": {
                "start_date": "2024-09-30",
                "end_date": "2024-09-31"
              }
            }]
        }
      })

      if (data?.status === 200) {
        toast.success("Added Successfully!");
      } else {
        toast.error("Something went wrong, please try later!")
      }
      setattributeState((prev) => { return { ...prev, submitting: false, } });

      console.log("data--------", data)
    } catch (err) {
      setattributeState((prev) => { return { ...prev, submitting: false, } });
      toast.error("Server error, please try later!")
    }
  }

  const onSelectHandler = (selectedList, selectedItem) => {
    console.log("selectedList-------", selectedList);
    console.log("selectedODays-------", selectedODays);
    let z = selectedList.map(item => { return { state_name: item.value } })
    setattributeState((prev) => {
      return {
        ...prev, data: [...prev.data, { state_name: selectedItem.value }],
      }
    })

    setSelectedODays(selectedList.map(item => item));
    // let daySelected = selectedList.map(item => item.id);
    // let z = daySelected.toString()

  }
  const onRemoveHandler = (selectedList, selectedItem) => {
    console.log("selectedList-------", selectedList)
    // let daySelected = selectedList.map(item => item.id);
    // let z = daySelected.toString()
    setSelectedODays(selectedList.map(item => item));
  }

  async function getStateBasedPriceData(id) {
    // let apiEndpoint = baseUrlDefiner();
    let payload;

    if (hasVariants === "true") {
      payload = {
        "variantid": "3baca8ba-44d2-4e64-9368-8ef44f1a1fef"
      }
    }

    if (hasVariants === "false") {
      payload = {
        "productid": product?.ondc_product_id

      }
    }
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/getpriceruleproduct`,
        method: "POST",
        headers: {
          "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`
        },
        data: payload
      })

      if (data?.status === 200) {
        setattributeState((prev) => {
          return { ...prev, status: data?.data[0]?.status, mrp: data?.data[0]?.compare_at_price, data: JSON.parse(data?.data[0]?.selling_price) }
        })
      }

      console.log(data)

    } catch (err) {
      toast.error("Server not working, please try later!")
      console.log(err)
    }
  }

  async function updateStatePriceRuleHandler() {
    let indexOfItem = attributesState?.data?.findIndex(item => item?.state_name === attributesState?.stateName);
    let z = [...attributesState?.data];
    z[indexOfItem] = { ...z[indexOfItem], price: attributesState?.price, start_date: attributesState.start_date, end_date: attributesState?.end_date, mrp: attributesState?.mrp };
    setattributeState((prev) => {
      return { ...prev, data: [...z], submitting: true, }
    })

    setSelectedODays();
    // let apiEndpoint = baseUrlDefiner();
    const { data } = await axios({
      url: `${apiEndpoint}/priceruleproduct`,
      method: "POST",
      headers: {
        "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`
      },
      data: {
        "allproducts": [
          {
            "id": id,
            "status": attributesState?.status,
            "has_variation": hasVariants === "true" ? true : false,
            "state": "Delhi",
            "compare_at_price": attributesState?.mrp,
            "selling_price": [...z],
            "validation_date": {
              "start_date": "2024-09-30",
              "end_date": "2024-09-31"
            }
          }]
      }
    })

    if (data?.status === 200) {
      toast.success("Updated Successfully!");
      setattributeState((prev) => { return { ...prev, stateName: "", price: "", data: [...z], editing_attr_id: "", editing_attr_value: "", start_date: "", end_date: "", submitting: false, mrp: "" } });
    } else {
      toast.error("Something went wrong, please try later!")
    }


    // setattributeState((prev) => {
    //   return {...prev, data: prev.data}
    // })
  }

  console.log("attributeState--------", attributesState)


  return (
    <ThemeMaker>
      <div className="row w-100 m-0 mt-1" >
        <div className="col-md-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3 d-flex justify-content-between">
              <h6 className="m-0 font-weight-bold text-primary">
                Create Product
                {/* <button onClick={() => generateTemplate()}>Download </button> */}
              </h6>

              <div className="d-flex justify-content-end align-items-center ">
                <span className="text-center" style={{ background: "lightgrey", borderRadius: "12px", fontSize: "14px", width: "150px", color: "black", fontWeight: "600", height: "27px", cursor: "pointer", paddingTop: "3px" }}
                  onClick={() => { setshipmentModalOpen(true) }}  >Import Products</span>
              </div>
            </div>

            <>
              <div className="card-body p-1" style={{ background: "#EAEFFF" }}>
                <div className="row ">
                  <div className="col-md-12 pl-2 pr-2">


                    <div className=' pt-2'>

                      <div className={styles.productContainer}>
                        <div className={styles.leftpart}>

                          <div className={styles.whiteContainer}>

                            <div className="col-md-12">
                              <h3>General Information</h3>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <label className='genericlabel'>Product Name </label>
                                <span className="text-danger" > * </span>
                                <input type="text" className="form-control" id="" name="name" value={product.name} onChange={(e) => setProduct({ ...product, [e.target.name]: e.target.value, })} onBlur={(e) => errorHandler(e)} placeholder="Enter Product Name"
                                />
                                {
                                  productErr?.name ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className='genericlabel'> Category  </label>
                                <span className="text-danger" > * </span>
                                <select name="category_id" value={product?.category_id} onChange={(e) => { setProduct({ ...product, [e.target.name]: parseInt(e.target.value) }); getProductTypeOnCategory(e.target.value); getCategoryOption(e); }}
                                  onBlur={(e) => errorHandler(e)}
                                  className="form-control"

                                >
                                  <option value="" disabled selected>Select Category</option>
                                  {categoryList.map(item => {
                                    return <option value={item.id}>{item.cat_name}</option>

                                  })}

                                </select>
                                {
                                  productErr?.category_id ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                              </div>
                            </div>

                            <div className="col-md-4 col-sm-12">
                              <div className="form-group">
                                <div className='d-flex justify-content-between align-item-center'>
                                  <div>
                                    <label className=" genericlabel">Product Type</label>
                                    <span className="text-danger" > * </span>
                                  </div>

                                  {loading ? <>
                                    <Box>
                                      <CircularProgress size={20} />
                                    </Box>
                                  </> : null}
                                </div>
                                <select name="product_type" id="" className='form-control' value={product.product_type}
                                  onChange={(e) => {
                                    setproductTypeName(e.target.selectedOptions[0].innerText); setProduct({
                                      ...product, [e.target.name]: parseInt(e.target.value),
                                    })
                                    getMandAttribute(e.target.value, product?.category_id)
                                  }}
                                  onBlur={(e) => errorHandler(e)}

                                >
                                  <option value="" disabled selected>Select Product Type</option>
                                  {productTypes?.map(item => {
                                    return <option value={item.id}>{item.name}</option>
                                  })}
                                </select>
                                {productErr?.product_type ? (
                                  <span className="text-danger tiny_size" > This field is required ! </span>
                                ) : null
                                }

                                {/* <select name="product_type" id="" className='form-control genericlabel' onChange={(e) => { setSampleCSV((prev) => { return { ...prev, [e.target.name]: e.target.value } }) }} >
                            <option value="" selected disabled>Select Product Type</option>
                            {
                              sampleCSVdata?.product_type_array.map((item, index) => {
                                return <option value={item.id} key={index} >{item.name}</option>
                              })
                            }
                          </select> */}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className='genericlabel'>Sell On ONDC</label>
                                <span className="text-danger" > * </span>

                                <select name="status" className="form-control" value={product.status} onChange={(e) => setProduct({ ...product, [e.target.name]: e.target.value })} onBlur={(e) => errorHandler(e)}>
                                  <option value={""}>Select Sell on ONDC</option>
                                  <option value={"true"}>Yes</option>
                                  <option value={"false"}>No</option>
                                </select>
                                {
                                  productErr?.status ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className='genericlabel'> Variants Available</label>
                                <span className="text-danger" > * </span>

                                {(product.has_variations && hasVariants == null) ? <span onClick={() => { restoreData() }} className='modalTrigerButton' >Add Variant</span> : null}

                                {(product.has_variations && hasVariants === "true") ? <span disabled={isVariantsLoading}
                                  onClick={() => { getVariantsData() }} className='modalTrigerButton'>{isVariantsLoading ? "Loading..." : "Edit Variants"}</span> : null}

                                {!product.has_variations ?
                                  <span className='modalTrigerButton' onClick={handleImageOpen} >Add Images
                                  </span> : null}

                                {/* {<span className='modalTrigerButton ml-2' onClick={handleFeaturesOpen} >Add Features</span>} */}

                                <select name="has_variations" className="form-control" value={product.has_variations}
                                  onChange={(e) =>
                                    setProduct({ ...product, [e.target.name]: e.target.value === "true" })
                                  }
                                  onBlur={(e) => errorHandler(e)}>
                                  <option value={"true"}>Yes</option>
                                  <option value={"false"}>No</option>
                                </select>
                                {
                                  productErr?.has_variations ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                              </div>
                            </div>

                          </div>

                          {
                            (manDatoryAttr?.length > 0 && product.has_variations == false) && <>
                              <div className={`mt-3 ${styles.whiteContainer}`}>


                                <div className="col-md-12">
                                  <h3>Attribute Details</h3>
                                </div>

                                {manDatoryAttr?.map(item => {
                                  return <div className="col-md-4">
                                    <div className="form-group">
                                      <label className='genericlabel'>{item?.mandatory_attribute}</label>
                                      <span className="text-danger" > * </span>
                                      <input type="text" className="form-control" id=""
                                        onKeyPress={preventMinus} name={`${item?.mandatory_attribute}`} value={manDatoryAttrValue?.[item?.mandatory_attribute]}
                                        onChange={(e) => setmanDatoryAttrValue({ ...manDatoryAttrValue, [e.target.name]: e.target.value })}
                                        placeholder={`Enter ${item?.mandatory_attribute}`}

                                      />
                                    </div>
                                  </div>
                                })
                                }



                              </div>
                            </>
                          }

                          <div className={`mt-3 ${styles.whiteContainer}`}>

                            <div className="col-md-12">
                              <h3>Manufacture Details</h3>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <label className='genericlabel'>Product Manufacturer Name </label>
                                <span className="text-danger" > * </span>
                                <input type="text" className="form-control"
                                  id="" name="pc_manufacturer_or_packer_name" value={product.pc_manufacturer_or_packer_name} onChange={(e) =>
                                    setProduct({ ...product, [e.target.name]: e.target.value })
                                  } onBlur={(e) => errorHandler(e)} placeholder="Enter Manufacturer Name"
                                />
                                {
                                  productErr?.pc_manufacturer_or_packer_name ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <label className='genericlabel'>Product Manufacturer Address </label>
                                <span className="text-danger" > * </span>
                                <input type="text" className="form-control" id=""
                                  name="pc_manufacturer_or_packer_address"
                                  value={product.pc_manufacturer_or_packer_address}
                                  onChange={(e) =>
                                    setProduct({ ...product, [e.target.name]: e.target.value })
                                  }
                                  onBlur={(e) => errorHandler(e)}
                                  placeholder="Enter Manufacturer Address"

                                />
                                {
                                  productErr?.pc_manufacturer_or_packer_address ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <label className='genericlabel'>Product Manufacturing Year</label>
                                <span className="text-danger" > * </span>
                                <input type="text" className="form-control" name="pc_month_year_of_manufacture_packing_import" value={product.pc_month_year_of_manufacture_packing_import} onChange={(e) =>
                                  setProduct({ ...product, [e.target.name]: e.target.value })}
                                  onBlur={(e) => errorHandler(e)} placeholder="Enter Manufacturing Year"
                                />
                                {
                                  productErr?.pc_month_year_of_manufacture_packing_import ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <label className='genericlabel'>Product Country Of Origin</label>
                                <span className="text-danger" > * </span>
                                <input type="text"
                                  className="form-control"
                                  name="pc_imported_product_country_of_origin"
                                  value={product.pc_imported_product_country_of_origin}
                                  onChange={(e) => setProduct({ ...product, [e.target.name]: e.target.value })} onBlur={(e) => errorHandler(e)}
                                  placeholder="Enter Product Country Of Origin"
                                />
                                {
                                  productErr?.pc_imported_product_country_of_origin ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <label className='genericlabel'>Common or Generic Product Name </label>
                                <span className="text-danger" > * </span>

                                <input type="text" className="form-control" id="" name="pc_common_or_generic_name_of_commodity" value={product.pc_common_or_generic_name_of_commodity} onChange={(e) => setProduct({ ...product, [e.target.name]: e.target.value, })} placeholder="Enter Common or Generic Product Name" onBlur={(e) => errorHandler(e)}

                                />
                                {
                                  productErr?.pc_common_or_generic_name_of_commodity ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                              </div>
                            </div>
                            {product.has_variations == false && <div className="col-md-4">
                              <div className="form-group">
                                <label className='genericlabel'>HSN Number</label>
                                <span className="text-danger" > * </span>
                                <input type="text" className="form-control" name="hsn" value={product.hsn}
                                  onChange={(e) =>
                                    setProduct({ ...product, [e.target.name]: e.target.value })
                                  } onBlur={(e) => errorHandler(e)} placeholder="Enter HSN Number"
                                />
                                {
                                  productErr?.hsn ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                              </div>
                            </div>}

                            <div className="col-md-12">
                              <div className="form-group">
                                <label className='genericlabel'>Product Short description </label>
                                <span className="text-danger" > * </span>
                                <textarea type="text" className="form-control" name="short_description" value={product.short_description}
                                  onChange={(e) =>
                                    setProduct({ ...product, [e.target.name]: e.target.value })
                                  } onBlur={(e) => errorHandler(e)} placeholder="Enter Short product description"
                                />
                                {
                                  productErr?.short_description ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="form-group">
                                <label className='genericlabel'>Product Long Description </label>
                                <span className="text-danger" > * </span>
                                <textarea type="text" className="form-control" name="description" value={product.description}
                                  onChange={(e) =>
                                    setProduct({ ...product, [e.target.name]: e.target.value })
                                  } onBlur={(e) => errorHandler(e)} placeholder="Enter Long Product Description"
                                />
                                {
                                  productErr?.description ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                              </div>
                            </div>

                          </div>

                          <div className={`mt-3 ${styles.whiteContainer}`}>
                            <div className="col-md-12">
                              <h3>Shipping</h3>
                            </div>


                            <div className="col-md-4">
                              <div className="form-group">
                                <label className='genericlabel'>Customer Care </label>
                                <span className="text-danger" > * </span>

                                <input type="text"
                                  className="form-control"
                                  name="customer_care_contact"
                                  value={product.customer_care_contact}
                                  onChange={(e) =>
                                    setProduct({ ...product, [e.target.name]: e.target.value })
                                  }
                                  onBlur={(e) => errorHandler(e)}
                                  placeholder="Enter Customer Care"

                                />
                                {
                                  productErr?.customer_care_contact ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className='genericlabel'>Time To Ship </label>
                                <span className="text-danger" > * </span>

                                <select

                                  className="form-control"
                                  name="time_to_ship"
                                  value={product.time_to_ship}
                                  onChange={(e) =>
                                    setProduct({ ...product, [e.target.name]: e.target.value })
                                  }
                                  onBlur={(e) => errorHandler(e)}
                                >
                                  <option value="">Time to Ship</option>
                                  {timeToShipLength.map((item, index) => (
                                    <option value={`P${item}D`} key={index}>{item === 1 ? `${item}Day` : `${item}Days`}</option>
                                  ))}
                                </select>
                                {
                                  productErr?.time_to_ship ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className='genericlabel'>Product Returnable </label>
                                <span className="text-danger" > * </span>

                                <select
                                  className="form-control"
                                  name="returnable"
                                  value={product.returnable}
                                  onChange={(e) =>
                                    setProduct({ ...product, [e.target.name]: e.target.value })
                                  }
                                  onBlur={(e) => errorHandler(e)}
                                >
                                  <option value={""}>Product Returnable</option>
                                  <option value={"true"}>Yes</option>
                                  <option value={"false"}>No</option>
                                </select>
                                {
                                  productErr?.returnable ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className='genericlabel'> Product Return Window </label>
                                <span className="text-danger" > * </span>

                                <select className="form-control" name="return_window" value={product.return_window}
                                  onChange={(e) =>
                                    setProduct({ ...product, [e.target.name]: e.target.value })
                                  }
                                  onBlur={(e) => errorHandler(e)}
                                >
                                  <option value="">Select Return Window</option>
                                  {returnWindowLength.map((item, index) => (
                                    <option value={`P${item}D`} key={index}>{item == 1 ? `${item}Day` : `${item}Days`}</option>
                                  ))}
                                </select>
                                {
                                  productErr?.return_window ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <label className='genericlabel'>Product Cancellable </label>
                                <span className="text-danger" > * </span>

                                <select className="form-control" name="cancelable" value={product.cancelable}
                                  onChange={(e) =>
                                    setProduct({ ...product, [e.target.name]: e.target.value })
                                  } onBlur={(e) => errorHandler(e)}  >
                                  <option value={""}>Select Cancellable</option>
                                  <option value={"true"}>Yes</option>
                                  <option value={"false"}>No</option>
                                </select>
                                {
                                  productErr?.cancelable ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <label className='genericlabel'> Return Pickup Available </label>
                                <span className="text-danger" > * </span>

                                <select className="form-control" name="seller_pickup_return" value={product.seller_pickup_return}
                                  onChange={(e) =>
                                    setProduct({ ...product, [e.target.name]: e.target.value })
                                  } onBlur={(e) => errorHandler(e)}             >
                                  <option value={""}>Select Return Pickup Available</option>
                                  <option value={"true"}>Yes</option>
                                  <option value={"false"}>No</option>
                                </select>
                                {
                                  productErr?.seller_pickup_return ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <label className='genericlabel'> Product Replaceable </label>
                                <span className="text-danger" > * </span>

                                <select className="form-control" name="replaceable" value={product.replaceable}
                                  onChange={(e) => setProduct({ ...product, [e.target.name]: e.target.value })} onBlur={(e) => errorHandler(e)}>
                                  <option value={""}>Select Product Replaceable</option>
                                  <option value={"true"}>Yes</option>
                                  <option value={"false"}>No</option>
                                </select>
                                {
                                  productErr?.replaceable ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <label className='genericlabel'>Cash On Delivery (COD) </label>
                                <span className="text-danger" > * </span>

                                <select name="cod" className="form-control" value={product.cod}
                                  onChange={(e) => setProduct({ ...product, [e.target.name]: e.target.value })}
                                  onBlur={(e) => errorHandler(e)}>
                                  <option value={""}>Select Cash on delivery</option>
                                  <option value={"true"}>Yes</option>
                                  <option value={"false"}>No</option>
                                </select>
                                {
                                  productErr?.cod ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                              </div>
                            </div>


                          </div>
                        </div>

                        <div className={styles.rightpart}>

                          <div className={` ${styles.whiteContainer}`}>
                            <div className="col-md-12 d-flex align-items-center mb-3 justify-content-between" >
                              <h3>Pricing</h3>
                              {hasVariants !== "true" && <span className="text-center" style={{ background: "green", borderRadius: "12px", fontSize: "14px", width: "150px", color: "white", fontWeight: "600", height: "27px", cursor: "pointer", paddingTop: "3px" }} onClick={() => { setStateBasedPriceingopen(true); getStateBasedPriceData(id) }}>Price rule</span>}
                            </div>

                            {product.has_variations == false ? <div className="col-md-12">
                              <div className="form-group">
                                <label className='genericlabel'>Selling price  </label>
                                <span className="text-danger" > * </span>

                                <small> &nbsp; Tax included</small>
                                <input type="checkbox" name="tax_included" style={{ marginLeft: "10px" }} onChange={(e) => priceHandler(e)} checked={product?.tax_included} />

                                {fullUrl !== "multipleselleradmin.thesellerapp.com" && <input type="number" className="form-control" min={0} name="selling_price" placeholder="Selling Price" value={product.selling_price} onChange={(e) => priceHandler(e)} onBlur={(e) => errorHandler(e)} onWheel={(e) => e.target.blur()} />}

                                {fullUrl === "multipleselleradmin.thesellerapp.com" && <input type="number" className="form-control" min={0} name="selling_price" placeholder="Selling Price" value={product.selling_price} onChange={(e) => priceHandler(e)} onBlur={(e) => errorHandler(e)} onWheel={(e) => e.target.blur()}
                                  readOnly={id === undefined ? false : (id && current_sellerid === 1) ? false : true}
                                />}

                                {
                                  productErr?.selling_price ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                              </div>
                            </div> : null}

                            {product.has_variations == false ? <div className="col-md-6">
                              <div className="form-group">
                                <label className='genericlabel'>Tax included price</label>


                                <input type="number" className="form-control"
                                  disabled
                                  name="tax_included_price"
                                  placeholder=""
                                  value={product.tax_included_price}
                                  onWheel={(e) => e.target.blur()}

                                />
                              </div>
                            </div> : null}

                            <div className="col-md-6">
                              <div className="form-group">
                                <label className='genericlabel'>Tax ( % ) </label>
                                {/* <span className="text-danger" > * </span> */}
                                {product.has_variations == true ? <> <small> &nbsp; Tax included</small>
                                  <input type="checkbox" name="tax_included" style={{ marginLeft: "10px" }} onChange={(e) => priceHandler(e)} checked={product?.tax_included}
                                  /></> : null}

                                {fullUrl !== "multipleselleradmin.thesellerapp.com" && <select className="form-control" name="tax"
                                  placeholder="Tax" value={product.tax} onChange={(e) => priceHandler(e)} onBlur={(e) => errorHandler(e)} >
                                  <option value="">Select tax</option>
                                  {taxData.map((item) => (
                                    <option value={item?.tax_rate} key={item.id}>{item?.tax_rate}</option>
                                  ))}
                                </select>}
                                {fullUrl === "multipleselleradmin.thesellerapp.com" && <select className="form-control" name="tax"
                                  placeholder="Tax" value={product.tax} onChange={(e) => priceHandler(e)} onBlur={(e) => errorHandler(e)}
                                  disabled={id === undefined ? false : (id && current_sellerid === 1) ? false : true}
                                >
                                  <option value="">Select tax</option>
                                  {taxData.map((item) => (
                                    <option value={item?.tax_rate} key={item.id}>{item?.tax_rate}</option>
                                  ))}
                                </select>}
                                {
                                  productErr?.tax ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                              </div>
                            </div>

                            {product.has_variations == false ? <div className="col-md-6">
                              <div className="form-group">
                                <label className='genericlabel'>To Show Price (MRP) </label>
                                <span className="text-danger" > * </span>

                                {fullUrl !== "multipleselleradmin.thesellerapp.com" && <input type="number" className="form-control" onKeyPress={preventMinus} min={0} name="mrp" placeholder="Enter Max price" value={product.mrp ? product.mrp : product.price} onChange={(e) => setProduct({
                                  ...product,
                                  [e.target.name]: e.target.value,
                                })
                                }
                                  onBlur={(e) => errorHandler(e)}
                                  onWheel={(e) => e.target.blur()}
                                />}
                                {fullUrl === "multipleselleradmin.thesellerapp.com" && <input type="number" className="form-control" onKeyPress={preventMinus} min={0} name="mrp" placeholder="Enter Max price" value={product.mrp ? product.mrp : product.price} onChange={(e) => setProduct({
                                  ...product,
                                  [e.target.name]: e.target.value,
                                })
                                }
                                  onBlur={(e) => errorHandler(e)}
                                  onWheel={(e) => e.target.blur()}
                                  readOnly={id === undefined ? false : (id && current_sellerid === 1) ? false : true}
                                />}
                                {
                                  productErr?.mrp ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                              </div>
                            </div> : null}

                            {fullUrl === "multipleselleradmin.thesellerapp.com" && <div className="col-md-6 pr-0">
                              <div className="form-group">
                                <label className='genericlabel'> Cost Price (Transfer Price) </label>

                                <input type="number" name="transfer_price" className="form-control" onKeyPress={preventMinus} min={0} placeholder="Enter Transfer price" value={product?.transfer_price} onChange={(e) => setProduct({
                                  ...product,
                                  [e.target.name]: e.target.value,
                                })
                                } onBlur={(e) => errorHandler(e)}
                                  onWheel={(e) => e.target.blur()}
                                  readOnly={id === undefined ? false : (id && current_sellerid === 1) ? false : true}
                                />
                              </div>
                            </div>}



                          </div>

                          <div className={`mt-3 ${styles.whiteContainer}`}>
                            <div className="col-md-12">
                              <h3>Other Details</h3>
                            </div>

                            {product.has_variations == false && <div className="col-md-5">
                              <div className="form-group">
                                <label className='genericlabel'>Product Weight</label>
                                <span className="text-danger" > * </span>

                                <input type="text" className="form-control" id=""
                                  onKeyPress={preventMinus} name="weight" value={product.weight} onChange={(e) => setProduct({ ...product, [e.target.name]: e.target.value, })} placeholder="Enter Weight" onBlur={(e) => errorHandler(e)}

                                />
                                {
                                  productErr?.weight ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                              </div>
                            </div>}

                            {product.has_variations == false && <div className="col-md-7 pl-1 pr-0">
                              <div className="form-group">
                                <label className='genericlabel'>Unit Of Measurement (UOM) </label>
                                <span className="text-danger" > * </span>
                                <select name="uom" id="" className='form-control' value={product.uom}
                                  onChange={(e) => setProduct({ ...product, [e.target.name]: e.target.value, })} onBlur={(e) => errorHandler(e)}  >
                                  <option value="" disabled selected>Select UOM</option>
                                  <option value="dozen">Dozen</option>
                                  <option value="gram">Gram</option>
                                  <option value="kilogram">Kilogram</option>
                                  <option value="tonne">Ton</option>
                                  <option value="litre">Litre</option>
                                  <option value="millilitre">Millilitre</option>
                                  <option value="unit">Unit</option>
                                </select>
                                {
                                  productErr?.uom ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                              </div>
                            </div>}



                            {(categoryOption === "Grocery" || product.category_id == 1) && (
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className='genericlabel'>FSSAI License Number</label>
                                  <span className="text-danger">*</span>
                                  {/* {productErr?.pf_brand_owner_FSSAI_license_no && (
                              <span className="text-danger tiny_size">This field is required!</span>
                            )} */}
                                  <input type="text" className="form-control" id="" onKeyPress={preventMinus} name="pf_brand_owner_FSSAI_license_no"
                                    value={product.pf_brand_owner_FSSAI_license_no}
                                    onChange={(e) => setProduct({ ...product, [e.target.name]: e.target.value })} placeholder="Enter FSSAI License Number"
                                    onBlur={(e) => errorHandler(e)}
                                  />
                                </div>
                              </div>
                            )}


                            {product.has_variations == false && <div className="col-md-6">
                              <div className="form-group">
                                <label className='genericlabel'>SKU </label>
                                <span className="text-danger" > * </span>

                                <input type="text" className="form-control" id=""
                                  name="sku" value={product.sku} onChange={(e) =>
                                    setProduct({ ...product, [e.target.name]: e.target.value, })}
                                  placeholder="Enter SKU" onBlur={(e) => errorHandler(e)}

                                />
                                {
                                  productErr?.sku ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                              </div>
                            </div>}


                            <div className="col-md-6">
                              <div className="form-group">
                                <label className='genericlabel'>Brand</label>
                                {/* <span className="text-danger" > * </span>
                  {
                    productErr?.brand ? (
                      <span className="text-danger tiny_size" > This field is required ! </span>
                    ) : null
                  } */}
                                <select className="form-control" value={product.brand} name="brand" onChange={(e) => setProduct({
                                  ...product, [e.target.name]: e.target.value,
                                })} onBlur={(e) => errorHandler(e)}

                                >
                                  <option value="">Select Brand</option>
                                  {brandlist.map((item) => (
                                    <option value={item.id} key={item.id}>{item.brand_name}</option>
                                  ))}
                                </select>
                              </div>
                            </div>

                            {product.has_variations == false && <div className="col-md-6">
                              <div className="form-group">
                                <label className='genericlabel'>Inventory</label>
                                <span className="text-danger" > * </span>

                                <input type="text" className="form-control" name="quantity" value={product.quantity} onChange={(e) => setProduct({ ...product, [e.target.name]: e.target.value })} onBlur={(e) => errorHandler(e)} placeholder="Enter Quantity"

                                />
                                {
                                  productErr?.quantity ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                              </div>
                            </div>}


                            <div className="col-md-6">
                              <div className="form-group">
                                <label className='genericlabel'>Tags </label>
                                <span className="text-danger" > * </span>

                                <input type="text"
                                  className="form-control"
                                  name="tags"
                                  value={product.tags}
                                  onChange={(e) =>
                                    setProduct({ ...product, [e.target.name]: e.target.value })
                                  }
                                  onBlur={(e) => errorHandler(e)}
                                  placeholder="Enter Search Tags"

                                />
                                {
                                  productErr?.tags ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                              </div>
                            </div>


                            <div className="col-md-6">
                              <div className="form-group">
                                <label className='genericlabel'>Country of Origin </label>
                                <span className="text-danger" > * </span>

                                <input type="text" className="form-control"
                                  name="country_of_origin"
                                  value={product.country_of_origin}
                                  onChange={(e) =>
                                    setProduct({ ...product, [e.target.name]: e.target.value })
                                  }
                                  onBlur={(e) => errorHandler(e)}
                                  placeholder="Enter Country of Origin"

                                />
                                {
                                  productErr?.country_of_origin ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                              </div>
                            </div>



                            <div className="col-md-6">
                              <div className="form-group">
                                <label className='genericlabel'>Position</label>
                                <span className="text-danger" > * </span>

                                <input type="number" className="form-control" id=""
                                  onKeyPress={preventMinus} name="position" value={product.position} onChange={(e) => setProduct({ ...product, [e.target.name]: e.target.value, })} placeholder="Enter Position" onBlur={(e) => errorHandler(e)}
                                  onWheel={(e) => e.target.blur()}
                                />
                                {
                                  productErr?.position ? (
                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                  ) : null
                                }
                              </div>
                            </div>


                            {product.has_variations == false && <div className="col-md-8">
                              <div className="form-group">
                                <label className='genericlabel'>Reference ID</label>
                                {/* <span className="text-danger" > * </span>
                          {
                            productErr?.reference_id ? (
                              <span className="text-danger tiny_size" > This field is required ! </span>
                            ) : null
                          } */}
                                <input type="number" className="form-control" id=""
                                  onKeyPress={preventMinus}
                                  name="reference_id" value={product.reference_id}
                                  onChange={(e) => setProduct({ ...product, [e.target.name]: e.target.value, })}
                                  placeholder="Enter a Unique Reference ID"
                                  onBlur={(e) => errorHandler(e)}
                                  onWheel={(e) => e.target.blur()}

                                />
                                <small className='text-danger'>Should be unique and numerical</small>
                              </div>
                            </div>}
                          </div>
                        </div>
                      </div>




                      {/* {product.category_id == 182 && <div className="col-md-4">
                      <div className="form-group">
                        <label className='genericlabel'>Gender</label>
                        <span className="text-danger" > * </span>

                        <select name="gender" id="" className='form-control' value={allDynamicField.gender} onChange={(e) => setallDynamicFiled({ ...allDynamicField, [e.target.name]: e.target.value, })} >
                          <option value="">Select Gender</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </select>
                      </div>
                    </div>}
                    {product.category_id == 182 && <div className="col-md-4" >
                      <div className="form-group">
                        <label className='genericlabel'>Fabric   </label>
                        <span className="text-danger" > * </span>

                        <input type="text" className='form-control' name="fabric" value={allDynamicField.fabric} onChange={(e) => setallDynamicFiled({ ...allDynamicField, [e.target.name]: e.target.value, })} />
                      </div>
                    </div>}
                    {product.category_id == 182 && <div className="col-md-4" >
                      <div className="form-group">
                        <label className='genericlabel'>Size Chart  </label>
                        <span className="text-danger" > * </span>
                        {allDynamicField.size_chart !== "" && <small><a href={allDynamicField.size_chart} className='text' target='_blank'>View Size Chart</a></small>}
                        <input type="file" className="form-control" accept="image/*" name="size_chart"
                          onChange={(e) => SizeChartImageHandler(e)}

                        />
                      </div>
                    </div>} */}


                      <div className='col-md-12 d-flex justify-content-end'>

                        <div className="m-2">
                          {id === undefined ? <button className="btn btn-primary" onClick={(e) => { handleInput(e, "create") }}>
                            Create Product
                          </button> : <button className="btn btn-success" onClick={(e) => { handleInput(e, "update") }}>
                            Update
                          </button>}

                        </div>

                      </div>

                      <Modal
                        open={mopen}
                        // onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >

                        <Box sx={smstyle} className="variantBox">
                          <CloseIcon style={{ position: "absolute", top: "10px", right: "10px", cursor: "pointer" }} onClick={handleClose} />
                          <p className="text-center" style={{ fontSize: "20px", fontWeight: "700" }}>Variants</p>


                          <div >
                            <Variants submitFun={submitFun} productIdVariantsData={productIdVariantsData} hasVariants={hasVariants} product={product} upDateVariantsHandler={upDateVariantsHandler} productTypeNmae={productTypeName} id={id} manDatoryAttr={manDatoryAttr} handleOpen={handleOpen} handleClose={handleClose} deletHandlerWhileCreate={deletHandlerWhileCreate} />
                          </div>
                        </Box>
                      </Modal>

                      <Modal
                        open={iopen}
                        // onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >

                        <div className={`variantBox ${styles.imageContainer}`}>
                          <div className="d-flex align-items-center justify-content-between mt-2">
                            <CloseIcon style={{ position: "absolute", top: "5px", right: "10px", cursor: "pointer" }} onClick={handleimageClose} />
                            <p className="" style={{ fontSize: "20px", fontWeight: "700" }}> Images</p>
                          </div>


                          <div>
                            <ImageAdd productImageHandler={productImageHandler} productData={product} imageIndex={1} removeImageHandler={removeImageHandler} />
                          </div>
                        </div>
                      </Modal>

                      <Modal
                        open={featureopen}
                        // onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >

                        <Box sx={{ ...smstyle, minHeight: "300px" }} className="variantBox">
                          <CloseIcon style={{ position: "absolute", top: "5px", right: "10px", cursor: "pointer" }} onClick={handleFeaturesClose} />
                          <p className="" style={{ fontSize: "20px", fontWeight: "700" }}> Add Product Features</p>

                          <hr classNameName="sidebar-divider" />
                          <div className='p-3' style={{ height: "300px", overflow: "scroll" }}>
                            {
                              optionValues.map((item, index) => {

                                return <div className='mt-2' key={index}>

                                  <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                                    <span style={{ cursor: "pointer", color: "#fd4545" }}
                                      onClick={() => {
                                        let c = [...featureKeyValue];
                                        c.splice(index, 1);
                                        setfeatureKeyValue([...c]);
                                        setoptionValues((prev) => {
                                          let v = [...prev];
                                          v.splice(index, 1);
                                          return v
                                        })
                                      }}> <DeleteIcon />  </span>

                                    <div className='d-flex' style={{ gap: "20px" }}>
                                      <input type="text" placeholder='Feature Name' value={featureKeyValue[index]?.code} className="form-control" style={{ width: "80%" }} key={index} name='code' onChange={(e) => { featureChangeHandler(index, e) }} />
                                      <input type="text" placeholder='Feature Description' value={featureKeyValue[index]?.value} className="form-control" style={{ width: "80%" }} key={index} name='value' onChange={(e) => { featureChangeHandler(index, e) }} />
                                    </div>

                                    {optionValues[optionValues.length - 1] == index && <span style={{ cursor: "pointer" }}
                                      onClick={addHandler}> <AddCircleOutlineIcon />  </span>}


                                  </div>

                                </div>
                              })
                            }


                          </div>

                          <div className='d-flex justify-content-end p-2'>
                            <button className='btn btn-primary' onClick={() => onFeatureSaveHandler()} >Save</button>
                          </div>


                        </Box>


                      </Modal>
                    </div>
                  </div>
                </div>

                <Modal
                  open={shipmentModalOpen}
                  onClose={handleShipementModalClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={Sstyle} className="col-md-4 ">

                    <div className="modal-content " >
                      <div className="modal-header p-2 d-flex align-items-center justify-content-between">
                        <p className="modal-title genericlabel  " style={{ letterSpacing: "1px" }}>Import Using Excel File..     <span className="text-primary ml-2" style={{ cursor: "pointer" }} onClick={() => { setdownloadCSVModalOpen(true) }} > Download Sample Excel</span> </p>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <span style={{ cursor: "pointer" }} ><CloseIcon onClick={handleShipementModalClose} /></span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="importingSection">
                          <input type="file" id="inputFile" onChange={(e) => {
                            setFile(e.target.files[0]);
                          }} /> <br />

                          {/* <span className='text-danger font-weight-bold text-small'>Only CSV Files</span> */}


                        </div>
                      </div>
                      <div className="modal-footer">
                        {/* <button className="btn btn-primary" onClick={handleImport} > Submit </button> */}
                        {fullUrl === "multipleselleradmin.thesellerapp.com" ? <button className="btn btn-primary" onClick={handleImport} > Submit </button> : <button className="btn btn-primary" onClick={handleImportForProdXpressbazaar} > Submit </button>}
                      </div>
                    </div>
                  </Box>
                </Modal>


                <Modal
                  open={downloadCSVModalOpen}
                  onClose={handleShipementModalClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={Sstyle} className="col-md-4 ">

                    <div className="modal-content " >
                      <div className="modal-header p-2 d-flex align-items-center justify-content-between">
                        <p className="modal-title genericlabel  " style={{ letterSpacing: "1px" }}> Download Sampe Excel File... </p>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <span style={{ cursor: "pointer" }} ><CloseIcon onClick={() => { setdownloadCSVModalOpen(false); setSampleCSV({ category: "", product_type: "", sub_category: "", product_type_array: [] }) }} /></span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div>
                          <label htmlFor="" className='genericlabel' >Product Category</label>
                          <select id="" className='form-control genericlabel' name="category" onChange={(e) => { setSampleCSV((prev) => { return { ...prev, [e.target.name]: e.target.value } }); getProductTypeforSelectedCategory(e.target.value) }} >
                            <option value="" className='genericlabel' selected disabled>Select Category</option>
                            {categoryList.map(item => {
                              return <option value={item.cat_name} className='genericlabel' >{item.cat_name}</option>

                            })}
                          </select>
                        </div>

                        {sampleCSVdata?.product_type_array?.length > 0 ? <div className='mt-2'>
                          <label htmlFor="" className='genericlabel'>Product Type </label>
                          <select
                            name="product_type"
                            id=""
                            className="form-control genericlabel"
                            onChange={(e) => {

                              const selectedOption = e.target.options[e.target.selectedIndex];

                              setSampleCSV((prev) => {
                                return {
                                  ...prev,
                                  [e.target.name]: e.target.value,
                                  sub_category: selectedOption.getAttribute('data_name')
                                };
                              });


                            }}
                          >
                            <option value="" selected disabled>Select Product Type</option>
                            {sampleCSVdata?.product_type_array.map((item, index) => {
                              return (
                                <option value={item.id} key={index} data_name={item.name}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>

                        </div> : null}

                        {
                          sampleCSVdata.category === "Fashion" && <div className='mt-2'>
                            <label htmlFor="" className='genericlabel'>Gender Specification</label>
                            <select name="gender_specification" id="" className="form-control genericlabel"
                              onChange={(e) => {
                                setSampleCSV((prev) => {
                                  return {
                                    ...prev,
                                    [e.target.name]: e.target.value,

                                  };
                                });
                              }}
                            >
                              <option value="" selected disabled>Select Gender</option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                              <option value="boy">Boy</option>
                              <option value="girl">Girl</option>
                            </select>

                          </div>
                        }

                      </div>




                      {(sampleCSVdata.category !== "Fashion" && sampleCSVdata.product_type) ? <div className="modal-footer">
                        <button className="btn btn-primary font-weight-bold" onClick={generateTemplate} > GET CSV</button>
                      </div> : (sampleCSVdata.category === "Fashion" && sampleCSVdata.product_type && sampleCSVdata.gender_specification) ? <button className="btn btn-primary font-weight-bold" onClick={generateTemplate} > GET CSV</button> : null}

                    </div>
                  </Box>
                </Modal>

                <Modal
                  open={StateBasedPriceingopen}
                  // onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >

                  <Box sx={{ ...sPmstyle }} className="variantBox col-md-4">
                    <CloseIcon style={{ position: "absolute", top: "10px", right: "10px", cursor: "pointer" }} onClick={handleStateBasedPricingModalClose} />
                    <p className="text-center" style={{ fontSize: "20px", fontWeight: "700" }}>State Wise Pricing..</p>

                    <div className="modal-content " >
                      <div className="modal-header p-2 d-flex align-items-center justify-content-between">
                        <p className="modal-title genericlabel  " style={{ letterSpacing: "1px" }}> State Wise Pricing <input type="checkbox" checked={attributesState?.status} className="" onChange={(e) => setattributeState((prev) => { return { ...prev, status: e.target.checked } })} /> </p>
                        <div>

                          {!attributesState.adding_new_attr ?
                            <span className='tex-primary' style={anchorStyle}
                              onClick={() => { setattributeState((prev) => { return { ...prev, adding_new_attr: true } }) }} >Add Price</span>
                            : <span style={anchorStyle}
                              onClick={() => { setattributeState((prev) => { return { ...prev, adding_new_attr: false }; }); }} >Price List</span>
                          }

                          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            {/* <span style={{ cursor: "pointer" }} ><CloseIcon onClick={handleShipementModalClose} /></span> */}
                          </button>
                        </div>
                      </div>
                      {!attributesState?.adding_new_attr ? <div className="modal-body" >
                        <div style={{ maxHeight: "250px", overflow: "scroll" }}>
                          {(attributesState?.data?.length > 0 && attributesState.attr_is_loading === false) ? <>   {
                            attributesState?.data?.map(item => {
                              function getStateName(stateValue) {
                                let z = IndianStates.filter(item => item.value === stateValue)
                                return z[0].name
                              }
                              return <div key={item?.id}>
                                <p className='genericlabel d-flex justify-content-between align-items-center'>
                                  {item.state_name === attributesState?.editing_attr_id ? <> {getStateName(item?.state_name)} | {item.state_name} | Rs <input type="text" value={attributesState?.editing_attr_value} className='form-control w-25' onChange={(e) => { setattributeState((prev) => { return { ...prev, editing_attr_value: e.target.value } }) }} /> </>
                                    : <div><span>{getStateName(item?.state_name)} | {item.state_name} | Rs {item?.price}</span> <p style={{ color: "grey" }} > Start: {item?.start_date} | End: {item?.end_date}</p> </div>}

                                  {item.state_name === attributesState?.editing_attr_id ? <span className='badge badge-primary' style={{ cursor: "pointer" }} onClick={() => {
                                    console.log(attributesState);
                                    submitHandler("Update")
                                  }} >Update</span> : <span className='d-flex' style={{ gap: "10px" }}>
                                    <EditNoteRoundedIcon style={{ color: "green", cursor: "pointer" }} onClick={() => {
                                      setattributeState((prev) => { return { ...prev, adding_new_attr: true } });
                                      let StateFullName = IndianStates.filter(state => state.value === item.state_name)
                                      setSelectedODays([{ ...item, value: item?.state_name, name: StateFullName[0]?.name }]);
                                      setattributeState((prev) => { return { ...prev, price: item?.price, start_date: item?.start_date, end_date: item?.end_date, isUpdatingItem: true, stateName: item?.state_name, makeDisable: true, mrp: item?.mrp } })
                                      // console.log(item)
                                      // setattributeState((prev) => { return { ...prev, editing_attr_id: item?.state_name, editing_attr_value: item?.price } })
                                    }}

                                    />
                                    {/* <DeleteIcon style={{ color: "red", cursor: "pointer" }} /> */}
                                  </span>}
                                </p>
                                <hr />
                              </div>
                            })
                          }</> : null}

                          {(attributesState?.data?.length < 1 && attributesState.attr_is_loading === false) ?
                            <p className='text-primary text-center'>No Pricing</p>
                            : null}

                          {(attributesState?.data?.length < 1 && attributesState.attr_is_loading === true) ?
                            <p className='text-primary text-center'>Loading...</p>
                            : null}
                        </div>


                      </div> : <div className="modal-body">

                        <div >
                          <select name="" id="" className='form-control d-none ' value={attributesState?.stateName} onChange={(e) => { setattributeState((prev) => { return { ...prev, stateName: e.target.value } }) }}>
                            <option value="" selected disabled>Select State</option>
                            {IndianStates.map(item => {
                              return <option value={item.value}>{item.name}</option>
                            })}
                          </select>

                          <Multiselect options={Oweekdays} selectedValues={selectedODays} displayValue="name" onSelect={onSelectHandler} onRemove={onRemoveHandler} disable={attributesState?.makeDisable}></Multiselect>

                          <p className="m-0 p-0 mt-2 genericlabel">Selling Price</p>
                          <input type="text" placeholder='Enter Selling Price' className='form-control mt-1' value={attributesState?.price} onChange={(e) => { setattributeState((prev) => { return { ...prev, price: e.target.value } }) }} />

                          <p className="m-0 p-0 mt-2 genericlabel">MRP</p>
                          <input type="text" placeholder='Enter MRP' className='form-control mt-1' value={attributesState?.mrp} onChange={(e) => { setattributeState((prev) => { return { ...prev, mrp: e.target.value } }) }} />

                          <p className="m-0 p-0 mt-2 genericlabel">Start Date</p>
                          <input type="date" placeholder='Enter Selling Price' className='form-control mt-1' value={attributesState?.start_date} onChange={(e) => { setattributeState((prev) => { return { ...prev, start_date: e.target.value } }) }} />

                          <p className="m-0 p-0 mt-2 genericlabel">End Date</p>
                          <input type="date" placeholder='Enter Selling Price' className='form-control mt-1' value={attributesState?.end_date} onChange={(e) => { setattributeState((prev) => { return { ...prev, end_date: e.target.value } }) }} />


                          {attributesState?.new_attr_type === "select" && <textarea name="" id="" placeholder='Enter Options' className='form-control mt-3' value={attributesState?.new_attr_options} onChange={(e) => { setattributeState((prev) => { return { ...prev, new_attr_options: e.target.value } }) }}>

                          </textarea>}
                        </div>


                        <div className="modal-header p-2 d-flex align-items-center justify-content-end" style={{ gap: "10px" }}>
                          {attributesState?.isUpdatingItem === false ? <div className="d-flex" style={{ gap: "10px" }}>
                            <button className='btn btn-success' onClick={addMoreHandler} > Add More</button>
                            {attributesState?.data?.length > 0 && <button className='btn btn-primary' onClick={() => { submitHandler("Create") }} >  {attributesState?.submitting ? "Please wait" : "Submit"}</button>}
                          </div> : <div>
                            <button className='btn btn-success' onClick={() => { updateStatePriceRuleHandler() }} >  {attributesState?.submitting ? "Please wait" : "Update"}</button>

                          </div>}
                        </div>

                      </div>}

                    </div>
                  </Box>
                </Modal>


              </div>
            </>
          </div>
        </div>
      </div>
    </ThemeMaker >
  );
};

export default createProduct;
