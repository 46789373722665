import React from "react";
import { useState, useEffect } from "react";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Modal from '@mui/material/Modal';
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
import { BasicTable } from "../../components/Table";
import { Paginated } from "../../components/Paginated";
import * as XLSX from 'xlsx';
import "../../components/table.css";
import "../NewmasterPage/Master.css"
import CloseIcon from '@mui/icons-material/Close';
const { apiEndpoint } = config;
var $ = require("jquery");
var count = 1;
const Ondclistedproduct = () => {

    const [currentSid, setcurrentSid]: any = useState("")
    const [productList, setproductList]: any = useState([])
    const [allData, setallData]: any = useState([])
    const [inputText, setinputText]: any = useState("")
    const [startDate, setstartDate]: any = useState("")
    const [endDate, setendDate]: any = useState("")

    const decode = tokenDecode();
    const { data } = decode;
    const {
        permission: { category, order, product, role, seller, tax, user },
    } = data;

    useEffect(() => {
        getProductList()
    }, [])
    useEffect(() => {

        if (inputText !== "") {
            const x = setTimeout(() => {
                let g = allData?.filter((item: any) => {

                    return item.product_name.toLowerCase().trim().includes(inputText.toLowerCase().trim())
                })

                setproductList(g)
            }, 1000)

            return () => clearTimeout(x)
        } else {
            getProductList()
        }
    }, [inputText])

    async function getProductList() {
        const { data } = await axios({
            url: `${apiEndpoint}/getProductSales`,
            method: "POST",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
            },
            data: {
                "start_date": startDate,
                "end_date": endDate
            },

        })
        console.log(data)
        if (data.length !== 0) {
            setproductList(data[0]?.items)
            setallData(data[0]?.items)
        }

    }


    const COLUMNS = [
        {
            Header: "Serial No.",
            Footer: "Serial No.",
            accessor: (row: any, count: any) => {
                return <>{count + 1}</>
            },
            disableFilters: true,
            sticky: "left"
        },
        {
            Header: "Product Image",
            Footer: "Product Image",
            accessor: (row: any) => {
                return (
                    <img src={row.image} alt="Image"
                        height={80}
                        width={80} />
                );
            },
            sticky: "left",
        },

        {
            Header: "Product Name",
            Footer: "Product Name",
            accessor: (row: any) => {
                return (
                    row.product_name
                );
            },
            sticky: "left",
        },
        {
            Header: "Quantity Sold",
            Footer: "Quantity Sold",
            accessor: (row: any) => {

                return (
                    row.order_quantity
                );
            },
            sticky: "left",
        },
        // {
        //     Header: "In Stock",
        //     Footer: "In Stock",
        //     accessor: "quantity",
        //     sticky: "left",
        // },



    ];

    const exportToExcel = (orders: any) => {

        const headers = [
            'S.No', "Product Name", "Product Image Url", "Quantity Sold",

            // Add more custom headers as needed
        ];

        // // Prepare data with just the values to be displayed in the columns
        const data = orders.map((order: any, index: any) => {

            return {
                'S.No': index + 1,
                "Product Name": order.product_name,
                "Product Image Url": order.image,
                "Quantity Sold": order.order_quantity,
                "ONDC Product id": order.ondc_product_id
                // "In Stock": order.quantity,    

            }
        });

        // // Create a new workbook and worksheet
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(data, { header: headers });

        // // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, 'Orders');

        // // Generate a unique name for the Excel file (you can customize this as needed)
        const fileName = `ONDC_Active_Products${new Date().getTime()}.xlsx`;

        // // Export the workbook as an Excel file
        XLSX.writeFile(wb, fileName);
    };
    const [Fopen, setFOpen] = useState(false);
    const handleFClose = () => setFOpen(false);
    const handleFOpen = () => setFOpen(true);
    function getCurrentDate() {
        const today = new Date();
        const year = today.getFullYear();
        let month: string | number = today.getMonth() + 1;
        let day: string | number = today.getDate();

        // Add leading zero if month or day is a single digit
        month = month < 10 ? `0${month}` : month;
        day = day < 10 ? `0${day}` : day;

        return `${year}-${month}-${day}`;
    }

    function sortHandler(e : React.ChangeEvent<HTMLSelectElement>){
        if(e.target.value=="lowtohigh"){
            let z = [...productList];
            z.sort((a, b) => a.order_quantity - b.order_quantity);
            console.log(z);
            setproductList([...z])
        }
        if(e.target.value=="hightoLow"){
            let z = [...productList];
            z.sort((a, b) => b.order_quantity - a.order_quantity);
            console.log(z);
            setproductList([...z])
        }
       
    }
    return (
        <>
            <ThemeMaker>
                <div className="row w-100 m-0 mt-1">
                    <div className="col-md-12 ">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3 d-flex justify-content-between">
                                <h6 className="m-0 font-weight-bold text-primary text-left">
                                    Products Active On ONDC
                                </h6>

                                <div className="d-flex w-50" style={{ gap: '20px' }}>
                                    <input type="text" className="form-control w-75" placeholder="Search Product" value={inputText} onChange={(e) => setinputText(e.target.value)} />

                                    <button className="btn btn-primary" onClick={handleFOpen} > Filter</button>

                                    <button className="btn btn-primary" onClick={() => setTimeout(() => {
                                        exportToExcel(productList)
                                    }, 2000)}>Export</button>
                                </div>


                            </div>

                            <div className="card-body" style={{ minHeight: "60vh" }}>
                                <div className="table-responsive">

                                    <div className="d-flex justify-content-end my-1">
                                        <select name="" id="" onChange={sortHandler}>
                                        <option value="" disabled selected >Sort Products</option>
                                            <option value="hightoLow">High-Low</option>
                                            <option value="lowtohigh">Low-High</option>
                                        </select>
                                    </div>

                                    <div >
                                        <Paginated data={productList} columns={COLUMNS} />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ThemeMaker>

            <Modal open={Fopen}
                onClose={handleFClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">


                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content" style={{ minHeight: "200px" }}>
                        <div className="modal-header d-flex justify-content-between">

                            <h5 className="modal-title text-dark genericlabel" id="exampleModalLongTitle"> Filters  </h5>

                            <span aria-hidden="true" onClick={handleFClose} style={{ cursor: "pointer" }} ><CloseIcon /></span>

                        </div>


                        <div className="modal-body w-50" >


                            <div className="d-flex " style={{ gap: "20px" }}>

                                <div>
                                    <label >Start Date</label>

                                    <input name="startDate" type="date" className="form-control" value={startDate} onChange={(e) => setstartDate(e.target.value)} max={getCurrentDate()}
                                    />
                                </div>

                                <div>
                                    <label className="text-dark" >End Date</label>
                                    <input name="endDate" type="date" className="form-control" value={endDate} onChange={(e) => setendDate(e.target.value)} max={getCurrentDate()}
                                    />
                                </div>

                            </div>

                        </div>

                        <div className="modal-header d-flex justify-content-end " style={{ gap: "20px" }}>

                            <button className="btn btn-danger" data-dismiss="modal" aria-label="Close" onClick={() => {
                                window.location.reload()

                            }} >Clear Filter</button>

                            <button className="btn btn-primary" data-dismiss="modal" aria-label="Close"
                                onClick={() => { getProductList(); handleFClose() }}
                            >Apply Filter</button>
                        </div>


                    </div>
                </div>


            </Modal >


        </>
    );
};

export default Ondclistedproduct;
