import jwt_decode from "jwt-decode";
export const tokenDecode = (tk_access?: any) => {
    try {
        if (tk_access) {
            sessionStorage.setItem("us_st_d", tk_access);
        }
        const token: any = sessionStorage.getItem("us_st_d");
        const decoded: any = jwt_decode(token);
        const expirationDate = new Date(decoded?.exp * 1000);
        // console.log('Token expiration date:', expirationDate);
        if (decoded) {
            return decoded;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}