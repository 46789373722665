/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import ThemeMaker from "../../components/thememaker";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Mastercatalog from "./Mastercatalog"
// import MasterCatalog from "../catalog/"
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query'
import Instorecatalog from "./Instorecatalog";
import CreateProduct from "./createProduct";
import "./switch.css";



const styles = {
  csvReader: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 10,
  }
};

const index = () => {
  const queryClient = new QueryClient()
  // const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  return (
    <>
      <ThemeMaker>
        <QueryClientProvider client={queryClient}>
          <div className="row m-0 w-100 mt-1">
            <div className="col-md-12">
              <div className="card shadow mb-4">
                <div className="card-header " style={{ minHeight: "90vh" }}>
                  <Tabs className="catelog">
                    <TabList className="custom-tab-list mb-3">
                      <Tab className="custom-tab tabsFont" >In-Store Products</Tab>
                      <Tab className="custom-tab tabsFont" >Master Catalog</Tab>
                      <Tab className="custom-tab tabsFont" >Create New Product</Tab>
                    </TabList>
                    <TabPanel>
                      <Instorecatalog />
                    </TabPanel>
                    <TabPanel>
                      <Mastercatalog />
                    </TabPanel>
                    <TabPanel>
                      <CreateProduct />
                    </TabPanel>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>

        </QueryClientProvider>
      </ThemeMaker>
    </>
  );
};


export default index;

