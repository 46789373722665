import React from 'react'

export default function Orderpopusp() {
    return (
        <div className='table table-responsive table-bordered'>
            <table>
                <thead>
                    <th>
                        Product Name
                    </th>
                    <th>
                        Current Quantity
                    </th>
                    <th>
                        Qty
                    </th>
                    <th>
                        Checkbox
                    </th>

                </thead>
                <tbody>
                    <tr>
                        <td>Soap</td>
                        <td>5</td>
                        <td> <input type="number" className='w-50' /></td>
                        <td> <input type="checkbox" /> </td>
                    </tr>
                    <tr>
                        <td>Box</td>
                        <td>5</td>
                        <td> <input type="number" className='w-50' /></td>
                        <td> <input type="checkbox" /> </td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}
