import { toast } from "react-toastify"
import ThemeMaker from "../../components/thememaker"
import { useState, useEffect } from "react"
import axios from "axios"
import { config } from "../../utils/config"

export default function Grievance() {
  const { baseNodeapiUrl } = config
  const [formData, setformData] = useState({
    name: "",
    number: "",
    email: ""
  })

  useEffect(() => {
    getOrganizationDetails()
  }, [])

  async function getOrganizationDetails() {
    try {
      const { data } = await axios({
        url: `${baseNodeapiUrl}/igm/getigmDeatils`,
        method: "GET",

      })
      if (data) {
        setformData((prev) => {
          return { ...prev, name: data?.igmDetails?.org_person_name, number: data?.igmDetails?.org_phone, email: data?.igmDetails?.org_email }
        })
      }

    } catch (err) {

      console.log("Error in Server----", err)
      toast.error("Server not working, Please try again later")

    }
  }

  function onChangeHandler(e: any) {
    setformData((prev) => {
      return {
        ...prev, [e.target.name]: e.target.value
      }
    })
  }

  async function submitHandler() {
    let { name, email, number } = formData
    if (name === "" || email === "" || number === "") {
      toast.error("Please Fill All Details")
      return
    }

    try {
      const { data } = await axios({
        url: `${baseNodeapiUrl}/igm/igm_details`,
        method: "PUT",
        data: {
          "org_phone": number,
          "org_email": email,
          "org_person_name": name
        },
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
        }
      })

      toast.success("Data Saved Successfully!")
    } catch (err) {
      toast.error("Something Went Wrong! Please try again later.")
    }
  }

  const preventMinus = (e: any) => {
    if (e.code === 'Minus') {
        e.preventDefault();
    }
};


  return (
    <ThemeMaker>
      <div className="row w-100 m-0 mt-1">
        <div className="col-md-12 ">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary text-left d-flex justify-content-between align-items-center">Grievance Settings</h6>
            </div>
            <div className="card-body" style={{ minHeight: "70vh" }}>
              <h6 className="m-0 font-weight-bold text-primary text-left d-flex justify-content-between align-items-center"> Organization Details</h6>
              <div className="row mt-4">


                <div className="col-md-6">
                  <div className="form-group">
                    <label className="genericlabel">
                      Organization Name
                      <span className="text-danger" > * </span>

                    </label>
                    <input type="text" name="name" className="form-control" placeholder="Enter Name" value={formData.name} onChange={onChangeHandler} />
                  </div>

                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="genericlabel">
                      Organization Email
                      <span className="text-danger" > * </span>

                    </label>
                    <input type="text" name="email" className="form-control" placeholder="Enter Email" value={formData.email} onChange={onChangeHandler} />
                  </div>

                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="genericlabel">
                      Organization Contact Number
                      <span className="text-danger" > * </span>

                    </label>
                    <input type="number" name="number" className="form-control" placeholder="Enter Contact" value={formData.number} onKeyPress={preventMinus} onChange={onChangeHandler} />
                  </div>

                </div>
              </div>

              <div className="row px-2 ">

                <button className="btn btn-primary" onClick={submitHandler}>Submit</button>

              </div>

            </div>
          </div>
        </div>
      </div>
    </ThemeMaker>
  )
}
