import { useEffect, useState } from 'react';
import ThemeMaker from '../../components/thememaker';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { config } from "../../utils/config";
import axios from 'axios';
import { Paginated } from '../../components/Paginated';
import CircularProgress from '@mui/material/CircularProgress';


export default function Productlist() {
    const { apiEndpoint } = config;
    const [productTypes, setproductTypes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        getproductTypes()
    }, [])

    async function getproductTypes() {
        try {
            setIsLoading(true)
            const { data } = await axios({
                url: `${apiEndpoint}/getProtypeList`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('us_st_d')}`,
                },

            })
            console.log(data)
            if (data.status === 200) {
                setproductTypes(data?.data)
                setIsLoading(false)
            } else {
                toast.error("Error In Loading Product Type");

            }
        } catch (err) {
            setIsLoading(false)
            toast.error("Error in Getting Product Types")
        }
    }
    async function deleteHandler(id: any) {
        try {
            setIsLoading(true)
            const { data } = await axios({
                url: `${apiEndpoint}/deleteprotype`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('us_st_d')}`,
                },
                data: {
                    id
                }

            })

            if (data.status === 200) {
                toast.success("Deleted Successfully");
                setTimeout(() => {
                    getproductTypes()
                }, 1000)
            } else {
                toast.error("Error In Deleting Product Type");

            }
        } catch (err) {
            setIsLoading(false)
            toast.error("Error in API")
        }
    }

    const COLUMNS = [
        {
            Header: "Sn No.",
            Footer: "Sn No.",
            accessor: (row: any, count: any) => {
                return <>{count + 1}</>
            },
            disableFilters: true,
            sticky: "left"
        },
        {
            Header: "Name",
            Footer: "Name",
            accessor: "name",
            sticky: "left"
        },
        {
            Header: "ONDC Mapping",
            Footer: "ONDC Mapping",
            accessor: "ondc_mapping",
            sticky: "Status"
        },
        {
            Header: "Status",
            Footer: "Status",
            accessor: (row: any) => {
                return row.status === true ? 'Active' : "Inactive"
            },
            sticky: "Status"
        },
        {
            Header: "Action",
            Footer: "Action",
            accessor: (row: any) => {
                return (
                    <>
                        <Link to={`/producttype/${row.id}`} className="btn btn-success btn-circle"> <i className="fas fa-eye"></i></Link> <button className="btn btn-danger btn-circle" onClick={() => deleteHandler(row.id)} ><i className="fas fa-trash"></i></button>
                    </>
                )
            },
            sticky: "left"
        },



    ];


    return (
        <ThemeMaker>
            <div className="row w-100 m-0 mt-1">
                <div className="col-md-12 ">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary text-left d-flex justify-content-between align-items-center">
                                Product Type List

                                <Link to="/producttype">   <button className="btn btn-primary">Add Product Type</button>
                                </Link>

                            </h6>
                        </div>

                        <div className="card-body" style={{ minHeight: "70vh" }}>

                            {!isLoading ? <Paginated data={productTypes} columns={COLUMNS} /> : <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "60vh" }}><CircularProgress /></div>}
                        </div>
                    </div>
                </div>
            </div>

        </ThemeMaker >
    )
}
