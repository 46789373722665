/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import axios from "axios";
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
import orderImg from "../../icons/order.png"
import { useEffect, useState } from "react";
import moment from "moment";
import searchicon_black from "../../icons/searchicon_black.png";
import { Link } from "react-router-dom";
import style from "../../components/Search&Options/Search&Options.module.css";
import "./switch.css";
import CircularProgress from '@mui/material/CircularProgress';
import { Paginated } from "../../components/Paginated";
import ThemeMaker from "../../components/thememaker";
import * as XLSX from 'xlsx';

const cancelOrderlist = () => {
  const decoded: any = tokenDecode();
  const [orderList, setOrderList] = useState([]);
  const [sellerList, setSellerList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const { apiEndpoint } = config;
  const [toggle, setToggle] = useState(false);
  const rawData = {
    provider_id: "",
  };
  const [searchData, setSearchData]: any = useState(rawData);
  const [sorderList, setSorderList] = useState([]);

  const [valuename, setValuename] = useState("");
  const [valueid, setValueid] = useState("");
  const [valueemail, setValueemail] = useState("");
  const [valuecontact, setValuecontact] = useState("");
  const [valuestatus, setValuestatus] = useState("");
  const [valueamount, setValueamount] = useState("");
  const [valuedate, setValuedate] = useState("");
  const [allorders, setallorders] = useState([])
  const { data } = decoded;
  const {
    permission: { category, order, product, role, seller, tax, user },
  } = data;

  const fetchSellerList = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/getsellercancelorderlist`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
        },
        data: {
          seller_id: decoded?.data?.user?.seller_id,
        },
      });
      // console.log("order data -> ", data.data);
      setOrderList(data.data);
      // const order = data.data;

      if (data.status === 200) {
        setOrderList(data.data);
        setIsLoading(false);
        // setOrderList(data.data);

        /*setTimeout(() => {
                $('#dataTable').DataTable();
            }, 500);
            var someTableDT = $("#dataTable").on("draw.dt", function () {
                $("#dataTable").find(".dataTables_empty").parents('tbody').empty();
            });
*/
      }
    } catch (error) {
      console.log("error", error);

      setIsLoading(false);
    }
  };

  const inputHandler = async (e: any) => {
    setValuename("");
    setValueid("");
    setValueemail("");
    setValuecontact("");
    setValuestatus("");
    setValueamount("");
    setValuedate("");
    var optionvalue = e.target.value;
    if (optionvalue) {
      try {
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/filterbyseller`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
          },
          data: {
            provider_id: optionvalue,
          },
        });

        if (data.status === 200) {
          console.log(data);
          setOrderList(data.data);
          setSorderList(data.data);
          setToggle(true);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      setOrderList(sorderList);
    }
  };
  const inputeHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuename(optionvalue);
    if (optionvalue) {
      try {
        const { provider_id } = searchData;
        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerorder`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
          },
          data: {
            provider_id: { searchData },
            name: optionvalue,
            order_status: "Cancelled"
          },
        });

        console.log({
          data: {
            provider_id: { searchData },
            name: optionvalue,
          },
        });

        if (data.status === 200) {
          console.log(data);
          setOrderList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }
  };
  const inputeeHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuename(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/filterordersellerlogin`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
          },
          data: {
            name: optionvalue,
            order_status: "Cancelled"
          },
        });

        if (data.status === 200) {
          console.log(data.response);
          setOrderList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      fetchSellerList();
    }
  };
  const inputoHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValueid(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);

        //setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
        //console.log("line 239"+rawpData);
        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerorder`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
          },
          data: {
            provider_id: { searchData },
            order_id: optionvalue,
            order_status: "Cancelled"
          },
        });

        if (data.status === 200) {
          setOrderList(data.response);
          console.log("line 332", data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }
  };
  const inputooHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValueid(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/filterordersellerlogin`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
          },
          data: {
            order_id: optionvalue,
            order_status: "Cancelled"
          },
        });

        if (data.status === 200) {
          console.log(data.response);
          setOrderList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      fetchSellerList();
    }
  };
  const inputemHandler = async (e: any) => {
    const optionvalue = e.target.value;
    console.log(optionvalue);
    setValueemail(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);

        //setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
        //console.log("line 239"+rawpData);
        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerorder`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
          },
          data: {
            provider_id: { searchData },
            email: optionvalue,
          },
        });

        if (data.status === 200) {
          setOrderList(data.response);
          console.log(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }
  };
  const inputeemHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValueemail(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/filterordersellerlogin`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
          },
          data: {
            email: optionvalue,
          },
        });

        if (data.status === 200) {
          console.log(data.response);
          setOrderList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      fetchSellerList();
    }
  };
  const inputcHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuecontact(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);

        //setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
        //console.log("line 239"+rawpData);
        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerorder`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
          },
          data: {
            provider_id: { searchData },
            contact_number: optionvalue,
            order_status: "Cancelled"
          },
        });

        if (data.status === 200) {
          setOrderList(data.response);
          console.log(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }
  };
  const inputccHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuecontact(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/filterordersellerlogin`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
          },
          data: {
            contact_number: optionvalue,
            order_status: "Cancelled"
          },
        });

        if (data.status === 200) {
          console.log(data.response);
          setOrderList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      fetchSellerList();
    }
  };

  const inputdHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuedate(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);

        //setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
        //console.log("line 239"+rawpData);
        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerorder`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
          },
          data: {
            provider_id: { searchData },
            created_at: optionvalue,
            order_status: "Cancelled"
          },
        });

        if (data.status === 200) {
          setOrderList(data.response);
          console.log(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }
  };
  const inputddHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuedate(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/filterordersellerlogin`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
          },
          data: {
            created_at: optionvalue,
            order_status: "Cancelled"
          },
        });

        if (data.status === 200) {
          console.log(data.response);
          setOrderList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      fetchSellerList();
    }
  };

  const inputpHandler = async (e: any) => {

    const optionvalue = e.target.value;
    // console.log(optionvalue)
    setValuestatus(optionvalue);
    if (optionvalue) {
      try {

        setToggle(true);

        //setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
        //console.log("line 239"+rawpData);
        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerorder`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('us_st_d')}`
          },
          data:
          {
            provider_id: { searchData },
            status: optionvalue,
            order_status: "Cancelled"
          }

        });

        if (data.status === 200) {
          setOrderList(data.response);
          // console.log(data.response)
        }


      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }


  };

  const inputppHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuestatus(optionvalue);
    if (optionvalue) {
      try {

        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/filterordersellerlogin`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('us_st_d')}`
          },
          data: {
            status: optionvalue,
            order_status: "Cancelled"
          }
        });

        if (data.status === 200) {
          // console.log(data.response);
          setOrderList(data.response);

        }

      } catch (error) {
        console.log("error", error);
      }
    } else {
      fetchSellerList();
    }
  };

  const COLUMNS = [
    {
      Header: "Serial No.",
      accessor: (row: any, count: any) => {
        return <>{count + 1}</>
      },
      disableFilters: true,
      sticky: "left"
    },
    {
      Header: "Order ID",
      accessor: (a: any) => (a?.order_id ? a?.order_id : null),
      sticky: "left"
    },
    {
      Header: "Order Status",
      accessor: (a: any) => (a?.status ? a?.status : null),
      sticky: "left",
    },
    {
      Header: "Item",
      accessor: (a: any) => (a?.item_count ? a?.item_count : null),
      sticky: "left",
    },
    {
      Header: "Customer Name",
      accessor: (a: any) => (a?.name ? a?.name : null),
      sticky: "left"
    },
    {
      Header: "Total (In Rs)",
      accessor: "order_total",
      sticky: "left"
    },
    {
      Header: "Payment Status",
      accessor: (row: any) => {
        return <>{row.status === "NOT-PAID" ? "COD" : "PAID"}</>
      },
      sticky: "left"
    },
    {
      Header: "Created Date",
      accessor: (a: any) => a?.created_at ? moment(a.created_at)?.format("MMMM Do YYYY") : null,
      sticky: "left"
    },
    {
      Header: "Action",
      accessor: (item: any) => {
        return <> <Link className="text-dark" to={`./orderCompleteinfo/${item.order_id}`}>
          <span className="badge badge-primary" style={{ fontSize: "15px", fontWeight: "400" }}>Get Order Details</span>
        </Link></>
      },
      sticky: "left"
    },
  ];

  const exportToExcel = (orders: any) => {
    // console.log("csv orders----->", orders)
    // Define custom column headers for the Excel file
    const headers = [
      'S.No', "Order Id", "Created At", "Seller Name", "Order State", " Customer Name", " Customer Email", " Customer Contact", "Status", "Item Count", "Order Total"

      // Add more custom headers as needed
    ];

    // // Prepare data with just the values to be displayed in the columns
    const data = orders.map((order: any, index: any) => {

      let orderCreatedDate = moment(order?.created_at).format("DD-MM-YYYY hh:mm:ss")
      let sellerName = order?.address?.start?.location?.descriptor?.name
      let sellerEmail = order?.address?.end?.contact?.email
      let sellerContact = order?.address?.end?.contact?.phone
      return {
        'S.No': index + 1,
        "Order Id": order.order_id,
        "Created At": orderCreatedDate,
        "Seller Name": sellerName,
        "Order State": order?.order_state,
        " Customer Name": order.name,
        " Customer Email": sellerEmail,
        " Customer Contact": sellerContact,
        "Status": order?.status,

        "Item Count": order?.item_count,
        "Order Total": order?.order_total

      }
    });

    // // Create a new workbook and worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data, { header: headers });

    // // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Orders');

    // // Generate a unique name for the Excel file (you can customize this as needed)
    const fileName = `allorders_${new Date().getTime()}.xlsx`;

    // // Export the workbook as an Excel file
    XLSX.writeFile(wb, fileName);
  };

  const exportorders = async () => {
    let payload;
    let sellerId = decoded?.data?.user?.seller_id;
    if(sellerId == 1 || sellerId > 1 ) {
      payload = sellerId
    }

    if(sellerId == 0){
      payload = decoded?.data?.user?.parent_id;
    }
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/getallstatusorder`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('us_st_d')}`
        },
        data: {
          seller_id: payload,
        }
      });
      // console.log("order data -> ", data);
      if (data.status === 200) {

        setallorders(data.data)
      }
    } catch (error) {
      console.log("error", error);
    }
  }


  useEffect(() => {
    fetchSellerList();
    // getSellerList();
    exportorders();
  }, []);

  return (
    <>
      <ThemeMaker>
        <div className="row w-100 m-0 mt-2">
          <div className="col-md-12">
            <div className="card shadow mb-4 mt-1">
              <div className="card-header">
                <div className="row d-flex justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">Cancel Order List</h6>
                 
                  <div className={`search mt-1`}>
                    <div className={`d-flex  mb-1`}>
                      <div >

                        <input
                          className="form-control"
                          type="search"
                          name="order_id"
                          placeholder="Search by order id"
                          onChange={(e) => {
                            seller?.read ? inputoHandler(e) : inputooHandler(e);
                          }}
                          value={valueid ? valueid : ""}
                        />
                      </div>

                      <div className="d-none">

                        <input
                          type="search"
                          name="name"
                          placeholder="Search by name"
                          onChange={(e) => {
                            seller?.read ? inputeHandler(e) : inputeeHandler(e);
                          }}
                          value={valuename ? valuename : ""}
                        />
                      </div>

                      {/* ------------------------------------------Search By email commented--------------------------------------------- */}
                      {/* <div className={style.inputMain}>
              <img src={searchicon_black} alt="" />{" "}
              <input
                type="search"
                name="email"
                placeholder="Search by email"
                onChange={(e) => {
                  seller?.read ? inputemHandler(e) : inputeemHandler(e);
                }}
                value={valueemail ? valueemail : ""}
              />
            </div> */}
                      {/* ------------------------------------------Search By email commented--------------------------------------------- */}
                      <div className="d-none">

                        <input
                          type="search"
                          name="contact_number"
                          placeholder="Search by Contact"
                          onChange={(e) => {
                            seller?.read ? inputcHandler(e) : inputccHandler(e);
                          }}
                          value={valuecontact ? valuecontact : ""}
                        />
                      </div>


                      <select name="payment_status" className="d-none" onChange={(e) => { seller?.read ? inputpHandler(e) : inputppHandler(e) }} value={valuestatus ? valuestatus : ""} >
                        <option >Select Payment Status</option>
                        <option value="NOT-PAID">Cod</option>
                        <option value="pa">Paid</option>
                      </select>

                      <div className="col-12 col-sm-12 col-md-1 mb-1">
                        <button className="btn btn-primary" onClick={() => setTimeout(() => {
                          exportToExcel(allorders)
                        }, 2000)} >Export </button>
                      </div>
                    </div>


                  </div>

                </div>
              </div>

              {!isLoading ? <div className="card-body">
                {orderList &&
                  <>
                    <div className="showdiv table-responsive table-bordered">
                      <Paginated data={orderList} columns={COLUMNS} />
                    </div>
                    <div className="hidediv">
                      {orderList.map((item: any) => {
                        let sellerName = item?.address?.start?.location?.descriptor?.name
                        if (sellerName) {
                          sellerName = sellerName
                        } else {
                          const parsedData = JSON.parse(item?.fulfillments);
                          const selletName = parsedData[0]?.start?.location?.descriptor?.name

                          sellerName = selletName
                        }

                        return <div className="border p-1">
                          <div className="d-flex  mt-1 align-items-center" style={{ gap: "10px" }}>
                            <span className="genericlabel" style={{ width: "28%" }} >Order ID</span>
                            <span className="" style={{ fontSize: "14px", width: '70%' }}>{item?.order_id}</span>
                          </div>
                          <div className="d-flex  mt-1" style={{ gap: "10px" }}>
                            <span className="genericlabel " style={{ width: "28%" }} >Order Status</span>
                            <span className="" style={{ fontSize: "14px", width: '70%' }}>{item?.order_state}</span>
                          </div>
                          <div className="d-flex mt-1" style={{ gap: "10px" }}>
                            <span className="genericlabel " style={{ width: "28%" }} > Seller Name</span>
                            <span className="" style={{ fontSize: "14px", width: '70%' }}>{sellerName}</span>
                          </div>
                          <div className="d-flex mt-1" style={{ gap: "10px" }}>
                            <span className="genericlabel " style={{ width: "31%" }}>Payment Status</span>
                            <span className="" style={{ fontSize: "14px", width: '69%' }}>{item?.status}</span>
                          </div>
                          <div className="mt-2">
                            <Link to={`../../orderCompleteDetail/${item.order_id}`}> <p style={{ width: "120px", fontWeight: 600, borderRadius: "17px", color: "white", background: "blue", padding: "6px", textAlign: "center" }} >Check Order</p>
                            </Link>
                          </div>
                        </div>
                      })}
                    </div>
                  </>

                }
              </div> : <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "60vh" }}><CircularProgress /></div>}
            </div>
          </div>
        </div>

      </ThemeMaker>
    </>
  );
};

export default cancelOrderlist;