export const SizeData = [
    "XXS", "XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL", "7XL", "8XL", "9XL", "10XL", "24", "26", "28", "30", "32", "34", "36", "38", "39", "40", "42", "44", "46", "48", "50", "52", "Free Size"
];
export const SizeDataBoyGirl = [
   "0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","Free Size"

];
export const UOMData = [
    "unit", "dozen", "gram", "kilogram", "tonne", "litre", "millilitre"
];
export const MaterialData = [
    , "aluminium", "brass", "carbon", "cotton", "leather", "glass", "grey", "iron", "jute", "leather", "linen", "nylon", "polycarbonate", "polyester", "polyethylene", "polyvinyl", "PVC", "rayon", "rubber", "sand", "silicon", "silk", "stainless", "steel", "synthetic", "textile", "thermo", "thermoplastic", "water", "wood", "wool", "zinc"
];
// export const MaterialData = [
//    "alpha","acetate","acrylic","acrylonitrile","aluminium","battery","brass","canvas","carbon","cellulose","cork","cotton","elastane","elastodiene","elastolefin","ethylene","EVA","expanded","faux leather","foamed","glass","grey","iron","jute","leather","linen","lycra","lyocell","mesh","modal","nubuck","nylon","polyamide","polycarbonate","polyester","polyethylene","polymethylpentene","polyoxymethylene","polypropylene","polyurethane","polyvinyl","PU","PVC","rayon","rice","rubber","sand","silicon","silk","stainless","steel","styrene","suede","synthetic","textile","thermo","thermoplastic","tritan","velvet","viscose","water","wood","wool","zinc"
// ];

export const ColourData = [
    { name: "Red", value: "#FF0000" },
    { name: "Green", value: "#00FF00" },
    { name: "Yellow", value: "#FFFF00" },
    { name: "Blue", value: "#0000FF" },
    { name: "Pink", value: "#FFC0CB" },
    { name: "Orange", value: "#FF681F" },
    { name: "Gray", value: "#808080" },
    { name: "Sky Blue", value: "#76D7EA" },
    { name: "Brown", value: "#964B00" },
    { name: "Purple", value: "#660099" },
    { name: "Violet", value: "#240A40" },
    { name: "Magenta", value: "#FF00FF" },
    { name: "Black", value: "#000000" },
    { name: "White", value: "#FFFFFF" },
    { name: "silver", value: "#C0C0C0" },
    { name: "gold", value: "#FFD700" },

];

export const FabricData = [
    "acrylic", "bamboo", "cotton", "cotton linen", "cotton silk",
    "denim", "dupion silk", "georgette", "jute", "jute cotton",
    "jute silk", "khadi cotton", "leather", "linen", "mulmul", "modal",
    "net", "nylon", "polycotton", "polyester", "rayon", "shantoon", "silk",
    "synthetic", "tissue", "velvet", "wool"
]

// DELETED SOME FABRICS BECAUSE OF LONG ARRAY , IF REQUIRED PICK FROM BELOW

// export const FabricData = [
//     "alpha", "acrylic", "art silk", "bamboo", "chambray", "chanderi cotton", "chanderi silk",
//     "chiffon", "cotton", "cotton blend", "cotton cambric", "cotton linen", "cotton silk",
//     "crepe", "denim", "dupion silk", "elastane", "georgette", "jacquard", "jute", "jute cotton",
//     "jute silk", "khadi cotton", "kora muslin", "lace", "leather", "linen", "mulmul", "modal",
//     "net", "nylon", "organza", "paper silk", "pashmina", "poly chiffon", "poly crepe", "poly georgette",
//     "poly silk", "polycotton", "polyester", "rayon", "rayon slub", "satin", "shantoon", "silk",
//     "silk blend", "soft silk", "super net", "synthetic", "taffeta silk", "tissue", "tussar silk",
//     "velvet", "vichitra silk", "viscose", "viscose rayon", "voile", "wool"
// ]

export const daysData = [
    { name: "1 Day", value: "P1D" },
    { name: "2 Days", value: "P2D" },
    { name: "3 Days", value: "P3D" },
    { name: "4 Days", value: "P4D" },
    { name: "5 Days", value: "P5D" },
    { name: "6 Days", value: "P6D" },
    { name: "7 Days", value: "P7D" },
    { name: "8 Days", value: "P8D" },
    { name: "9 Days", value: "P9D" },
    { name: "10 Days", value: "P10D" },
]
export const ShoeSizedata = [
    "IND-5", "IND-6", "IND-7", "IND-8", "IND-9", "IND-10", "IND-11", "IND-12", "IND-13",
    "UK1", "UK1.5", "UK2", "UK2.5", "UK3", "UK3.5", "UK4", "UK4.5", "UK5", "UK5.5",
    "UK6", "UK6.5", "UK7", "UK7.5", "UK8", "UK8.5", "UK9", "UK9.5", "UK10", "UK10.5",
    "UK11", "UK11.5", "UK12", "UK12.5", "UK13", "UK13.5"

];

export const IndianStates = [
    { name: "Andhra Pradesh", value: "AP" },
    { name: "Arunachal Pradesh", value: "AR" },
    { name: "Andaman and Nicobar Islands", value: "AN" },
    { name: "Assam", value: "AS" },
    { name: "Bihar", value: "BR" },
    { name: "Chhattisgarh", value: "CG" },
    { name: "Chandigarh", value: "CH" },
    { name: "Dadra and Nagar Haveli", value: "DH" },
    { name: "Daman and Diu", value: "DD" },
    { name: "Delhi", value: "DL" },
    { name: "Goa", value: "GA" },
    { name: "Gujarat", value: "GJ" },
    { name: "Haryana", value: "HR" },
    { name: "Himachal Pradesh", value: "HP" },
    { name: "Jammu and Kashmir", value: "JK" },
    { name: "Jharkhand", value: "JH" },
    { name: "Karnataka", value: "KA" },
    { name: "Kerala", value: "KL" },
    { name: "Lakshadweep", value: "LD" },
    { name: "Madhya Pradesh", value: "MP" },
    { name: "Maharashtra", value: "MH" },
    { name: "Manipur", value: "MN" },
    { name: "Meghalaya", value: "ML" },
    { name: "Mizoram", value: "MZ" },
    { name: "Nagaland", value: "NL" },
    { name: "Orissa", value: "OR" },
    { name: "Punjab", value: "PB" },
    { name: "Pondicherry", value: "PY" },
    { name: "Rajasthan", value: "RJ" },
    { name: "Sikkim", value: "SK" },
    { name: "Tamil Nadu", value: "TN" },
    { name: "Telangana", value: "TS" },
    { name: "Tripura", value: "TR" },
    { name: "Uttarakhand", value: "UK" },
    { name: "Uttar Pradesh", value: "UP" },
    { name: "West Bengal", value: "WB" }
];

