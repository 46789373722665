import React from 'react'
import ThemeMaker from '../../components/thememaker'
export default function Ondc() {
    return (
        <ThemeMaker>
            <div className="row w-100 m-0 mt-1">
                <div className="col-md-12 ">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex justify-content-between">
                            <h6 className='font-weight-bold text-primary'>ONDC</h6>

                        </div>

                        <div className={`card-body p-3 w-100`} style={{ minHeight: "70vh" }}>

                            <p className="genericlabel" style={{fontSize: "18px"}}>Sync catalogs to ONDC </p> <button className='btn btn-success'>Sync Catalog</button>
                        </div>
                    </div>
                </div>
            </div>
        </ThemeMaker>
    )
}
