import React, { useEffect, useState } from 'react'
import VairantTables from './VairantTables';
import "../../index.css"
import styles from "./Variant.module.css"


export default function Variants({ submitFun, id, productIdVariantsData, hasVariants, product, upDateVariantsHandler, productTypeNmae, manDatoryAttr, getVariantsData, handleOpen, handleClose, deletHandlerWhileCreate }: any) {

    const [optionValues, setoptionValues] = useState([0]);
    const [inputValues, setinputValues] = useState<any>([])
    const [variatType, setVariantType]: any = useState([])
    const [dataSet, setdataSet]: any = useState({
        size: [],
        colour: [],
        RAM: [],
        model: [],
        material: [],
        storage: [],
        screensize: [],
        variant: [],
        battery_capacity: [],
    })

    const [tableData, settableData] = useState<any>([])
    useEffect(() => {
        if (productIdVariantsData?.length > 0) {
            let d = [...productIdVariantsData];
            let k = d.map(item => { return { ...item, image: item?.image ? typeof (item?.image) === "string" ? item?.image?.split(",") : item?.image : [] } });
            settableData([...k])
            // setVariantType(productIdVariantsData[0].variant_group[0]?.variant_name);
            let v = productIdVariantsData.map((item: any, index: any) => (
                item.variant_group[0]?.variant_value
            ))
            setinputValues(v)
            // Initialize an empty object to store the variant values for each variant group
            const variantValues: any = {};
            // Iterate over each product in the data
            productIdVariantsData.forEach((product: any) => {
                // Iterate over each variant group in the product
                product.variant_group.forEach((variant: any) => {
                    // If the variant group doesn't exist in the variantValues object, initialize it as an empty array
                    if (!variantValues[variant.variant_name]) {
                        variantValues[variant.variant_name] = [];
                    }
                    // Add the variant value to the corresponding variant group array if it doesn't already exist
                    if (!variantValues[variant.variant_name].includes(variant.variant_value)) {
                        variantValues[variant.variant_name].push(variant.variant_value);
                    }
                });
            });

            // Update the state with the extracted variant values
            setdataSet(variantValues);

        }
    }, [])

    function addHandler() {
        setoptionValues((count: any) => [...count, count[count?.length - 1] + 1])
    }

    function inputChangeHandler(e: React.ChangeEvent<HTMLInputElement>, index: any) {
        setinputValues((prev: any) => {
            const updatedValues = [...prev];
            updatedValues[index] = e.target.value;
            return updatedValues;
        })

    }

    function onDoneHandler(variatType: any) {
        let k = Object.values(inputValues);
        let z = { ...dataSet, [variatType]: [...k] };
        setdataSet(z);
        setVariantType("");
        setoptionValues([0]);
        setinputValues([]);
    }

    const buttonProps = [
        { type: "size", label: "Size" },
        { type: "colour", label: "colour" },
        { type: "RAM", label: "RAM" },
        { type: "model", label: "Model" },
        { type: "storage", label: "Storage" },
        { type: "screen_size", label: "Screen Size" },
        { type: "material", label: "Material" },
        { type: "variant", label: "variant" },
        { type: "battery_capacity", label: "battery_capacity" }
    ];
    const [newValue, setnewValue] = useState("")
    return (
        <>
            < div className="" style={{ background: "white", minHeight: "100px" }} >

                {/* <p>Variants</p> */}

                {/* ---------------------------- BELOW CODE FOR DYNAMC START-------------------- */}
                {/* <div>
                    {
                        dataSet[variatType]?.length > 0 && <div className='p-2'> <p className='genericlabel'>{variatType}</p>
                            <p>
                                {
                                    dataSet[variatType]?.map((item: any, index: any) => (
                                        <span className='badge badge-primary mx-1 genericlabel' style={{ background: "lightgray", borderRadius: "20px" }} key={index}>{item}</span>
                                    ))
                                }
                            </p>
                        </div>
                    }
                </div> */}
                {/* ---------------------------- BELOW CODE FOR DYNAMC END-------------------- */}

                <div>
                    {dataSet?.size?.length > 0 && <div className='p-2 genericlabel'> <p>Size</p>
                        <p>{dataSet.size.map((item: any, index: any) => (
                            <span className='badge badge-primary mx-1' style={{ background: "lightgray", borderRadius: "20px", color: "black" }} key={index}>{item}</span>
                        ))} </p>
                        <input type="text" onChange={(e) => { setnewValue(e.target.value) }} placeholder='Enter new size' />
                        <button className={styles.variantAddBtn} onClick={() => {
                            setinputValues((prev: any) => {

                                return [...prev, newValue];
                            })
                            setdataSet((prev: any) => {
                                return { ...prev, size: [...prev.size, newValue] }
                            })

                        }}>Submit</button>


                    </div>
                    }
                </div>

                <div>
                    {dataSet?.colour?.length > 0 && <div className='p-2 genericlabel'> <p>Colour</p>
                        <p>{dataSet.colour.map((item: any, index: any) => (
                            <span className='badge badge-primary mx-1' style={{ background: "lightgray", borderRadius: "20px", color: "black" }} key={index}>{item}</span>
                        ))} </p>

                        <input type="text" onChange={(e) => { setnewValue(e.target.value) }} placeholder='Enter new colour' />
                        <button className={styles.variantAddBtn} onClick={() => {
                            setinputValues((prev: any) => {

                                return [...prev, newValue];
                            })
                            setdataSet((prev: any) => {
                                return { ...prev, colour: [...prev.colour, newValue] }
                            })

                        }}>Submit</button>


                    </div>
                    }
                </div>

                <div>
                    {dataSet?.RAM?.length > 0 && <div className='p-2 genericlabel'> <p>RAM</p>
                        <p>{dataSet.RAM.map((item: any, index: any) => (
                            <span className='badge badge-primary mx-1' style={{ background: "lightgray", borderRadius: "20px", color: "black" }} key={index}>{item}</span>
                        ))} </p>
                        <input type="text" onChange={(e) => { setnewValue(e.target.value) }} />
                        <button className={styles.variantAddBtn} onClick={() => {
                            setinputValues((prev: any) => {

                                return [...prev, newValue];
                            })
                            setdataSet((prev: any) => {
                                return { ...prev, ram: [...prev.ram, newValue] }
                            })

                        }}>Submit</button>


                    </div>
                    }
                </div>

                <div>
                    {dataSet?.model?.length > 0 && <div className='p-2 genericlabel'> <p>Model</p>
                        <p>{dataSet.model.map((item: any, index: any) => (
                            <span className='badge badge-primary mx-1' style={{ background: "lightgray", borderRadius: "20px", color: "black" }} key={index}>{item}</span>
                        ))} </p>

                        <input type="text" onChange={(e) => { setnewValue(e.target.value) }} placeholder='Enter new model' />
                        <button className={styles.variantAddBtn} onClick={() => {
                            setinputValues((prev: any) => {

                                return [...prev, newValue];
                            })
                            setdataSet((prev: any) => {
                                return { ...prev, model: [...prev.model, newValue] }
                            })

                        }}>Submit</button>
                    </div>
                    }
                </div>

                <div>
                    {dataSet?.storage?.length > 0 && <div className='p-2 genericlabel'> <p>Storage</p>
                        <p>{dataSet.storage.map((item: any, index: any) => (
                            <span className='badge badge-primary mx-1' style={{ background: "lightgray", borderRadius: "20px", color: "black" }} key={index}>{item}</span>
                        ))} </p>
                        <input type="text" onChange={(e) => { setnewValue(e.target.value) }} />
                        <button className={styles.variantAddBtn} onClick={() => {
                            setinputValues((prev: any) => {

                                return [...prev, newValue];
                            })
                            setdataSet((prev: any) => {
                                return { ...prev, storage: [...prev.storage, newValue] }
                            })

                        }}>Submit</button>


                    </div>
                    }
                </div>

                <div>
                    {dataSet?.material?.length > 0 && <div className='p-2 genericlabel'> <p>Material</p>
                        <p>{dataSet.material.map((item: any, index: any) => (
                            <span className='badge badge-primary mx-1' style={{ background: "lightgray", borderRadius: "20px", color: "black" }} key={index}>{item}</span>
                        ))} </p>

                        <input type="text" onChange={(e) => { setnewValue(e.target.value) }} />
                        <button className={styles.variantAddBtn} onClick={() => {
                            setinputValues((prev: any) => {

                                return [...prev, newValue];
                            })
                            setdataSet((prev: any) => {
                                return { ...prev, material: [...prev.material, newValue] }
                            })

                        }}>Submit</button>

                    </div>
                    }
                </div>

                <div>
                    {dataSet?.screen_size?.length > 0 && <div className='p-2 genericlabel'> <p>Screen Size</p>
                        <p>{dataSet.screen_size.map((item: any, index: any) => (
                            <span className='badge badge-primary mx-1' style={{ background: "lightgray", borderRadius: "20px", color: "black" }} key={index}>{item}</span>
                        ))} </p>
                        <input type="text" onChange={(e) => { setnewValue(e.target.value) }} />
                        <button className={styles.variantAddBtn} onClick={() => {
                            setinputValues((prev: any) => {

                                return [...prev, newValue];
                            })
                            setdataSet((prev: any) => {
                                return { ...prev, screen_size: [...prev.screen_size, newValue] }
                            })

                        }}>Submit</button>

                    </div>
                    }
                </div>
                <div>
                    {dataSet?.variant?.length > 0 && <div className='p-2 genericlabel'> <p>Variant</p>
                        <p>{dataSet.variant.map((item: any, index: any) => (
                            <span className='badge badge-primary mx-1' style={{ background: "lightgray", borderRadius: "20px", color: "black" }} key={index}>{item}</span>
                        ))} </p>
                        <input type="text" onChange={(e) => { setnewValue(e.target.value) }} />
                        <button className={styles.variantAddBtn} onClick={() => {
                            setinputValues((prev: any) => {

                                return [...prev, newValue];
                            })
                            setdataSet((prev: any) => {
                                return { ...prev, variant: [...prev.variant, newValue] }
                            })

                        }}>Submit</button>

                    </div>
                    }
                </div>
                <div>
                    {dataSet?.battery_capacity?.length > 0 && <div className='p-2 genericlabel'> <p>Battery Capacity</p>
                        <p>{dataSet.battery_capacity.map((item: any, index: any) => (
                            <span className='badge badge-primary mx-1' style={{ background: "lightgray", borderRadius: "20px", color: "black" }} key={index}>{item}</span>
                        ))} </p>
                        <input type="text" onChange={(e) => { setnewValue(e.target.value) }} />
                        <button className={styles.variantAddBtn} onClick={() => {
                            setinputValues((prev: any) => {

                                return [...prev, newValue];
                            })
                            setdataSet((prev: any) => {
                                return { ...prev, battery_capacity: [...prev.battery_capacity, newValue] }
                            })

                        }}>Submit</button>

                    </div>
                    }
                </div>



                <hr className="sidebar-divider" />

                <div style={{ display: hasVariants == "true" ? "none" : "block" }}>
                    <div className="p-2">
                        <div className='w-50 '>
                            <label htmlFor="" className='genericlabel'>Option Name</label>
                            <select name="" id="" className='form-control' value={variatType} onChange={(e) => setVariantType(e.target.value)}>
                                <option value="">Select Variant Type</option>
                                <option value="size">Size</option>
                                <option value="colour">Colour</option>
                                {/* <option value="RAM">RAM</option> */}
                                <option value="model">Model</option>
                                <option value="variant">Variant</option>
                                <option value="battery_capacity">Battery Capacity</option>
                                {/* <option value="material">Material</option>
                                <option value="storage">Storage</option>
                                <option value="screensize">Screen Size</option> */}
                            </select>
                        </div>

                        <div className='w-50 mt-3'   >

                            <label htmlFor="" className='genericlabel'>Option Values</label>
                            {
                                optionValues.map((item, index) => {

                                    return <div className='mt-2'>

                                        <div style={{ display: "flex", gap: "20px" }}>

                                            <input type="text" className="form-control" style={{ width: "80%" }} key={index} value={inputValues[index]} onChange={(e) => inputChangeHandler(e, index)} />

                                            {optionValues[optionValues?.length - 1] == index && <span className='badge badge-primary' style={{ background: "#105293", width: "100px", borderRadius: "20px", cursor: "pointer" }}
                                                onClick={addHandler}>Add More</span>}

                                        </div>

                                    </div>
                                })
                            }

                            {/* {variatType === "size" ? <button className={`btn  mt-3 ${styles.doneButton}`} onClick={onDoneHandler} >Done</button> : null}
                            {variatType === "color" ? <button className={`btn  mt-3 ${styles.doneButton}`} onClick={onColorDoneHandler} >Done</button> : null}
                            {variatType === "RAM" ? <button className={`btn  mt-3 ${styles.doneButton}`} onClick={onRAMDoneHandler} >Done</button> : null}
                            {variatType === "model" ? <button className={`btn  mt-3 ${styles.doneButton}`} onClick={onModelDoneHandler} >Done</button> : null}
                            {variatType === "storage" ? <button className={`btn  mt-3 ${styles.doneButton}`} onClick={onStorageDoneHandler} >Done</button> : null}
                            {variatType === "screen_size" ? <button className={`btn  mt-3 ${styles.doneButton}`} onClick={onScreenSizeDoneHandler} >Done</button> : null}
                            {variatType === "material" ? <button className={`btn  mt-3 ${styles.doneButton}`} onClick={onMaterialDoneHandler} >Done</button> : null} */}

                            {buttonProps.map((buttonProp, index) => (
                                variatType === buttonProp.type &&
                                <button key={index} className={`btn mt-3 ${styles.doneButton}`} onClick={() => onDoneHandler(buttonProp.type)} >Done</button>
                            ))}


                        </div>
                    </div>

                </div>


                {/* listing of variants */}


                <VairantTables productTypeNmae={productTypeNmae} id={id} setdataSet={setdataSet} dataSet={dataSet} datatype={variatType} submitFun={submitFun} hasVariants={hasVariants} variatType={variatType} productIdVariantsData={productIdVariantsData} handleOpen={handleOpen} handleClose={handleClose}
                    tableData={tableData} settableData={settableData} product={product} upDateVariantsHandler={upDateVariantsHandler} manDatoryAttr={manDatoryAttr} getVariantsData={getVariantsData} deletHandlerWhileCreate={deletHandlerWhileCreate} />

                <div>
                    {/* <button className='btn mt-3' style={{ background: "#105293", color: "white", width: "100px", borderRadius: "20px" }}>Submit</button> */}
                </div>
            </div>

        </>
    )
}
