import React from 'react'
import { useState, useRef } from 'react';
import axios from "axios";
import { useHistory } from 'react-router-dom';
import { toast } from "react-toastify";
import ThemeMaker from '../../components/thememaker';
import { Link } from 'react-router-dom';
import { config } from "../../utils/config";
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom';
import { show, hide } from "react-functional-modal"
import styled from "styled-components"
import jwt_decode from "jwt-decode";
const { apiEndpoint, awsKeyId, awsSecretKey, awsRegion, awsBucket } = config;
const aws = require('aws-sdk');

// const s3Config = {
//     bucketName: 'bucket-name',
//     dirName: 'directory-name',      /* Optional */
//     region: 'ap-south-1',
//     accessKeyId: 'ABCD12EFGH3IJ4KLMNO5',
//     secretAccessKey: 'a12bCde3f4+5GhIjKLm6nOpqr7stuVwxy8ZA9bC0',
//     s3Url: 'https:/your-aws-s3-bucket-url/'     /* Optional */
// }

const ImageContainer = styled.div
`
border: 1px solid black;
border-radius: 27px;
width: 83%;
background: white;
height: 50%;
display: flex;
justify-content: center;
align-items: center;
position: relative;


img{
width: 100%!important;
height: 80%;
object-fit: contain;
}

button{
position: absolute;
top: 4px;
right: 11px;
background: none;
outline: none;
border: none;
}
`

export default function Bankdetails() {
    const param = useParams()
    const id = param.id
    let token = sessionStorage.getItem("us_st_d");
    let decoded = jwt_decode(token);
    let { data } = decoded
    const isApproved = data?.seller_data?.approve_status
    // console.log("decoded----", data)
    const history = useHistory()

    const rawData = {
        id: id,
        bank_name: "",
        upi_address: "",
        bank_account: "",
        ifsc_code: "",
        beneficiary_name: "",
        branch_name: "",
        cancelled_cheque_image: "",

    }
    const rawErrorData = {
        bank_name: false,
        upi_address: false,
        bank_account: false,
        ifsc_code: false,
        beneficiary_name: false,
        branch_name: false,
        commission: false,
        ondc_commission: false,
        ondc_commission: false,
        seller_source: false,
        cancelled_cheque_image: false,


    }
    const [bankdetailsData, setbankdetailsData] = useState(rawData);
    const [bankdetailsDataError, setbankdetailsDataError] = useState(rawErrorData);
    const renderAfterCalled = useRef(false);
    if (!renderAfterCalled.current) {
        givedta()
    }
    async function givedta() {
        const { data } = await axios({
            url: `${apiEndpoint}/getbankdetails`,
            method: "POST",
            headers: {  
                Authorization: `Bearer ${sessionStorage.getItem('us_st_d')}`
            },
            data: {
                seller_id: id
            }
        });

        if (data.status === 200) {
            setbankdetailsData(data.data[0])
            renderAfterCalled.current = true;
        }

    }

    const inputHandler = (e) => {
        setbankdetailsData({ ...bankdetailsData, [e.target.name]: e.target.value });
    }
    const errorHandler = (e) => {
        if (e.target.value) {
            setbankdetailsDataError({ ...bankdetailsDataError, [e.target.name]: "valid" });
        } else {
            setbankdetailsDataError({ ...bankdetailsDataError, [e.target.name]: true });
        }
    }

    const onSubmitHandler = async () => {
        console.log("bankdetailsData => ", bankdetailsData);
        console.log("bankdetailsDataError => ", bankdetailsDataError);
        const { id,
            bank_name,
            upi_address,
            bank_account,
            ifsc_code,
            beneficiary_name,
            branch_name,
        } = bankdetailsData;
        if (bank_name && upi_address && bank_account && ifsc_code && beneficiary_name && branch_name) {
            // dispatch(registerSeller(bankdetailsData));
            // console.log(">>>>>>>>>>>>", bankdetailsData)
            try {
                const { data } = await axios({
                    url: `${apiEndpoint}/bankdetails`,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
                    },
                    data: bankdetailsData

                })


                if (data.status === 200) {
                    toast.success("Details submitted Successfully", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setTimeout(() => {
                        if (decoded.data.user.approve_status == 0) {
                            history.push("/pending")
                        }
                    }, 2000)


                }

                // console.log("data from server---", data)
                // console.log("data from server---", bankdetailsData)

            } catch (err) {
                console.log(err)
            }
        } else {
            toast.error("Please fill all the fields !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const productImageHandler = async (e) => {
        try {
            const file = e.target.files[0];
            let myFile = file.name.split(".");
            let myFileType = myFile[myFile.length - 1];
            const s3 = new aws.S3({
                accessKeyId: awsKeyId,
                secretAccessKey: awsSecretKey,
                region: awsRegion
            });
            const params = {
                Bucket: "ondc-marketplace",
                Key: `images/${uuidv4()}.${myFileType}`,
                Body: file,
                ContentType: file?.type
            }
            let result = await s3.upload(params).promise();
            const { Location } = result;
            console.log(Location);

            setbankdetailsData({ ...bankdetailsData, [e.target.name]: Location })

        } catch (exception) {
            console.log(exception);
        }
    };
   

    const open = (image) => {
        show(
            <ImageContainer>
                <img src={image} alt="" />
                <button onClick={() => {
                    hide("key#1")
                }}>x</button>
            </ImageContainer>,
            { key: "key#1" }
        )
    }
    return (


        <div className="row m-0 w-100 mt-1">
            <div className="col-md-12" style={{ background: "white" }}>
                <div className="card shadow mb-4">
                    {/* <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-bg-gradient-dark">
                                Bank Details
                            </h6>
                        </div> */}
                    <div className="card-body" style={{ minHeight: '70vh' }}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className='text-dark' style={{ color: "black", fontWeight: 600 }} >
                                        Bank Name
                                        <span className="text-danger" > * </span>
                                        {
                                            bankdetailsDataError?.bank_name !== "valid" && bankdetailsDataError?.bank_name !== false ? (
                                                <span className="text-danger tiny_size" > This field is required ! </span>
                                            ) : null
                                        }
                                    </label>
                                    <input type="text" className="form-control" name="bank_name" placeholder="Enter Bank Name"
                                        value={bankdetailsData.bank_name}
                                        onChange={(e) => inputHandler(e)}
                                        onBlur={(e) => errorHandler(e)}
                                        readOnly={isApproved === 1 ? true : false}
                                    />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className='text-dark' style={{ color: "black", fontWeight: 600 }}>Bank Account Number<span className="text-danger" >*</span>
                                        {
                                            bankdetailsDataError?.bank_account !== "valid" && bankdetailsDataError?.bank_account !== false ? (
                                                <span className="text-danger tiny_size" > This field is required ! </span>
                                            ) : null
                                        }
                                    </label>
                                    <input type="text" className="form-control" name="bank_account" placeholder="Enter Account Number"
                                        value={bankdetailsData.bank_account}
                                        onChange={(e) => inputHandler(e)}
                                        onBlur={(e) => errorHandler(e)}
                                        readOnly={isApproved === 1 ? true : false}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className='text-dark fw-bold' style={{ color: "black", fontWeight: 600 }}>IFSC Code<span className="text-danger" >*</span>
                                        {
                                            bankdetailsDataError?.ifsc_code !== "valid" && bankdetailsDataError?.ifsc_code !== false ? (
                                                <span className="text-danger tiny_size" > This field is required ! </span>
                                            ) : null
                                        }
                                    </label>
                                    <input type="text" name="ifsc_code" className="form-control" placeholder="Enter IFSC Code"
                                        value={bankdetailsData.ifsc_code}
                                        onChange={(e) => inputHandler(e)}
                                        onBlur={(e) => errorHandler(e)}
                                        readOnly={isApproved === 1 ? true : false}
                                    />
                                </div>
                            </div>


                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className='text-dark' style={{ color: "black", fontWeight: 600 }}>Beneficiary Name<span className="text-danger" >*</span>
                                        {
                                            bankdetailsDataError?.beneficiary_name !== "valid" && bankdetailsDataError?.beneficiary_name !== false ? (
                                                <span className="text-danger tiny_size" > This field is required ! </span>
                                            ) : null
                                        }
                                    </label>
                                    <input type="text" name="beneficiary_name" className="form-control" placeholder="Enter Benificiary Name"
                                        value={bankdetailsData.beneficiary_name}
                                        onChange={(e) => inputHandler(e)}
                                        onBlur={(e) => errorHandler(e)}
                                        readOnly={isApproved === 1 ? true : false}

                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className='text-dark' style={{ color: "black", fontWeight: 600 }} >Bank Branch Name<span className="text-danger" >*</span>
                                        {
                                            bankdetailsDataError?.branch_name !== "valid" && bankdetailsDataError?.branch_name !== false ? (
                                                <span className="text-danger tiny_size" > This field is required ! </span>
                                            ) : null
                                        }
                                    </label>
                                    <input type="text" className="form-control" name="branch_name" placeholder="Enter Branch Name"
                                        value={bankdetailsData.branch_name}
                                        onChange={(e) => inputHandler(e)}
                                        onBlur={(e) => errorHandler(e)}
                                        readOnly={isApproved === 1 ? true : false}

                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className='text-dark' style={{ color: "black", fontWeight: 600 }} >UPI Address <span className="text-danger" >*</span>
                                        {
                                            bankdetailsDataError?.upi_address !== "valid" && bankdetailsDataError?.upi_address !== false ? (
                                                <span className="text-danger tiny_size" > This field is required ! </span>
                                            ) : null
                                        }
                                    </label>
                                    <input type="text" className="form-control" name="upi_address" placeholder="Enter UPI Address"
                                        value={bankdetailsData.upi_address}
                                        onChange={(e) => inputHandler(e)}
                                        onBlur={(e) => errorHandler(e)}
                                        readOnly={isApproved === 1 ? true : false}
                                    />
                                </div>
                            </div>

                            <div className="col-md-6 d-none">
                                <label className='text-dark' style={{ color: "black", fontWeight: 600 }}> Cancelled Cheque Image
                                    {/* {
                                                sellerDataError?.address_proof_image !== "valid" && sellerDataError?.address_proof_image !== false ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            } */}
                                </label>
                                <div className="form-group">
                                    <input type="file" name="address_proof_image" className="form-control" disabled={true}
                                    />
                                </div>


                            </div>

                            <div className="col-md-6">

                                <div className="form-group">
                                    <label className='text-dark' style={{ color: "black", fontWeight: 600 }}>
                                        Cancelled Cheque Image

                                    </label>
                                    {bankdetailsData.cancelled_cheque_image && <a
                                        // href={product.image}
                                        onClick={() => open(bankdetailsData.cancelled_cheque_image)}
                                        target="_blank"
                                        style={{
                                            fontSize: "10px",
                                            marginLeft: "3px",
                                            textDecoration: "underline",
                                        }}
                                    >
                                        View Document
                                    </a>}

                                    <input
                                        type="file"
                                        className="form-control"
                                        onChange={(e) => productImageHandler(e)}
                                        accept="image/*"
                                        name="cancelled_cheque_image"
                                        multiple
                                        // onBlur={(e) => errorHandler(e)}
                                        id="image"
                                    // disabled={isApproved === 1 ? true : false}
                                    />
                                </div>
                            </div>


                            <div className="col-md-12">

                                <button type="submit" className="btn btn-primary"
                                    onClick={onSubmitHandler}>
                                    Update
                                </button>
                            </div>
                            {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                        </div>

                    </div>
                </div>
            </div>
        </div>


    )
}
