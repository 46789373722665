/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
import { v4 as uuidv4 } from 'uuid';
import { Multiselect } from "multiselect-react-dropdown"
import Bankdetails from "../SellerRegisteration/Bankdetails";
import Personaldetails from "../SellerRegisteration/Personaldetails";
import styles from "./Sellerdetails.module.css"
import styled from "styled-components"
import style from "../SellerRegisteration/seller_register.module.css"
import { show, hide } from "react-functional-modal"
import jwt_decode from "jwt-decode";
import DatePicker from "react-datepicker";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Modal as AntModal, notification } from "antd"
const { apiEndpoint, awsKeyId, awsSecretKey, awsRegion, awsBucket } = config;
const aws = require('aws-sdk');


const ImageContainer = styled.div
    `
 border: 1px solid black;
    border-radius: 27px;
  width: 83%;
  background: white;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

    
    img{
      width: 100%!important;
    height: 80%;
    object-fit: contain;
  }

  button{
    position: absolute;
    top: 4px;
    right: 11px;
    background: none;
    outline: none;
    border: none;
  }
    `

const Sellerdetails = () => {
    const [showThank, setshowThank] = useState(false);


    let params = useParams();
    const [pageNamem, setpageName] = useState(true);
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const decode = tokenDecode();
    const rawSData = {
        name: '',
        email: '',
        company_name: '',
        contact_no: '',
        gst_no: '',
        pan_no: '',
        provider_id: ""

    }
    const rawSErrorData = {
        name: false,
        email: false,
        company_name: false,
        contact_no: false,
        gst_no: false,
        pan_no: false,
        provider_id: false

    }
    const decodededTok = tokenDecode();
    const { data } = decodededTok;

    const isApproved = data?.seller_data?.approve_status
    const [sellerData, setSellerData] = useState(rawSData);
    const [sellerDataError, setSellerDataError] = useState(rawSErrorData);
    useEffect(() => {

        let rawToken = sessionStorage.getItem("us_st_d");
        var decode = jwt_decode(rawToken);
        let decodedUserdata = decode.data;
        setSellerData({ ...sellerData, name: decodedUserdata.user.name, company_name: decodedUserdata.seller_data.company_name, contact_no: decodedUserdata.seller_data.contact_no, email: decodedUserdata.user.email, provider_id: "hulid" })
        // getId();
    }, [])

    function tabredirect() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const tab = urlParams.get('tab');
        return tab
    }
    const [renderComponent, setrenderComponent] = useState(tabredirect())

    const rawData = {
        opening_time: '',
        closing_days: "",
        ondc_domain: "",
        opening_days: "",
        closing_time: '',
        incorporation_date: "",
        images: "",
        commission: 1,
        ondc_commission: "",
        fssai_licence: "",
        seller_commission: 1,
        returnable: "",
        replaceable: "",
        customer_care_contact: "",
        cancelable: "",
        return_window: "",
        seller_pickup_return: "",
        time_to_ship: "",
        cod: "",
        delivery_fee: "",
        packing_fee: "",
        status: "",
        radius: "",
        description: "",
        short_description: "",
        notification: ""
        // status: "true",
        // approved_status: "0"

    };
    const errorRawData = {
        opening_time: false,
        closing_days: false,
        opening_days: false,
        closing_time: false,
        incorporation_date: false,
        images: false,
        commission: false,
        ondc_commission: false,
        fssai_licence: false,
        seller_commission: false,
        returnable: false,
        replaceable: false,
        customer_care_contact: false,
        cancelable: false,
        return_window: false,
        seller_pickup_return: false,
        time_to_ship: false,
        cod: false,
        radius: false,
        delivery_fee: false,
        packing_fee: false,
        // status: false,
        status: false,
        description: false,
        short_description: false,
        notification: false

    };
    const [userData, setUserData] = useState(rawData);
    const [userDataError, setUserDataError] = useState(errorRawData);
    const timeToShipLength = new Array(1, 2, 3, 4, 5, 6, 7, 8, 9, 10);
    //code for the days selection:-
    // console.log(userData)
    const [Oweekdays, setOweekdays] = useState([
        { day: "Monday", id: "1" }, { day: "Tuesday", id: "2" }, { day: "Wednesday", id: "3" }, { day: "Thursday", id: "4" }, { day: "Friday", id: "5" }, { day: "Saturday", id: "6" }, { day: "Sunday", id: "7" },
    ])
    const [Cweekdays, setCweekdays] = useState([
        { cday: "Monday", id: "1" }, { cday: "Tuesday", id: "2" }, { cday: "Wednesday", id: "3" }, { cday: "Thursday", id: "4" }, { cday: "Friday", id: "5" }, { cday: "Saturday", id: "6" }, { cday: "Sunday", id: "7" },
    ])
    const [domain, setDomain] = useState([
        { cday: "Grocery", id: "ONDC:RET10" }, { cday: "Fashion ", id: "ONDC:RET12" }, { cday: "BPC", id: "ONDC:RET13" }, { cday: "Electronics", id: "ONDC:RET14" }, { cday: "Appliances", id: "ONDC:RET15" }, { cday: "Home & Kithcen", id: "ONDC:RET16" }, { cday: "Health & Wellness", id: "ONDC:RET18" },
    ])
    const [selectedCDays, setSelectedCDays] = useState();
    const [selectedDomain, setselectedDomain] = useState();
    const [selectedODays, setSelectedODays] = useState();
    const [selectedDates, setSelectedDates] = useState([]);
    const [startDate, setStartDate] = useState(new Date());

    const showModal = () => {
        setIsModalVisible(true);
    };
    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const open = () => {
        show(
            <ImageContainer>
                <img src={userData.images} alt="" />
                <button onClick={() => {
                    hide("key#1")
                }}>x</button>
            </ImageContainer>,
            { key: "key#1" }
        )
    }

    //code for the days selection end:-

    const inputHandler = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    };
    const errorHandler = (e) => {
        if (e.target.value) {
            setUserDataError({ ...userDataError, [e.target.name]: false });
        } else {
            setUserDataError({ ...userDataError, [e.target.name]: true });
        }
    }
    const getRole = async () => {
        let payload;
        if (decode?.data?.user?.seller_id === 0) {
            payload = decode?.data?.user?.parent_id
        } else {
            payload = decode?.data?.user?.seller_id
        }
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/sellerShop`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
                },
                data: { seller_id: payload }

            });

            if (data.status === 200) {
                setUserData(data.response);
                setpageName(false);

                //for holidays-->
                if (data?.response?.holidays !== null) {
                    let t = data?.response.holidays;
                    let z = t.split(",")
                    setSelectedDates(z)
                }
                // Split opening_days and closing_days by comma and trim any whitespace
                const openingDays = data?.response?.opening_days?.split(",")?.map((day) => day.trim());
                const closingDays = data?.response?.closing_days?.split(",")?.map((day) => day.trim());
                const domainsdata = data?.response?.ondc_domain?.split(",")?.map((day) => day.trim());
               
                const filteredCDays = Cweekdays.filter(
                    (day) => closingDays?.includes(day.id)
                );
                const filteredODays = Oweekdays.filter(
                    (day) => openingDays?.includes(day.id)
                );
                const filteredDomains = domain.filter(
                    (day) => domainsdata?.includes(day.id)
                );
                setSelectedODays(filteredODays?.map(item => item));
                setSelectedCDays(filteredCDays?.map(item => item));
                setselectedDomain(filteredDomains?.map(item => item));
            }
        } catch (error) { }
    };
    const submitSellerDetailsHandler = async () => {
        setLoading(true);

        try {
            const {
                opening_days,
                opening_time,
                closing_days,
                closing_time,
                incorporation_date,
                images,
                commission,
                ondc_commission,
                fssai_licence,
                seller_commission,
                returnable,
                replaceable,
                customer_care_contact,
                cancelable,
                return_window,
                seller_pickup_return,
                time_to_ship,
                cod,
                delivery_fee,
                packing_fee,
                short_description,
                description,
                status
            } = userData;
            console.log("userdetails------>", userData)
            if (opening_days && opening_time && closing_time && returnable !== "" && replaceable !== "" && customer_care_contact && cancelable !== "" && return_window !== "" && seller_pickup_return !== "" && time_to_ship !== "" && cod !== "" && delivery_fee !== "" && packing_fee !== "" && incorporation_date  && short_description !=="" && description !=="" && status) {
                const { data } = await axios({
                    url: `${apiEndpoint}/addshopdetails`,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
                    },
                    data: { ...userData, holidays: selectedDates.toString() },
                });

                if (data.status == 200) {
                    toast.success("Details submitted Successfully");
                    setUserData(data.response);
                    setTimeout(() => {
                        pageChangeController()
                    }, 3000)
                }
            } else {
                toast.error("Please fill all the fields !");
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error("404 error !");
        }
    };

    const onSelectHandler = (selectedList, selectedItem) => {
        let daySelected = selectedList.map(item => item.id);
        let z = daySelected.toString()
        setUserData({ ...userData, opening_days: z });
        setSelectedODays(selectedList.map(item => item));
    }
    const onRemoveHandler = (selectedList, selectedItem) => {
        let daySelected = selectedList.map(item => item.id);
        let z = daySelected.toString()
        setUserData({ ...userData, opening_days: z });
        setSelectedODays(selectedList.map(item => item));
    }

    const inputeHandler = (e) => {
        setSellerData({ ...sellerData, [e.target.name]: e.target.value });
    }

    const erroreHandler = (e) => {
        if (e.target.value) {
            setSellerDataError({ ...sellerDataError, [e.target.name]: "valid" });
        } else {
            setSellerDataError({ ...sellerDataError, [e.target.name]: true });
        }
    }

    const productImageHandler = async (e) => {
        try {
            const file = e.target.files[0];
            let myFile = file.name.split(".");
            let myFileType = myFile[myFile.length - 1];
            const s3 = new aws.S3({
                accessKeyId: awsKeyId,
                secretAccessKey: awsSecretKey,
                region: awsRegion
            });
            const params = {
                Bucket: "ondc-marketplace",
                Key: `images/${uuidv4()}.${myFileType}`,
                Body: file,
                ContentType: file?.type
            }
            let result = await s3.upload(params).promise();
            const { Location } = result;
            setUserData({ ...userData, [e.target.name]: Location })
        } catch (exception) {
        }
    };
   

    function renderController(e) {
        setrenderComponent(e.target.value)
        // givedta()
    }


    function pageChangeController() {
        if (renderComponent == "shopdetails") {
            setrenderComponent("personaldetails")
        }
        if (renderComponent == "personaldetails") {
            setrenderComponent("bankdetails")
        }
    }

    // code logics for holidays start:----

    const handleDateSelect = async (date) => {
        setStartDate(date)
        let z = date;
        let c = z.toLocaleDateString()
        const dateStr = c;
        // Create a new Date object by parsing the date string
        const dates = new Date(dateStr);

        // Extract the year, month, and day from the Date object
        const year = dates.getFullYear();
        const month = String(dates.getMonth() + 1).padStart(2, '0');
        const day = String(dates.getDate()).padStart(2, '0');

        // Format the date in the desired format
        const formattedDate = `${year}-${month}-${day}`;
        setSelectedDates((startDate) => [...startDate, formattedDate]);
    };

    const RemoveItem = (indexToRemove) => {
        let x = [...selectedDates]
        x.splice(indexToRemove, 1);
        setSelectedDates(x)
    }

    // code logics for holidays end:----

     useEffect(() => {
        if(renderComponent==="shopdetails"){
            getRole();
        }
       
        if (sessionStorage.getItem("showthank")) {
            setshowThank(true)
        }


    }, [renderComponent]);

    return (
        <>
            <ThemeMaker>
                <div className="row w-100 m-0 mt-2">
                    <div className="col-md-12 ">
                        <div className="card shadow mb-4">

                            <div className="card-header p-0">


                                {showThank && <div className={style.thanks_container} style={{ background: "white" }}>

                                    {/* <div className="w-100 d-flex justify-content-center" >
                                        <img src={thanks} alt="" className="w-50" />
                                    </div> */}

                                    <div className={style.thanks_statement}>
                                        <p>Thank you for registering with Seller Portal</p>
                                        <p className='m-1' style={{ fontSize: "13px", background: "white", marginBottom: "12px" }}>Your request is under process, your outlet will be live once request is approved.</p>
                                        <p className='m-1' style={{ fontSize: "13px", background: "white", marginBottom: "12px" }}>In mean time please confirm shop details.</p>
                                    </div>



                                </div>}
                                <h6 className="m-0 font-weight-bold text-primary">
                                    {/* {pageNamem ? "Add Shop Details" : "Update Details"} */}
                                    <div className={styles.tabs}>


                                        <button onClick={renderController} value="shopdetails" className={renderComponent == "shopdetails" ? `${styles.tab} ${styles.active}` : `${styles.tab}`}>
                                            Shop Details
                                        </button>
                                        <button onClick={renderController} value="personaldetails" className={renderComponent == "personaldetails" ? `${styles.tab} ${styles.active}` : `${styles.tab}`}>
                                            Personal   Details</button>
                                        <button onClick={renderController} value="bankdetails" className={renderComponent == "bankdetails" ? `${styles.tab} ${styles.active}` : `${styles.tab}`}>
                                            Bank Details</button>

                                    </div>
                                </h6>
                            </div>
                            {renderComponent == "shopdetails" ? <div className="card-body">

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className='text-dark' style={{ color: "black", fontWeight: 600 }}>Opening Time
                                                <span className="text-danger" > * </span>
                                                {
                                                    userDataError?.opening_time ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input
                                                type="time"
                                                className="form-control"
                                                name="opening_time"
                                                placeholder="Enter Opening Time"
                                                value={userData.opening_time}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            />


                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className='text-dark' style={{ color: "black", fontWeight: 600 }}>Closing Time
                                                <span className="text-danger" > * </span>
                                                {
                                                    userDataError?.closing_time ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input
                                                type="time"
                                                name="closing_time"
                                                className="form-control"
                                                placeholder="Enter Closing Time"
                                                value={userData.closing_time}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            />
                                        </div>
                                    </div>


                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className='text-dark' style={{ color: "black", fontWeight: 600 }}>
                                                Seller Name
                                                <span className="text-danger" > * </span>
                                                {
                                                    sellerDataError?.name !== "valid" && sellerDataError?.name !== false ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input type="text" className="form-control" name="name" placeholder="Name"
                                                value={sellerData.name}
                                                onChange={(e) => inputeHandler(e)}
                                                onBlur={(e) => erroreHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className='text-dark' style={{ color: "black", fontWeight: 600 }}>Company name<span className="text-danger" >*</span>
                                                {
                                                    sellerDataError?.company_name !== "valid" && sellerDataError?.company_name !== false ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input type="text" className="form-control" name="company_name" placeholder="Company name"
                                                value={sellerData.company_name}
                                                onChange={(e) => inputeHandler(e)}
                                                onBlur={(e) => erroreHandler(e)}
                                                readOnly={isApproved === 1 ? true : false}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className='text-dark' style={{ color: "black", fontWeight: 600 }}>Email address<span className="text-danger" >*</span>
                                                {
                                                    sellerDataError?.email !== "valid" && sellerDataError?.email !== false ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input type="text" name="email" className="form-control" placeholder="Email"
                                                value={sellerData.email}
                                                onChange={(e) => inputeHandler(e)}
                                                onBlur={(e) => erroreHandler(e)}
                                            />
                                        </div>
                                    </div>


                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className='text-dark' style={{ color: "black", fontWeight: 600 }}>Contact No.<span className="text-danger" >*</span>
                                                {
                                                    sellerDataError?.contact_no !== "valid" && sellerDataError?.contact_no !== false ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input type="number" name="contact_no" className="form-control" placeholder="Contact number"
                                                value={sellerData.contact_no}
                                                onChange={(e) => inputeHandler(e)}
                                                onBlur={(e) => erroreHandler(e)}
                                                onWheel={(e) => e.target.blur()}

                                            />
                                        </div>
                                    </div>
                                    {/* <div className="col-md-6">
                                        <div className="form-group">
                                            <label >GST<span className="text-danger" >*</span>
                                                {
                                                    sellerDataError?.gst_no !== "valid" && sellerDataError?.gst_no !== false ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input type="text" className="form-control" name="gst_no" placeholder="GST number"
                                                maxlength="15"
                                                value={sellerData.gst_no}
                                                onChange={(e) => inputeHandler(e)}
                                                onBlur={(e) => erroreHandler(e)}

                                            />
                                        </div>
                                    </div>


                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >PAN Number <span className="text-danger" >*</span>
                                                {
                                                    sellerDataError?.pan_no !== "valid" && sellerDataError?.pan_no !== false ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input type="text" className="form-control" name="pan_no" placeholder="PAN number"
                                                maxlength="10"
                                                value={sellerData.pan_no}
                                                onChange={(e) => inputeHandler(e)}
                                                onBlur={(e) => erroreHandler(e)}
                                            />
                                        </div>

                                    </div> */}

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label  className='text-dark' style={{ color: "black", fontWeight: 600 }} >Short Description <span className="text-danger" >*</span>
                                                {
                                                    userDataError?.short_description ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input type="text" name="short_description" className="form-control" placeholder="Enter Short Description"
                                                value={userData.short_description}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label  className='text-dark' style={{ color: "black", fontWeight: 600 }} >Long Description <span className="text-danger" >*</span>
                                                {
                                                    userDataError?.description ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input type="text" name="description" className="form-control" placeholder="Enter Long Description"
                                                value={userData.description}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className='text-dark' style={{ color: "black", fontWeight: 600 }}>Incorporation Date
                                                <span className="text-danger" > * </span>
                                                {
                                                    userDataError?.incorporation_date ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input
                                                style={{ border: "1px solid lightgrey" }}
                                                type="date"
                                                name="incorporation_date"
                                                className="form-control"
                                                placeholder="Enter Date"
                                                value={userData.incorporation_date}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className='text-dark' style={{ color: "black", fontWeight: 600 }}>Image
                                                <span className="text-danger" > * </span>
                                                {
                                                    userDataError?.images ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            {userData.images && <a
                                                onClick={showModal}
                                                target="_blank"
                                                style={{
                                                    fontSize: "10px",
                                                    marginLeft: "3px",
                                                    textDecoration: "underline",
                                                }}
                                            >
                                                View Document
                                            </a>}
                                            <input
                                                type="file"
                                                className="form-control"
                                                onChange={(e) => productImageHandler(e)}
                                                accept="image/*"
                                                name="images"
                                                multiple
                                                onBlur={(e) => errorHandler(e)}
                                                id="image"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className='text-dark' style={{ color: "black", fontWeight: 600 }}>Maximum  Commission
                                                <span className="text-danger" > * </span>
                                                {
                                                    userDataError?.ondc_commission ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input
                                                type="text"
                                                name="ondc_commission"
                                                className="form-control"
                                                value={userData.ondc_commission}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                placeholder="Enter Max Commission"
                                                readOnly={isApproved === 1 ? true : false}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6" style={{ display: "none" }}>
                                        <div className="form-group">
                                            <label className='text-dark' style={{ color: "black", fontWeight: 600 }}>Seller Commission
                                                <span className="text-danger" > * </span>
                                                {
                                                    userDataError?.seller_commission ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input
                                                type="text"
                                                name="seller_commission"
                                                className="form-control"
                                                value="1"
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                placeholder="Enter ONDC Commission"
                                                readOnly={isApproved === 1 ? true : false}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6" style={{ display: "none" }}>
                                        <div className="form-group">
                                            <label className='text-dark' style={{ color: "black", fontWeight: 600 }}>Commission
                                                <span className="text-danger" > * </span>
                                                {
                                                    userDataError?.commission ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input
                                                type="text"
                                                name="commission"
                                                className="form-control"
                                                placeholder="Enter Commission"
                                                value="1"
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                readOnly={isApproved === 1 ? true : false}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className='text-dark' style={{ color: "black", fontWeight: 600 }}>Fssai License Number
                                                {/* <span className="text-danger" > * </span>
                                                {
                                                    userDataError?.fssai_licence ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                } */}
                                            </label>
                                            <input
                                                type="text"
                                                maxLength="14"
                                                name="fssai_licence"
                                                className="form-control"
                                                value={userData.fssai_licence}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                placeholder="Enter License Number"
                                                readOnly={isApproved === 1 ? true : false}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className='text-dark' style={{ color: "black", fontWeight: 600 }}>Product returnable</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                userDataError?.returnable ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <select
                                                className="form-control"
                                                value={userData.returnable}
                                                name="returnable"
                                                id=""
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            >
                                                <option value="">Select returnable</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className='text-dark' style={{ color: "black", fontWeight: 600 }}>Sell On ONDC</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                userDataError?.status ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <select
                                                className="form-control"
                                                value={userData.status}
                                                name="status"
                                                id=""
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            >
                                                <option value="">Select Sell on ONDC</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className='text-dark' style={{ color: "black", fontWeight: 600 }}>Require Order Notification</label>
                                            {/* <span className="text-danger" > * </span> */}
                                            {/* {
                                                userDataError?.notification ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            } */}
                                            <select
                                                className="form-control"
                                                value={userData.notification}
                                                name="notification"
                                                id=""
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            >
                                                <option value="" disabled selected >Select </option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className='text-dark' style={{ color: "black", fontWeight: 600 }}>Product Replaceable</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                userDataError?.replaceable ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <select
                                                className="form-control"
                                                value={userData.replaceable}
                                                name="replaceable"
                                                id=""
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            >
                                                <option value="">Select Replaceable</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                        </div>
                                    </div>


                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className='text-dark' style={{ color: "black", fontWeight: 600 }}>Product Cancelable</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                userDataError?.cancelable ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <select
                                                className="form-control"
                                                value={userData.cancelable}
                                                name="cancelable"
                                                id=""
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            >
                                                <option value="">Select Cancelable</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className='text-dark' style={{ color: "black", fontWeight: 600 }}>Customer Care Contact
                                                <span className="text-danger" > * </span>
                                                {
                                                    userDataError?.customer_care_contact ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input
                                                type="text"
                                                name="customer_care_contact"
                                                className="form-control"
                                                value={userData.customer_care_contact}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                placeholder="Customer Care Number"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className='text-dark' style={{ color: "black", fontWeight: 600 }}>Cash On delivery</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                userDataError?.cod ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <select
                                                className="form-control"
                                                value={userData.cod}
                                                name="cod"
                                                id=""
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            >
                                                <option value="">Select cash on delivery</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className='text-dark' style={{ color: "black", fontWeight: 600 }}>Delivery Charges </label>
                                            <span className="text-danger" > * </span>
                                            {
                                                userDataError?.delivery_fee ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }

                                            <input
                                                type="text"
                                                name="delivery_fee"
                                                className="form-control"
                                                value={userData.delivery_fee}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                placeholder="Enter Delivery Charges"
                                            />
                                        </div>
                                    </div>



                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className='text-dark' style={{ color: "black", fontWeight: 600 }}>Packing Charges</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                userDataError?.packing_fee ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <input
                                                type="text"
                                                name="packing_fee"
                                                className="form-control"
                                                value={userData.packing_fee}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                placeholder="Enter Packing Charges"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className='text-dark' style={{ color: "black", fontWeight: 600 }}>Seller pickup return</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                userDataError?.seller_pickup_return ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <select
                                                className="form-control"
                                                value={userData.seller_pickup_return}
                                                name="seller_pickup_return"
                                                id=""
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            >
                                                <option value="">Select seller return pickup</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className='text-dark' style={{ color: "black", fontWeight: 600 }}>Return Window</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                userDataError?.return_window ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <select
                                                className="form-control"
                                                value={userData.return_window}
                                                name="return_window"
                                                id=""
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            >
                                                <option value="">Select Return Window</option>
                                                {timeToShipLength.map((item) => (
                                                    <option value={`P${item}D`}>{item == 1 ? `${item}Day` : `${item}Days`}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className='text-dark' style={{ color: "black", fontWeight: 600 }}>Time to ship</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                userDataError?.time_to_ship ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <select
                                                className="form-control"
                                                style={{ marginTop: "7px" }}
                                                value={userData.time_to_ship}
                                                name="time_to_ship"
                                                id=""
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            >
                                                <option value="">Select Time to Ship</option>
                                                {timeToShipLength.map((item) => (
                                                    <option value={`P${item}D`}>{item == 1 ? `${item}Day` : `${item}Days`}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label> Product Domains  </label>

                                            <Multiselect options={domain} selectedValues={selectedDomain} displayValue="cday" onSelect={(selectedList, selectedItem) => {
                                                let daySelected = selectedList.map(item => item.id)
                                                let z = daySelected.toString()
                                                setUserData({ ...userData, ondc_domain: z });
                                                setselectedDomain(selectedList.map(item => item));
                                            }}
                                                onRemove={(selectedList, selectedItem) => {
                                                    let daySelected = selectedList.map(item => item.id);
                                                    let z = daySelected.toString()
                                                    setUserData({ ...userData, ondc_domain: z });
                                                    setselectedDomain(selectedList.map(item => item));
                                                }}
                                                >

                                                </Multiselect>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">

                                            <label className='text-dark' style={{ color: "black", fontWeight: 600 }}>Holidays   </label>
                                            {/* <span className="text-danger" > * </span> */}
                                            <div style={{ marginLeft: "5px", display: "inline-block", }}>
                                                <DatePicker selected={startDate} onChange={(date) => handleDateSelect(date)} />
                                            </div>


                                            <ul className="multiselect-container multiSelectContainer" style={{ display: "flex", marginTop: "3px", border: "1px solid lightgrey", padding: "10px", borderRadius: "5px", flexWrap: "wrap", minWidth: "80%", minHeight: "40px" }}>
                                                {selectedDates.map((date, index) => (
                                                    <>
                                                        <li key={date.toString()}
                                                            style={{ padding: "10px", background: "#0096fb", color: "white", borderRadius: "40px", marginBottom: "22px", lineHeight: "10px", fontSize: "13px", display: "flex", justifyContent: "center" }}>
                                                            {date}

                                                            <span onClick={() => RemoveItem(index)}
                                                                style={{ margin: "-2px 0px 0px 10px" }} > < CancelOutlinedIcon style={{ fontSize: "14px", fontWeight: "600" }} /> </span>
                                                        </li>&nbsp;&nbsp;

                                                    </>
                                                ))}
                                            </ul>




                                        </div>
                                    </div>



                                    {/* <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Service Radius (in km)</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                userDataError?.radius ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <input
                                                type="text"
                                                name="radius"
                                                className="form-control"
                                                placeholder="Enter Radius"
                                                value={userData.radius}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            />

                                        </div>
                                    </div> */}


                                    {/* <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Status</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                userDataError?.status ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <select
                                                className="form-control"
                                                value={userData.status}
                                                name="status"
                                                id=""
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            >
                                                <option value="">Select Status</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>

                                            </select>
                                        </div>
                                    </div> */}
                                    {/* {  decode.data.seller_data.id ==1 &&  <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Approved Status</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                userDataError?.approved_status ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <select
                                                className="form-control"
                                                value={userData.approved_status}
                                                name="approved_status"
                                                id=""
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            >
                                                <option value="0">New Request</option>
                                                <option value="1">Active</option>
                                                <option value="2">Block</option>

                                            </select>
                                        </div>
                                    </div>}  */}


                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>
                                                <label className='text-dark m-0' style={{ color: "black", fontWeight: 600 }}>Opening Days
                                                    <span className="text-danger" > * </span>
                                                    {
                                                        userDataError?.opening_days !== "valid" && userDataError?.opening_days !== false ? (
                                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                                        ) : null
                                                    }
                                                </label>
                                            </label>
                                            {/* <input
                                                type="text"
                                                name="opening_days"
                                                className="form-control"
                                                placeholder="Enter Open Days"
                                                value={userData.opening_days}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            /> */}

                                            <Multiselect options={Oweekdays} selectedValues={selectedODays} displayValue="day" onSelect={onSelectHandler} onRemove={onRemoveHandler}></Multiselect>


                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className='text-dark' style={{ color: "black", fontWeight: 600 }}>Closing Days
                                                {/* <span className="text-danger" > * </span> */}
                                                {
                                                    userDataError?.closing_days !== "valid" && userDataError?.closing_days !== false ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>

                                            <Multiselect options={Cweekdays} selectedValues={selectedCDays} displayValue="cday" onSelect={(selectedList, selectedItem) => {
                                                let daySelected = selectedList.map(item => item.id)
                                                let z = daySelected.toString()
                                                setUserData({ ...userData, closing_days: z });
                                                setSelectedCDays(selectedList.map(item => item));
                                            }}
                                                onRemove={(selectedList, selectedItem) => {
                                                    let daySelected = selectedList.map(item => item.id);
                                                    let z = daySelected.toString()
                                                    setUserData({ ...userData, closing_days: z });
                                                    setSelectedCDays(selectedList.map(item => item));
                                                }}></Multiselect>
                                        </div>
                                    </div>


                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className='text-dark' style={{ color: "black", fontWeight: 600 }}>
                                                Business Tags
                                            </label>
                                            <input type="text" className="form-control" name="name" placeholder="Enter Business Tags"

                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label className='text-dark' style={{ color: "black", fontWeight: 600 }}>
                                                Select Social Platform
                                            </label>
                                            <select name="" id="" className="form-control">
                                                <option value="" selected disabled>   Select</option>
                                                <option value="">Facebook</option>
                                                <option value="">Youtube</option>
                                                <option value="">Linked In</option>
                                                <option value="">Twitter</option>
                                                <option value="">Instagram</option>
                                            </select>


                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label className='text-dark' style={{ color: "black", fontWeight: 600 }}>
                                                Social Links
                                            </label>
                                            <input type="text" className="form-control" name="name" placeholder="Enter Social Links"

                                            />
                                        </div>
                                    </div>



                                    <div className="col-md-12">
                                        {pageNamem ? <button
                                            type="submit"
                                            className="btn btn-primary"
                                            onClick={submitSellerDetailsHandler}
                                        >
                                            {loading ? "Saving..." : "Save"}
                                        </button> : <button
                                            type="submit"
                                            className="btn btn-primary"
                                            onClick={submitSellerDetailsHandler}
                                        >
                                            {loading ? "Saving..." : "Update"}
                                        </button>}
                                    </div>
                                    {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                </div>
                            </div> : renderComponent == "personaldetails" ? <Personaldetails pageChangeController={pageChangeController} /> : renderComponent == "bankdetails" && <Bankdetails />}
                        </div>
                    </div>
                </div>
                {userData.images && (
                    <AntModal
                        open={isModalVisible}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        width={500}
                    >
                        <img src={userData.images} style={ImgStyles?.image} alt="" />
                    </AntModal>
                )}
            </ThemeMaker>
        </>
    );
};
const ImgStyles = {
    image: {
        width: '100%',
        height: 'auto',
        maxHeight: '70vh',
        objectFit: 'contain'
    }
};
export default Sellerdetails;
