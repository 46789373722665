
import TableCell from '@mui/material/TableCell';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import ImageAdd from '../../components/ImageAdd/ImageAdd';
import styles from "./Variant.module.css"
import { useState } from "react"
import { toast } from 'react-toastify';
import { IndianStates } from '../../utils/rawdata';
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import { Multiselect } from "multiselect-react-dropdown";
import axios from 'axios';
import { config } from '../../utils/config';
const { apiEndpoint } = config;

const smstyle = {
    position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 800, bgcolor: 'background.paper', boxShadow: 24, borderRadius: "10px", p: 1, minHeight: 400, maxHeight: 600, overflow: "scroll", color: "black",
};
const sPstyle = {
    position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 800, bgcolor: 'background.paper', boxShadow: 24, borderRadius: "10px", p: 1, minHeight: "maxcontent", maxHeight: "max-content", overflow: "scroll", color: "black",
};
const anchorStyle = { fontSize: "12px", textDecoration: "underline", cursor: "pointer" }
export default function CombinationTableRows({ productTypeNmae, iopen, handleImageOpen, handleimageClose, removeImageHandler, inputValues, handleChange, productImageHandler, product, tableData, index, combinations, productimageIndex, handlePrice, manDatoryAttr, combination }) {

    function indexd() {

        if (combination.colour && combination.size) {
            let d = tableData?.findIndex((item, index) => {
                // Check if the item has at least one entry with the matching colour
                const hasColour = item?.variant_group.some((entry) => entry.variant_value === combination?.colour);

                // Check if the item has at least one entry with the matching size
                const hasSize = item?.variant_group.some((entry) => entry.variant_value === combination?.size);

                // Return true if both colour and size are found in the same item
                if (hasColour && hasSize) {
                    return true
                }
            })
            return d
        }

        if (combination.colour && !combination.size) {
            let d = tableData?.findIndex((item, index) => {
                const hasColour = item?.variant_group.some((entry) => entry.variant_value === combination?.colour);
                // const hasSize = item?.variant_group.some((entry) => entry.variant_value === combination?.size);
                if (hasColour) {
                    return true
                }
            })
            return d
        }
        if (!combination.colour && combination.size) {
            let d = tableData?.findIndex((item, index) => {
                // const hasColour = item?.variant_group.some((entry) => entry.variant_value === combination?.colour);
                const hasSize = item?.variant_group.some((entry) => entry.variant_value === combination?.size);
                if (hasSize) {
                    return true
                }
            })
            return d
        }
        if (combination?.variant) {
            let d = tableData?.findIndex((item, index) => {
                // const hasColour = item?.variant_group.some((entry) => entry.variant_value === combination?.colour);
                const hasVariant = item?.variant_group.some((entry) => entry.variant_value === combination?.variant);
                if (hasVariant) {
                    return true
                }
            })
            return d
        }
    }

    const [StateBasedPriceingopen, setStateBasedPriceingopen] = useState(false);

    const [attributesState, setattributeState] = useState({
        data: [],
        attr_is_loading: false,
        submitting: false,
        parent_category_id: "",
        product_type_id: "",
        editing_attr_id: "",
        editing_attr_value: "",
        adding_new_attr: false,
        new_attr_value: "",
        new_attr_options: "",
        new_attr_type: "",
        stateName: "",
        price: "",
        status: false,
        start_date: "",
        end_date: "",
        isUpdatingItem: false,
        makeDisable: false,
        mrp: ""

    })
    const [selectedODays, setSelectedODays] = useState();
    const [Oweekdays, setOweekdays] = useState([
        ...IndianStates
    ])

    const handleStateBasedPricingModalClose = () => setStateBasedPriceingopen(false);
    function addMoreHandler() {
        let z = attributesState?.data?.map(item => {
            if (!item.price && !item.start_date && !item.end_date) {
                return { ...item, price: attributesState?.price, start_date: attributesState?.start_date, end_date: attributesState?.end_date, mrp: attributesState?.mrp }
            } else {
                return { ...item }
            }

        })
        setattributeState((prev) => { return { ...prev, stateName: "", price: "", data: [...z], start_date: "", end_date: "", mrp:"" } });
        setSelectedODays();
    }

    async function submitHandler(operation) {
        try {

            let z
            if (operation === "Update") {
                z = attributesState?.data?.map(item => {
                    if (item.state_name === attributesState?.editing_attr_id) {
                        return {
                            ...item, price: attributesState?.editing_attr_value, start_date: attributesState.start_date, end_date: attributesState?.end_date, mrp: attributesState?.mrp
                        }
                    } else {
                        return item
                    }
                })
            } else {
                z = attributesState?.data?.map(item => {
                    if (!item.price) {
                        return { ...item, price: attributesState?.price, start_date: attributesState.start_date, end_date: attributesState?.end_date, mrp: attributesState?.mrp }
                    } else {
                        return { ...item }
                    }

                })
            }

            setattributeState((prev) => { return { ...prev, stateName: "", price: "", data: [...z], editing_attr_id: "", editing_attr_value: "", start_date: "", end_date: "", submitting: true,mrp:"" } });
            setSelectedODays();
            // let apiEndpoint = baseUrlDefiner();
            const { data } = await axios({
                url: `${apiEndpoint}/priceruleproduct`,
                method: "POST",
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`
                },
                data: {
                    "allproducts": [
                        {
                            "id": tableData[index]?.id,
                            "status": attributesState?.status,
                            "has_variation": true,
                            "state": "Delhi",
                            "compare_at_price": attributesState?.mrp,
                            "selling_price": [...z],
                            "validation_date": {
                                "start_date": "2024-09-30",
                                "end_date": "2024-09-31"
                            }
                        }]
                }
            })

            if (data?.status === 200) {
                toast.success("Added Successfully!")
            } else {
                toast.error("Something went wrong, please try later!")
            }
            setattributeState((prev) => { return { ...prev, submitting: false, } });

        } catch (err) {
            setattributeState((prev) => { return { ...prev, submitting: false, } });
            toast.error("Server error, please try later!")
        }
    }

    const onSelectHandler = (selectedList, selectedItem) => {
        console.log("selectedList-------", selectedList);
        console.log("selectedODays-------", selectedODays);
        let z = selectedList.map(item => { return { state_name: item.value } })
        setattributeState((prev) => {
            return {
                ...prev, data: [...prev.data, { state_name: selectedItem.value }],
            }
        })

        setSelectedODays(selectedList.map(item => item));
        // let daySelected = selectedList.map(item => item.id);
        // let z = daySelected.toString()

    }
    const onRemoveHandler = (selectedList, selectedItem) => {
        console.log("selectedList-------", selectedList)
        // let daySelected = selectedList.map(item => item.id);
        // let z = daySelected.toString()
        setSelectedODays(selectedList.map(item => item));
    }

    async function getStateBasedPriceData(id) {
        // let apiEndpoint = baseUrlDefiner();
        let payload = {
            "variantid": id
        };

        try {
            const { data } = await axios({
                url: `${apiEndpoint}/getpriceruleproduct`,
                method: "POST",
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`
                },
                data: payload
            })

            if (data?.status === 200) {
                setattributeState((prev) => {
                    return { ...prev, status: data?.data[0]?.status, mrp: data?.data[0]?.compare_at_price, data: JSON.parse(data?.data[0]?.selling_price) }
                })
            }

            console.log(data)

        } catch (err) {
            toast.error("Server not working, please try later!")
            console.log(err)
        }
    }

    async function updateStatePriceRuleHandler() {
        let indexOfItem = attributesState?.data?.findIndex(item => item?.state_name === attributesState?.stateName);
        let z = [...attributesState?.data];
        z[indexOfItem] = { ...z[indexOfItem], price: attributesState?.price, start_date: attributesState.start_date, end_date: attributesState?.end_date, mrp: attributesState?.mrp };
        setattributeState((prev) => {
            return { ...prev, data: [...z], submitting: true, }
        })

        setSelectedODays();

        const { data } = await axios({
            url: `${apiEndpoint}/priceruleproduct`,
            method: "POST",
            headers: {
                "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`
            },
            data: {
                "allproducts": [
                    {
                        "id": tableData[index]?.id,
                        "status": attributesState?.status,
                        "has_variation": true,
                        "state": "Delhi",
                        "state": "Delhi",
                        "compare_at_price": attributesState?.mrp,
                        "selling_price": [...z],
                        "validation_date": {
                            "start_date": "2024-09-30",
                            "end_date": "2024-09-31"
                        }
                    }]
            }
        })

        if (data?.status === 200) {
            toast.success("Updated Successfully!");
            setattributeState((prev) => { return { ...prev, stateName: "", price: "", data: [...z], editing_attr_id: "", editing_attr_value: "", start_date: "", end_date: "", submitting: false, mrp: "" } });
        } else {
            toast.error("Something went wrong, please try later!")
        }


        // setattributeState((prev) => {
        //   return {...prev, data: prev.data}
        // })
    }



    return (
        <>
            <TableCell align="center">
                {/* {combinations[indexd()]?.size==tableData[indexd()]?.Size} */}
                <input type="text" className={`form-control ${styles.tablecellBox}`} placeholder='' name="mrp" value={tableData[indexd()]?.mrp} onChange={(e) => handleChange(e, indexd(), combinations)} />
            </TableCell>

            <TableCell align="center">
                <input type="text" className={`form-control ${styles.tablecellBox}`} placeholder='' name="selling_price" value={tableData[indexd()]?.selling_price} onChange={(e) => handlePrice(e, indexd(), combinations)} />
            </TableCell>

            <TableCell align='center'>
                <input type="text" className='form-control' placeholder='Tax Included Price' name=""
                    value={tableData[indexd()]?.tax_included_price}
                    // onChange={(e) => handleChange(e, index, item)}
                    readOnly />
            </TableCell>

            <TableCell align="center">
                <input type="text" className={`form-control ${styles.tablecellBox}`} placeholder='' name="quantity" value={tableData[indexd()]?.quantity} onChange={(e) => handleChange(e, indexd(), combinations)} />
            </TableCell>

            <TableCell align="center">
                <input type="text" className={`form-control ${styles.tablecellBox}`} placeholder='' name="sku" value={tableData[indexd()]?.sku} onChange={(e) => handleChange(e, indexd(), combinations)} />
            </TableCell>

            <TableCell align="center">
                <input type="text" className={`form-control ${styles.tablecellBox}`} placeholder='' name="reference_id" value={tableData[indexd()]?.reference_id} onChange={(e) => handleChange(e, indexd(), combinations)} />
            </TableCell>

            <TableCell align="center">
                <input type="text" className={`form-control ${styles.tablecellBox}`} placeholder='' name="hsn" v value={tableData[indexd()]?.hsn} onChange={(e) => handleChange(e, indexd(), combinations)} />
            </TableCell>

            <TableCell align="center">
                <select name="uom" id="" className={`form-control ${styles.tablecellBox}`} value={tableData[indexd()]?.uom} onChange={(e) => handleChange(e, indexd(), combinations)}>
                    <option value="" disabled selected>Select</option>
                    <option value="unit">Unit</option>
                    <option value="dozen">Dozen</option>
                    <option value="gram">Gram</option>
                    <option value="kilogram">Kilogram</option>
                    <option value="tonne">Ton</option>
                    <option value="litre">Litre</option>
                    <option value="millilitre">Millilitre</option>
                </select>
            </TableCell>

            <TableCell align="center">
                <input type="text" className={`form-control ${styles.tablecellBox}`} name="weight" value={tableData[indexd()]?.weight} onChange={(e) => handleChange(e, indexd(), combinations)} />
            </TableCell>
            {
                manDatoryAttr?.map((item) => {
                    return <TableCell align="center">
                        <input type="text" className={`form-control ${styles.tablecellBox}`} name={item.mandatory_attribute} value={tableData[indexd()]?.[item.mandatory_attribute]} onChange={(e) => handleChange(e, indexd(), combinations)} />
                    </TableCell>

                })

            }

            {tableData[indexd()]?.image?.length > 0 ? <TableCell align="center">
                <span className='badge badge-info' onClick={() => { handleImageOpen(tableData[indexd()], index) }} style={{ cursor: "pointer" }} >See Images</span>
            </TableCell> : <TableCell align="center">
                <input type="file" className={`form-control ${styles.tablecellBox}`} style={{ lineHeight: "1", width: "127px" }} accept="image/*" name="image" multiple onChange={(e) => productImageHandler(e, indexd())} />
            </TableCell>
            }

            <TableCell align="center">
                <button className="btn btn-secondary btn-sm" onClick={() => { setStateBasedPriceingopen(true); getStateBasedPriceData(tableData[index].ondc_variant_id) }}>Check</button>
            </TableCell>


            <Modal
                open={iopen}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={smstyle} className="variantBox">
                    <CloseIcon style={{ position: "absolute", top: "5px", right: "10px", cursor: "pointer" }} onClick={handleimageClose} />
                    <p className="" style={{ fontSize: "20px", fontWeight: "700" }}> Images</p>
                    <div>
                        <ImageAdd productImageHandler={productImageHandler} productData={productimageIndex[0]} imageIndex={productimageIndex[1]} removeImageHandler={removeImageHandler} />
                    </div>
                </Box>
            </Modal>

            <Modal
                open={StateBasedPriceingopen}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={{ ...sPstyle, width: "30%", minheight: "max-content", height: "max-content", }} className="variantBox">
                    <CloseIcon style={{ position: "absolute", top: "10px", right: "10px", cursor: "pointer" }} onClick={handleStateBasedPricingModalClose} />
                    <p className="text-center" style={{ fontSize: "20px", fontWeight: "700" }}>State Wise Pricing..</p>

                    <div className="modal-content " >
                        <div className="modal-header p-2 d-flex align-items-center justify-content-between">
                            <p className="modal-title genericlabel  " style={{ letterSpacing: "1px" }}> State Wise Pricing  <input type="checkbox" checked={attributesState?.status} className="" onChange={(e) => setattributeState((prev) => { return { ...prev, status: e.target.checked } })} /> </p>
                            <div>

                                {!attributesState.adding_new_attr ?
                                    <span className='tex-primary' style={anchorStyle}
                                        onClick={() => { setattributeState((prev) => { return { ...prev, adding_new_attr: true } }) }} >Add Price</span>
                                    : <span style={anchorStyle}
                                        onClick={() => { setattributeState((prev) => { return { ...prev, adding_new_attr: false }; }); }} >Price List</span>
                                }

                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    {/* <span style={{ cursor: "pointer" }} ><CloseIcon onClick={handleShipementModalClose} /></span> */}
                                </button>
                            </div>
                        </div>
                        {!attributesState?.adding_new_attr ? <div className="modal-body" >
                            <div style={{ maxHeight: "250px", overflow: "scroll" }}>
                                {(attributesState?.data?.length > 0 && attributesState.attr_is_loading === false) ? <>   {
                                    attributesState?.data?.map(item => {
                                        function getStateName(stateValue) {
                                            let z = IndianStates.filter(item => item.value === stateValue)
                                            return z[0].name
                                        }
                                        return <div key={item?.id}>
                                            <p className='genericlabel d-flex justify-content-between align-items-center'>
                                                {item.state_name === attributesState?.editing_attr_id ? <> {getStateName(item?.state_name)} | {item.state_name} | Rs <input type="text" value={attributesState?.editing_attr_value} className='form-control w-25' onChange={(e) => { setattributeState((prev) => { return { ...prev, editing_attr_value: e.target.value } }) }} /> </> : <div><span>{getStateName(item?.state_name)} | {item.state_name} | Rs {item?.price}</span> <p style={{ color: "grey" }} > Start: {item?.start_date} | End: {item?.end_date}</p> </div>}
                                                {item.state_name === attributesState?.editing_attr_id ? <span className='badge badge-primary' style={{ cursor: "pointer" }} onClick={() => {
                                                    console.log(attributesState);
                                                    submitHandler("Update")
                                                }} >Update</span> : <span className='d-flex' style={{ gap: "10px" }}>
                                                    <EditNoteRoundedIcon style={{ color: "green", cursor: "pointer" }} onClick={() => {
                                                        setattributeState((prev) => { return { ...prev, adding_new_attr: true } });
                                                        let StateFullName = IndianStates.filter(state => state.value === item.state_name)
                                                        setSelectedODays([{ ...item, value: item?.state_name, name: StateFullName[0]?.name }]);
                                                        setattributeState((prev) => { return { ...prev, price: item?.price, start_date: item?.start_date, end_date: item?.end_date, isUpdatingItem: true, stateName: item?.state_name, makeDisable: true, mrp: item?.mrp } })
                                                        // console.log(item)
                                                        // setattributeState((prev) => { return { ...prev, editing_attr_id: item?.state_name, editing_attr_value: item?.price } })
                                                    }}

                                                    />
                                                    {/* <DeleteIcon style={{ color: "red", cursor: "pointer" }} /> */}
                                                </span>}
                                            </p>
                                            <hr />
                                        </div>
                                    })
                                }</> : null}

                                {(attributesState?.data?.length < 1 && attributesState.attr_is_loading === false) ?
                                    <p className='text-primary text-center'>No Pricing</p>
                                    : null}

                                {(attributesState?.data?.length < 1 && attributesState.attr_is_loading === true) ?
                                    <p className='text-primary text-center'>Loading...</p>
                                    : null}
                            </div>


                        </div> : <div className="modal-body">

                            <div >
                                <select name="" id="" className='form-control d-none ' value={attributesState?.stateName} onChange={(e) => { setattributeState((prev) => { return { ...prev, stateName: e.target.value } }) }}>
                                    <option value="" selected disabled>Select State</option>
                                    {IndianStates.map(item => {
                                        return <option value={item.value}>{item.name}</option>
                                    })}
                                </select>

                                <Multiselect options={Oweekdays} selectedValues={selectedODays} displayValue="name" onSelect={onSelectHandler} onRemove={onRemoveHandler} disable={attributesState?.makeDisable}></Multiselect>
                                
                                <p className="m-0 p-0 mt-2 genericlabel">Selling Price</p>
                                <input type="text" placeholder='Enter Selling Price' className='form-control mt-1' value={attributesState?.price} onChange={(e) => { setattributeState((prev) => { return { ...prev, price: e.target.value } }) }} />

                                <p className="m-0 p-0 mt-2 genericlabel">MRP</p>
                                <input type="text" placeholder='Enter MRP' className='form-control mt-1' value={attributesState?.mrp} onChange={(e) => { setattributeState((prev) => { return { ...prev, mrp: e.target.value } }) }} />

                                <p className="m-0 p-0 mt-2 genericlabel">Start Date</p>
                                <input type="date" placeholder='Enter Selling Price' className='form-control mt-1' value={attributesState?.start_date} onChange={(e) => { setattributeState((prev) => { return { ...prev, start_date: e.target.value } }) }} />

                                <p className="m-0 p-0 mt-2 genericlabel">End Date</p>
                                <input type="date" placeholder='Enter Selling Price' className='form-control mt-1' value={attributesState?.end_date} onChange={(e) => { setattributeState((prev) => { return { ...prev, end_date: e.target.value } }) }} />

                                {attributesState?.new_attr_type === "select" && <textarea name="" id="" placeholder='Enter Options' className='form-control mt-3' value={attributesState?.new_attr_options} onChange={(e) => { setattributeState((prev) => { return { ...prev, new_attr_options: e.target.value } }) }}>

                                </textarea>}
                            </div>


                            <div className="modal-header p-2 d-flex align-items-center justify-content-end" style={{ gap: "10px" }}>
                                {attributesState?.isUpdatingItem === false ? <div className="d-flex" style={{ gap: "10px" }}>
                                    <button className='btn btn-success' onClick={addMoreHandler} > Add More</button>
                                    {attributesState?.data?.length > 0 && <button className='btn btn-primary' onClick={() => { submitHandler("Create") }} >  {attributesState?.submitting ? "Please wait" : "Submit"}</button>}
                                </div> : <div>
                                    <button className='btn btn-success' onClick={() => { updateStatePriceRuleHandler() }} >  {attributesState?.submitting ? "Please wait" : "Update"}</button>

                                </div>}
                            </div>

                        </div>}

                    </div>
                </Box>
            </Modal>

        </>

    )
}
