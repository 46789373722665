/* eslint-disable no-self-compare */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { Modal as AntModal } from "antd"
import { tokenDecode } from "../../../utils/helper";
import ThemeMaker from "../../../components/thememaker";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation, useHistory } from "react-router-dom";
import './onIssue.module.css'
import moment from "moment"
import { config } from "../../../utils/config";
import "../../../index.css";
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
const { nodeapiEndpoint } = config;


const OnIssues = () => {
    const decoded: any = tokenDecode();
    const { data } = decoded;
    const { permission: { igm } } = data;
    // state
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [uploading, setUpLoading] = useState(false);
    const [productData, setProductData]: any = useState("");
    const [closeStatus, setCloseStatus]: any = useState(true);
    const [respondent, setRespondent] = useState({
        respondent_action: "",
        respondent_remarks: "",
    });
    const [TicketID, setTicketID]: any = useState("");
    const [DataLoading, setDataLoading]: any = useState(false);
    interface State extends SnackbarOrigin {
        open: boolean;
    }
    const [state, setState] = useState<State>({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, open } = state;
    let history = useHistory();
    const location = useLocation();
    if (location?.state) {
        var { tkt }: any = location?.state;
    } else {
        history.push("/issues");
        // toast.error("Un-Authorized");
    }
    const inputHandler = (e: any) => {
        e.preventDefault();
        setProductData({ ...productData, [e.target.name]: e.target.value });
        // setCloseStatus({ [e.target.name]: e.target.value });
        // console.log("productData", productData);
    };
    const showModal = () => {
        setIsModalVisible(true);
    };
    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };


    const OnIssues_status = async (tkt: any) => {
        // console.log("tkt", tkt)
        setDataLoading(true)
        try {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json" },
                // body: JSON.stringify({ "issue_id": tkt })
            };
            fetch(`${nodeapiEndpoint}/getById/${tkt}`, requestOptions)
                .then((response) => response.json())
                .then((data) => { setTicketID(data?.data[0]); actionTakenData.refund_amount = data?.priceValue; setDataLoading(false) });
        } catch (error) {
            toast.error("Error in getting ticket details")
            setDataLoading(false)
            console.error("error", error);
        }
    };
    const updateIssuesHanlder = async (e: any) => {
        e.preventDefault();
        // if (!productData.status || !productData.remarks) {
        //     return toast.error("Please Select Satus & Write Message");
        // }
        let urlEndpoint = "unsolicited_on_issue_status";
        let payLoad;
        if (productData.status === "NEED-MORE-INFO") {
            // urlEndpoint = "";
            payLoad = {
                issue_id: TicketID?.issue_id,
                description: actionTakenData.remarks,
                respondent_action: productData.status,
            }
        } else {
            // urlEndpoint = "unsolicited_on_issue_status";
            payLoad = {
                issue_id: TicketID?.issue_id,
                description: actionTakenData.remarks,
                respondent_action: productData.status,
                name: data?.user?.name,
                contact: data?.user?.contact_no,
                email: data?.user?.email,
                type: actionTakenData.type,
                support_name: actionTakenData?.gro_name,
                support_phone: actionTakenData?.gro_contact,
                support_email: actionTakenData?.gro_email,
                gro_type: actionTakenData.gro_type,
                short_desc: actionTakenData.short_desc,
                long_desc: actionTakenData.long_desc,
                action_triggered: actionTakenData.action_taken,
                refund_amount: actionTakenData.refund_amount,
                cascaded_level: parseInt(actionTakenData.cascaded_level)
            }
        }
        try {
            setUpLoading(true);
            await axios({
                url: `${nodeapiEndpoint}/${urlEndpoint}`,
                method: "POST",
                data: {
                    // transactionId: TicketID?.context?.transaction_id,
                    ...payLoad
                },
            });
            setUpLoading(false);
            toast.success("Issues Status updated successfully !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } catch (error) {
            setUpLoading(false);
            toast.error("404 error ! please reach to support@ens.enterprises", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }


    const [actionTakenData, setactionTakenData]: any = useState({
        short_desc: "",
        long_desc: "",
        action_taken: "",
        refund_amount: "",
        type: "",
        gro_type: "",
        remarks: "",
        gro_name: "",
        gro_email: "",
        gro_contact: "",
        cascaded_level: ""

    })

    function actionChangeHandler(e: any) {
        setactionTakenData((prev: any) => {
            return { ...prev, [e.target.name]: e.target.value }
        })
    }

    async function actionTakenforLspHandler(e: any) {
        console.log(e.target.value);
        const data = await axios({
            url: `${nodeapiEndpoint}/issue_to_LSP`,
            method: "POST",
            data: {
                "order_id": TicketID?.order_id,
                "issue_id": TicketID?.issue_id,
                "description": "Complaint is being CASCADED successfully.",
                "respondent_action": "CASCADED"
            }
        })
        if (data) {
            console.log("data------", data)
        }
    }

    const [showChat, setshowchat]: any = useState(false)
    const [chatArray, setChatArray]: any = useState([])

    async function showChatHandler() {
        const { data } = await axios({
            method: "GET",
            url: `${nodeapiEndpoint}/chatDetails/${tkt}`,


        })

        console.log(data)
        if (data?.message == "success") {
            setChatArray([...data?.chat])

        }
        setshowchat(true)
    }

    useEffect(() => {
        OnIssues_status(tkt)
    }, []);
    return (
        <>
            <ThemeMaker>
                <form onSubmit={updateIssuesHanlder}>
                    <div className="pl-4 pr-4 mt-4">
                        <div className="row rounded-lg overflow-hidden shadow">
                            {/* <!-- Chat Box--> */}
                            <div className="messages col-md-8 col-sm-12 col-12 px-0" >
                                <div className="card-header d-flex justify-content-between">
                                    <h5 className="m-0 font-weight-bold text-primary">
                                        Ticket Response
                                    </h5>
                                </div>

                                <div>
                                    <div className="row">
                                        <div className="col-md-12 pl-4 pr-4">

                                            <div className="row mt-2">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="genericlabel">Name</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="title"
                                                            placeholder="Name"
                                                            value={TicketID?.complainant_info?.person?.name}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="genericlabel">Email</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="compareprice"
                                                            placeholder="Enter email"
                                                            value={TicketID?.complainant_info?.contact?.email}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="genericlabel">Short description</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="netweight"
                                                            placeholder="Enter description 1"
                                                            value={TicketID?.description?.short_desc}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="form-group">
                                                        <label className="genericlabel">Long description</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="netweight"
                                                            placeholder="Enter description 2"
                                                            value={TicketID?.description?.long_desc}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-4 d-flex align-items-center">
                                                    {/* <label>Product Image</label> */}
                                                    <div className="text-center d-flex">

                                                        {TicketID?.description?.images ? <span className="badge badge-primary" style={{ cursor: "pointer" }}>  <a href={TicketID?.description?.images[0]} target="_blank" style={{ color: "white", cursor: "pointer" }}>Product Image</a> </span> : null}
                                                        {/* <img src={TicketID?.description?.images[0]} alt="..." className="rounded" width={200} height={200} /> */}
                                                        <span className="badge badge-secondary ml-2 " style={{ cursor: "pointer" }} onClick={showChatHandler}>View History</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {showChat && <div>
                                    <div className="px-4 py-5 h-75 chat-box bg-white" style={{ maxHeight: "400px", overflow: "scroll" }}>
                                        <h3 className="text-center mb-5">Complaint  History</h3>
                                        {chatArray.map((message: any, index: any) => (
                                            <div className={`media w-50 ${message.key === 'buyer' ? '' : 'ml-auto'} mb-3`} key={index}>
                                                {message.key === 'buyer' && <img
                                                    src="https://bootstrapious.com/i/snippets/sn-chat/avatar.svg"
                                                    alt="user"
                                                    width="50"
                                                    className="rounded-circle"
                                                />}
                                                <div className="media-body ml-3">
                                                    {message.key === 'buyer' && <div className="bg-primary rounded py-2 px-3 mb-2" >
                                                        <p className={`text-small mb-0 ${message.key === 'buyer' ? 'text-white' : 'text-dark'}`}>
                                                            {message.long_desc}
                                                        </p>
                                                    </div>}

                                                    {message.key === 'buyer' ? <p className="small text-muted m-0 p-0">{message.short_desc}</p> : message?.short_desc && <p className="bg-primary rounded py-2 px-3  small text-light mb-0" >{message?.short_desc}</p>}

                                                    {message.key === 'buyer' ? <p className="small text-dark m-0 p-0">{moment(message.created_at).format("DD-MM-YYYY hh:mm:ss")}</p> : message?.updated_at && <p className="rounded  small text-dark m-0 p-0" >{moment(message.updated_at).format("DD-MM-YYYY hh:mm:ss")}</p>}

                                                    {message?.refund_desc ? <p className="bg-primary rounded py-2 px-3  small text-light mb-0" >{message?.refund_desc}</p> : null}

                                                </div>
                                            </div>
                                        ))}

                                    </div>

                                    {/* <!-- Typing area --> */}
                                    {/* <div className="bg-light">
                                        <div className="input-group ">

                                            <input type="text" placeholder="Type a message" aria-describedby="button-addon2" className="form-control rounded-0 border-0 py-4 bg-light" />
                                            <div className="input-group-append">
                                                <button id="button-addon2" className="btn btn-primary" onClick={pushToChat}>Submit</button>
                                            </div>
                                            <div className="form-outline">

                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                                }
                            </div>

                            {/* <!-- Status box--> */}
                            <div className=" col-md-4 col-sm-12 col-12 px-0 ">
                                <div className="card-header d-flex justify-content-between">
                                    <h5 className="m-0 font-weight-bold text-primary">
                                        Ticket Details
                                    </h5>
                                    <div className={`form-check`} id='StatusLight'>
                                        <input className={`form-check-input ${TicketID?.status}`} checked={TicketID?.status === TicketID?.status ? true : false} type="radio" name="flexRadioDefault" />
                                    </div>
                                </div>
                                {/* <div className="bg-white"> */}
                                {/* <div className="messages-box"> */}
                                <div className="card">
                                    <div className="card-body" style={{ height: "65vh", overflow: "scroll" }}>
                                        <div className="row" >
                                            <div className="col-md-12" >
                                                {/* <div className="form-group"> */}
                                                <label className="genericlabel">Order ID</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="title"
                                                    placeholder="Enter Order Id"
                                                    value={TicketID?.order_details?.id}
                                                    readOnly
                                                // value={rawdata.tiketNo} onChange={(e) => setRawdata({ ...rawdata, tiketNo: e.target.value })}
                                                />

                                            </div>
                                            <div className="col-md-12" >
                                                {/* <div className="form-group"> */}
                                                <label className="genericlabel">Ticket No</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="title"
                                                    placeholder="Enter Ticket No"
                                                    value={TicketID?.issue_id}
                                                    readOnly
                                                // value={rawdata.tiketNo} onChange={(e) => setRawdata({ ...rawdata, tiketNo: e.target.value })}
                                                />
                                                {/* </div> */}
                                            </div>
                                            {/* <div className="col-md-12">
                                                <label className="genericlabel">Issues status</label>
                                                <select name="status"
                                                    className="form-control"
                                                    onChange={(e) => { inputHandler(e); setCloseStatus(e.target.value) }} disabled={igm?.update ? false : true}>
                                                    <option selected disabled>Select Status</option>
                                                    <option selected={TicketID?.status === "CLOSED" ? true : false} value="CLOSED">Closed</option>
                                                    <option selected={TicketID?.status === "RESOLVED" ? true : false} value="RESOLVED">Resolved</option>
                                                    <option selected={TicketID?.status === "PROCESSING" ? true : false} value="PROCESSING">Processing</option>
                                                    <option selected={TicketID?.status === "CASCADED" ? true : false} value="CASCADED">Cascaded</option>
                                                    <option selected={TicketID?.status === "NEED-MORE-INFO" ? true : false} value="NEED-MORE-INFO">Need more Information</option>
                                                    <option selected={TicketID?.status === "ESCALATE" ? true : false} value="Escalated">Escalate</option>
                                                </select>
                                                </div>
                                            </div> */}
                                            <div className="col-md-12">
                                                <label className="genericlabel">Issues status</label>
                                                <select name="status" className="form-control"
                                                    onChange={(e) => { inputHandler(e); setCloseStatus(e.target.value) }}
                                                    disabled={igm?.update ? false : true}>
                                                    <option selected disabled>Select Status</option>
                                                    {TicketID?.status === "RESOLVED" ? (
                                                        <option selected value="RESOLVED">Resolved</option>
                                                    ) : TicketID?.status === "CLOSED" ? (
                                                        <option selected value="CLOSED">Closed</option>
                                                    ) : TicketID?.status === "PROCESSING" ? (
                                                        <>
                                                            <option selected value="PROCESSING">Processing</option>
                                                            <option value="RESOLVED">Resolved</option>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <option selected={TicketID?.status === "CLOSED" ? true : false} value="CLOSED">Closed</option>
                                                            <option selected={TicketID?.status === "RESOLVED" ? true : false} value="RESOLVED">Resolved</option>
                                                            <option selected={TicketID?.status === "PROCESSING" ? true : false} value="PROCESSING">Processing</option>
                                                            <option selected={TicketID?.status === "CASCADED" ? true : false} value="CASCADED">Cascaded</option>
                                                            <option selected={TicketID?.status === "NEED-MORE-INFO" ? true : false} value="NEED-MORE-INFO">Need more Information</option>
                                                            <option selected={TicketID?.status === "ESCALATE" ? true : false} value="ESCALATED">Escalate</option>
                                                        </>
                                                    )}
                                                </select>
                                            </div>

                                            {(productData.status == "RESOLVED" || productData.status == "CASCADED") && <div className="mt-2 py-1 px-3 d-flex flex-column col-md-12" style={{ gap: "10px" }}>
                                                <div>
                                                    <label htmlFor="" className="genericlabel">Short Description</label> <br />
                                                    <input type="text" className="form-control w-100" placeholder="Enter Short Desc" name="short_desc" value={actionTakenData.short_desc} onChange={(e: any) => actionChangeHandler(e)} />
                                                </div>
                                                <div>
                                                    <label htmlFor="" className="genericlabel">Long Description</label> <br />
                                                    <input type="text" className="form-control w-100" placeholder="Enter Long Desc" name="long_desc" value={actionTakenData.long_desc} onChange={(e: any) => actionChangeHandler(e)} />
                                                </div>
                                                <div >
                                                    <label className="genericlabel">Remarks</label>
                                                    <input type="text" className="form-control" name="remarks"
                                                        placeholder="Enter Remarks"
                                                        value={actionTakenData.remarks}
                                                        onChange={(e: any) => actionChangeHandler(e)} />
                                                </div>
                                                <div >

                                                    <label className="genericlabel">Action Taken</label>
                                                    <select name="action_taken"
                                                        className="form-control"
                                                        onChange={(e) => { actionChangeHandler(e) }} >
                                                        <option>Select Action Taken</option>
                                                        <option value="REFUND">Refund</option>
                                                        <option value="REPLACEMENT">Replacement</option>
                                                        <option value="CANCEL">Cancel</option>
                                                        <option value="NO-ACTION">No Action</option>
                                                        <option value="CASCADED">Cascaded</option>
                                                    </select>

                                                    {
                                                        actionTakenData?.action_taken == "REFUND" && <div className="mt-4 d-flex align-items-center">
                                                            <label htmlFor="" className="genericlabel">Refund Amount</label>
                                                            <input type="number" name="refund_amount" value={actionTakenData.refund_amount} className="ml-3 w-25 form-control" onChange={(e: any) => actionChangeHandler(e)} />
                                                        </div>
                                                    }

                                                </div>

                                                <div>
                                                    <label className="genericlabel">Type</label>
                                                    <select name="type"
                                                        className="form-control"
                                                        onChange={(e) => { actionChangeHandler(e) }} >
                                                        <option>Select Type </option>
                                                        <option value="INTERFACING-NP">INTERFACING-NP</option>
                                                        <option value="TRANSACTION-COUNTERPARTY-NP">TRANSACTION-COUNTERPARTY-NP</option>
                                                        <option value="CASCADED-COUNTERPARTY-NP">CASCADED-COUNTERPARTY-NP</option>

                                                    </select>
                                                </div>
                                                <div>
                                                    <label className="genericlabel">Gro Type</label>
                                                    <select name="gro_type"
                                                        className="form-control"
                                                        onChange={(e) => { actionChangeHandler(e) }} >
                                                        <option>Select Gro Type </option>
                                                        {/* <option value="INTERFACING-NP-GRO">INTERFACING-NP-GRO</option> */}
                                                        <option value="TRANSACTION-COUNTERPARTY-NP-GRO">TRANSACTION-COUNTERPARTY-NP-GRO</option>
                                                        <option value="CASCADED-COUNTERPARTY-NP-GRO">CASCADED-COUNTERPARTY-NP-GRO</option>

                                                    </select>
                                                </div>

                                                <div >
                                                    <label className="genericlabel">Gro Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="gro_name"
                                                        placeholder="Enter Name"
                                                        value={actionTakenData.gro_name}
                                                        onChange={(e: any) => actionChangeHandler(e)}
                                                    />
                                                </div>
                                                <div >
                                                    <label className="genericlabel">Gro Email</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="gro_email"
                                                        placeholder="Enter Email"
                                                        value={actionTakenData.gro_email}
                                                        onChange={(e: any) => actionChangeHandler(e)}
                                                    />
                                                </div>
                                                <div >
                                                    <label className="genericlabel">Gro Contact</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="gro_contact"
                                                        placeholder="Enter Contact"
                                                        value={actionTakenData.gro_contact}
                                                        onChange={(e: any) => actionChangeHandler(e)}
                                                    />
                                                </div>
                                                <div>
                                                    <label className="genericlabel">Cascaded Level</label>
                                                    <select name="cascaded_level"
                                                        className="form-control"
                                                        onChange={(e) => { actionChangeHandler(e) }} >
                                                        <option>Select Cascaded Level </option>
                                                        <option value="1">Level 1</option>
                                                        <option value="2">Level 2</option>
                                                        {/* <option value="3">Level 3</option>
                                                        <option value="4">Level 4</option> */}

                                                    </select>

                                                </div>


                                            </div>}

                                            {(productData.status == "NEED-MORE-INFO") && <div className="mt-2 py-1 px-3 d-flex flex-column col-md-12" style={{ gap: "10px" }}>
                                                <div >
                                                    <label>Remarks</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="remarks"
                                                        placeholder="Enter Remarks"
                                                        value={actionTakenData.remarks}
                                                        onChange={(e: any) => actionChangeHandler(e)}
                                                    />
                                                </div>
                                            </div>

                                            }

                                            <div className="col-md-12">
                                                {/* <div className="form-group"> */}
                                                <label className="genericlabel">Category</label>
                                                <input type="text"
                                                    className="form-control"
                                                    name="category"
                                                    placeholder="Enter Category"
                                                    value={TicketID?.category}
                                                    readOnly
                                                />
                                                {/* </div> */}
                                            </div>
                                            <div className="col-md-12">
                                                {/* <div className="form-group"> */}
                                                <label className="genericlabel">Sub Category</label>
                                                <input type="text"
                                                    className="form-control"
                                                    name="title"
                                                    placeholder="Enter Sub Category"
                                                    value={
                                                        TicketID?.sub_category
                                                    }
                                                    readOnly
                                                />
                                            </div>
                                            {/* </div> */}
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-2 d-flex flex-nowrap justify-content-between">
                                        {igm?.update && <button type="submit" className="btn btn-success">Submit </button>}
                                        <button type="button" onClick={showModal} className="btn btn-success ">
                                            View Details
                                        </button>
                                    </div>

                                    {/* <div>
                                        <div>
                                            <p>Issue Description : Issue related to LSP</p>
                                        </div>
                                        <div>
                                            <label htmlFor="">Action Taken for LSP</label>
                                            <select name="" id="" onChange={(e: any) => actionTakenforLspHandler(e)}>
                                                <option value="">Select Action</option>
                                                <option value="1">Informed to LSP</option>
                                                <option value="">No Action yet</option>
                                            </select>
                                        </div>
                                        <div>
                                            <label htmlFor="">Action Taken for Buyer</label>
                                            <select name="" id="">
                                                <option value="">Select Action</option>
                                                <option value="">Escalated to LSP</option>
                                                <option value="">Resolution In progress</option>
                                                <option value="">Closed</option>
                                            </select>
                                        </div>
                                        <div>
                                            <p>Updates from LSP : Issue resolution under progress</p>
                                        </div>
                                        <div>
                                            <label htmlFor="">Status</label>
                                            <select name="" id="">
                                                <option value="">Open</option>
                                                <option value="">CLosed</option>
                                            </select>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* model */}
                    <AntModal
                        className={``}
                        open={isModalVisible}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        width={1000}
                    >
                        <div className="row">
                            <div className="col-md-12 pl-4 pr-4">
                                <div className="card-header">
                                    <h5 className="m-0 font-weight-bold text-primary">Info</h5>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="title"
                                                placeholder="Name"
                                                value={TicketID?.complainant_info?.person?.name}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="compareprice"
                                                placeholder="Enter email"
                                                value={TicketID?.complainant_info?.contact?.email}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Short description</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="netweight"
                                                placeholder="Enter description 1"
                                                value={TicketID?.description?.short_desc}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="form-group">
                                            <label>Long description</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="netweight"
                                                placeholder="Enter description 2"
                                                value={TicketID?.description?.long_desc}
                                            />
                                        </div>
                                    </div>
                                    {TicketID?.description?.images ? <div className="col-md-4">
                                        <label>Product Image</label>
                                        <div className="text-center">
                                            <img src={TicketID?.description?.images[0]} alt="..." className="rounded" width={200} height={200} />
                                        </div>
                                    </div> : null}
                                </div>
                            </div>
                        </div>
                    </AntModal>
                </form>
                <Snackbar open={DataLoading} message="Loading..." key={vertical + horizontal} anchorOrigin={{ vertical, horizontal }} />
            </ThemeMaker >
        </>
    );
};
export default OnIssues;
