import React, { useState, useEffect } from 'react'
import ThemeMaker from '../../components/thememaker'
import { useHistory } from 'react-router-dom';
import { tokenDecode } from "../../utils/helper";
import axios from 'axios';
import { config } from "../../utils/config"
import { toast } from "react-toastify";
import style from "../SellerDetails/Sellerdetails.module.css"

export default function Settings() {

    const apiEndpoint = config.apiEndpoint;
    const nodeApi = config.baseNodeapiUrl
    console.log(nodeApi)
    const decoded = tokenDecode();
    const { data } = decoded;
    let current_sellerid = decoded.data.user.seller_id;
    // console.log(decoded.data.seller_data.provider_id)
    const rawInputData = {
        API_VERSION: "",
        Version: "",
        Access_Token: "",
        Source: "",
        Api_key: "",
        domain: ""

    }
    const rawInputErrorData = {
        shopify_store_url: false,
        shopify_access_token: false,
        shopify_version: false,
        API_VERSION: false,
        Version: false,
        Access_Token: false,
        Source: false,
        Api_key: false,
        domain: false

    }
    const [inputValue, setInputValue] = useState(rawInputData);
    const [inputErrorValue, setInputErrorValue] = useState(rawInputErrorData);
    const [cmsVal, setcmsVal] = useState("")
    const [readOnly, setreadOnly] = useState(false)
    const [showSubmit, setShowSubmit] = useState(true)

    useEffect(() => {
        getStoreCred()
    }, [])

    async function getStoreCred() {
        try {
            const { data } = await axios({
                method: "POST",
                url: `${nodeApi}/env/getconfig`,
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
                },
                data: {
                    "seller_id": current_sellerid.toString()
                }

            })

            if (data.data !==null) {
                // let maskedvalue = data?.data?.shopify_access_token.replace(/.(?=.{2})/g, '*');
                setInputValue({ ...data?.data })
                // console.log(data.data)
                setreadOnly(true)
                setShowSubmit(false)
                setcmsVal("01")
            }
        } catch (err) {
            console.log("Servr error in fetching config details---->", err)
            toast.error("Server Error, please try later!")
        }

    }

    const changeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setInputValue((prev) => {
            return { ...prev, [e.target.name]: newValue }
        })

    }

    // const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    //     // console.log("input state----->", inputValue);
    //     try {
    //         if (inputValue.shopify_access_token !== "" && inputValue.shopify_store_url !== "" && inputValue.shopify_version !== "") {
    //             const { data } = await axios({
    //                 method: "POST",
    //                 url: `${apiEndpoint}/storeCollection`,
    //                 headers: {
    //                     Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
    //                 },
    //                 data: inputValue

    //             })

    //             if (data.status == 200) {
    //                 alert("Data Submitted")
    //             } else {
    //                 alert("Something Went wrong")
    //             }
    //         } else {
    //             alert("Please fill all details")
    //         }
    //     } catch (err) {
    //         console.log(err)
    //     }
    // }

    function selectCMSHandler(e: React.ChangeEvent<HTMLSelectElement>) {
        let val = e.target.value
        console.log(val)
        setcmsVal(val)
    }


    function errorHandler(e: React.FocusEvent<HTMLInputElement>) {
        const newValue = e.target.value;
        if (newValue == "") {
            setInputErrorValue((prev) => {
                return { ...prev, [e.target.name]: true }
            })
        } else {
            setInputErrorValue((prev) => {
                return { ...prev, [e.target.name]: false }
            })
        }

    }

    async function submitWordpressData() {
        // console.log("payload------->", inputValue)
        try {
            if (inputValue.API_VERSION !== "" && inputValue.Version !== "" && inputValue.Access_Token !== "" && inputValue.Source !== "" && inputValue.Api_key !== "" && inputValue.domain !== "") {
                const { data } = await axios({
                    method: "POST",
                    url: `${nodeApi}/env/config`,
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
                    },
                    data: { ...inputValue, seller_id: current_sellerid.toString(), provider_id: decoded.data.seller_data.provider_id }

                })

                if (data.status === 200) {
                    toast.success("Configuration Added")
                } else {
                    toast.error("Something Went Wrong")
                }

                // console.log("data------------>", data)

                // if (data.status == 200) {
                //     alert("Data Submitted")
                // } else {
                //     alert("Something Went wrong")
                // }
            } else {
                toast.error("Please fill all details")
            }
        } catch (err) {
            console.log(err)
            toast.error("Server Not working, please try again later!")
        }
    }

    return (
        <>
            <ThemeMaker>
                <div style={{ minHeight: "90vh" }}>
                    <div className="col-md-12 mt-2">
                        <div className="card shadow mb-4 h-100">
                            <div className="card-header py-3 ">
                                <h6 className="m-0 font-weight-bold text-primary text-left">
                                    Connect Your Online Store
                                </h6>
                            </div>
                            <div className='card-body' style={{ minHeight: "80vh" }}>


                                <div className='p-2'>
                                    <label htmlFor="">Choose Your CMS</label>
                                    <select name="" id="" className="form-control col-md-4" value={cmsVal} onChange={(e) => selectCMSHandler(e)}>
                                        <option value="">Select</option>
                                        <option value="01">Shopify</option>
                                        <option value="02">Wordpress</option>
                                        <option value="03">PrestaShop</option>
                                        <option value="04">Magento</option>
                                        <option value="05">Big Commerce</option>
                                    </select>
                                </div>

                                <div className=' col-md-12 row' style={{ paddingLeft: "10px", color: "black" }}>
                                    {(cmsVal == "01") && <>
                                        <div className='mt-3 col-md-6' >
                                            <label>Enter Api Version  <span className="text-danger" > * </span></label>
                                            <div className='form-group'>
                                                <input type="text" className="form-control" onChange={(e) => changeValue(e)} name='API_VERSION' value={inputValue?.API_VERSION} onBlur={errorHandler} />
                                                {inputErrorValue.API_VERSION && <p style={{ fontSize: "10px", color: "red" }}>*This field is required</p>}
                                            </div>
                                        </div>
                                        <div className='mt-3 col-md-6' >
                                            <label>Version <span className="text-danger" > * </span></label>
                                            <div className='form-group'>
                                                <input type="text" className="form-control" onChange={(e) => changeValue(e)} name='Version' value={inputValue?.Version} onBlur={errorHandler} />
                                                {inputErrorValue.Version && <p style={{ fontSize: "10px", color: "red" }}>*This field is required</p>}
                                            </div>
                                        </div>
                                        <div className='mt-3 col-md-6' >
                                            <label>Access Token <span className="text-danger" > * </span></label>
                                            <div className='form-group'>
                                                <input type="text" className="form-control" onChange={(e) => changeValue(e)} name='Access_Token' value={inputValue?.Access_Token} onBlur={errorHandler} />
                                                {inputErrorValue.Access_Token && <p style={{ fontSize: "10px", color: "red" }}>*This field is required</p>}
                                            </div>
                                        </div>
                                        <div className='mt-3 col-md-6' >
                                            <label>Source <span className="text-danger" > * </span></label>
                                            <div className='form-group'>
                                                <input type="text" className="form-control" onChange={(e) => changeValue(e)} name='Source' value={inputValue?.Source} onBlur={errorHandler} />
                                                {inputErrorValue.Source && <p style={{ fontSize: "10px", color: "red" }}>*This field is required</p>}
                                            </div>
                                        </div>
                                        <div className='mt-3 col-md-6' >
                                            <label>Api Key <span className="text-danger" > * </span></label>
                                            <div className='form-group'>
                                                <input type="text" className="form-control" onChange={(e) => changeValue(e)} name='Api_key' value={inputValue?.Api_key} onBlur={errorHandler} />
                                                {inputErrorValue.Api_key && <p style={{ fontSize: "10px", color: "red" }}>*This field is required</p>}
                                            </div>
                                        </div>
                                        <div className='mt-3 col-md-6' >
                                            <label>Domain <span className="text-danger" > * </span></label>
                                            <div className='form-group'>
                                                <input type="text" className="form-control" onChange={(e) => changeValue(e)} name='domain' value={inputValue?.domain} onBlur={errorHandler} />
                                                {inputErrorValue.domain && <p style={{ fontSize: "10px", color: "red" }}>*This field is required</p>}
                                            </div>
                                        </div>

                                        <button className='btn btn-success ms-5' onClick={submitWordpressData}>Submit</button>


                                    </>}
                                </div>


                                <div className=' col-md-12 row' style={{ paddingLeft: "10px", color: "black" }}>
                                    {
                                        (cmsVal === "02" || cmsVal === "03" || cmsVal === "04" || cmsVal === "05") ? <>
                                            <div className='mt-3 col-md-6' >
                                                <label>Enter Api Version  <span className="text-danger" > * </span></label>
                                                <div className='form-group'>
                                                    <input type="text" className="form-control" onChange={(e) => changeValue(e)} name='API_VERSION' value={inputValue?.API_VERSION} onBlur={errorHandler} />
                                                    {inputErrorValue.API_VERSION && <p style={{ fontSize: "10px", color: "red" }}>*This field is required</p>}
                                                </div>
                                            </div>
                                            <div className='mt-3 col-md-6' >
                                                <label>Version <span className="text-danger" > * </span></label>
                                                <div className='form-group'>
                                                    <input type="text" className="form-control" onChange={(e) => changeValue(e)} name='Version' value={inputValue?.Version} onBlur={errorHandler} />
                                                    {inputErrorValue.Version && <p style={{ fontSize: "10px", color: "red" }}>*This field is required</p>}
                                                </div>
                                            </div>
                                            <div className='mt-3 col-md-6' >
                                                <label>Access Token <span className="text-danger" > * </span></label>
                                                <div className='form-group'>
                                                    <input type="text" className="form-control" onChange={(e) => changeValue(e)} name='Access_Token' value={inputValue?.Access_Token} onBlur={errorHandler} />
                                                    {inputErrorValue.Access_Token && <p style={{ fontSize: "10px", color: "red" }}>*This field is required</p>}
                                                </div>
                                            </div>
                                            <div className='mt-3 col-md-6' >
                                                <label>Source <span className="text-danger" > * </span></label>
                                                <div className='form-group'>
                                                    <input type="text" className="form-control" onChange={(e) => changeValue(e)} name='Source' value={inputValue?.Source} onBlur={errorHandler} />
                                                    {inputErrorValue.Source && <p style={{ fontSize: "10px", color: "red" }}>*This field is required</p>}
                                                </div>
                                            </div>
                                            <div className='mt-3 col-md-6' >
                                                <label>Api Key <span className="text-danger" > * </span></label>
                                                <div className='form-group'>
                                                    <input type="text" className="form-control" onChange={(e) => changeValue(e)} name='Api_key' value={inputValue?.Api_key} onBlur={errorHandler} />
                                                    {inputErrorValue.Api_key && <p style={{ fontSize: "10px", color: "red" }}>*This field is required</p>}
                                                </div>
                                            </div>
                                            <div className='mt-3 col-md-6' >
                                                <label>Domain <span className="text-danger" > * </span></label>
                                                <div className='form-group'>
                                                    <input type="text" className="form-control" onChange={(e) => changeValue(e)} name='domain' value={inputValue?.domain} onBlur={errorHandler} />
                                                    {inputErrorValue.domain && <p style={{ fontSize: "10px", color: "red" }}>*This field is required</p>}
                                                </div>
                                            </div>

                                            <button className='btn btn-success ms-5' onClick={submitWordpressData}>Submit</button>


                                        </> : null
                                    }

                                </div>

                                {/*  */}
                                <div className='mt-5'>
                                    <div className='px-3'>
                                        <h6>CMS We Support</h6>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className='col-md-3'>
                                            <div className={`${style.taxlistTemplateDesc}`}>
                                                <div className='d-flex justify-content-center'>
                                                    <img src="img/shopify_.png" alt="megento" />
                                                    {/* <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" disabled />
                                                    </div> */}
                                                </div>
                                                {/* <div className='mt-2 d-flex justify-content-end' style={{ gap: "10px", cursor: "pointer" }}>
                                                    <button className='badge btn btn-outline-primary p-2' disabled> View </button>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className='col-md-3'>
                                            <div className={`${style.taxlistTemplateDesc}`}>
                                                <div className='d-flex justify-content-center'>
                                                    <img src="img/magento.png" alt="megento" />
                                                    {/* <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" disabled />
                                                    </div> */}
                                                </div>
                                                {/* <div className='mt-2 d-flex justify-content-end' style={{ gap: "10px", cursor: "pointer" }}>
                                                    <button className='badge btn btn-outline-primary p-2' disabled> View </button>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className='col-md-3'>
                                            <div className={`${style.taxlistTemplateDesc}`}>
                                                <div className='d-flex justify-content-center'>
                                                    <img src="img/BigCom.png" alt="" />
                                                    {/* <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" disabled />
                                                    </div> */}
                                                </div>
                                                {/* <div className='mt-2 d-flex justify-content-end' style={{ gap: "10px", cursor: "pointer" }}>
                                                    <button className='badge btn btn-outline-primary p-2' disabled> View </button>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className='col-md-3'>
                                            <div className={`${style.taxlistTemplateDesc}`}>
                                                <div className='d-flex justify-content-center'>
                                                    <img src="img/Prestashop.png" alt="" />
                                                    {/* <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" disabled />
                                                    </div> */}
                                                </div>
                                                {/* <div className='mt-2 d-flex justify-content-end' style={{ gap: "10px", cursor: "pointer" }}>
                                                    <button className='badge btn btn-outline-primary p-2' disabled> View </button>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className='col-md-3'>
                                            <div className={`${style.taxlistTemplateDesc}`}>
                                                <div className='d-flex justify-content-center'>
                                                    <img src="img/wordpres.png" alt="" />
                                                    {/* <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" disabled />
                                                    </div> */}
                                                </div>
                                                {/* <div className='mt-2 d-flex justify-content-end' style={{ gap: "10px", cursor: "pointer" }}>
                                                    <button className='badge btn btn-outline-primary p-2' disabled> View </button>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ThemeMaker>
        </>
    )
}
