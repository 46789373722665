
import ThemeMaker from '../../components/thememaker'
import axios from 'axios';
import { useEffect, useState } from 'react';
import { config } from '../../utils/config';
import { toast } from 'react-toastify';

export default function Logs() {

    const { baseNodeapiUrl, apiEndpoint } = config;
    const [allCities, setallcities] = useState([]);
    const [sellerList, setSellerList] = useState([]);
    const [logState, setlogState] = useState({
        selectedCity: "",
        selectedBpp: "",
        selectedCategroy: "",
        selectedProviderId: "",
        isDataLoading: false
    })
    const [incrementalState, setIncrementalState] = useState({
        selectedDomain: "",
        selectedBPP: "",
        selectedProviderId: "",
        start_time: "",
        end_time: "",
        isSendingRequest: false,
        buyerAps: []
    })


    useEffect(() => {
        getCities();
        getSellerList();

    }, [])

    const getSellerList = async () => {
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/getsellerlist`,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('us_st_d')}`
                }
            });
            if (data.status === 200) {
                // console.log(data.data)
                setSellerList(data.data.filter((item) => item.approve_status === 1));
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    async function getCities() {
        try {
            const { data } = await axios({
                url: "https://xpressbazarprod.hulsecure.in/public/index.php/api/getcity",
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`
                }
            })

            if (data.status == 200) {
                setallcities(data.data)
                console.log(data.data)
            }
        } catch (err) {
            console.log(err)
        }


    }
    async function getLogs() {
        try {
            setlogState((prev) => {
                return { ...prev, isDataLoading: true }
            })
            const { data } = await axios({
                url: `${baseNodeapiUrl}/analytics/search_json`,
                method: "POST",
                data: {
                    "bap_id": logState?.selectedBpp,
                    "city_code": logState?.selectedCity,
                    "provider_id": logState?.selectedProviderId,
                    "category": logState?.selectedCategroy,
                }
            })

            const fileData = JSON.stringify(data, null, 2);
            const blob = new Blob([fileData], { type: 'text/plain' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.download = 'data.txt';
            link.href = url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setlogState((prev) => {
                return { ...prev, isDataLoading: false, selectedBpp: "", selectedCategroy: '', selectedProviderId: '', selectedCity: '', }
            })
        } catch (err) {
            toast.error("Unable to download JSON");
            setlogState((prev) => {
                return { ...prev, isDataLoading: false, selectedBpp: "", selectedCategroy: '', selectedProviderId: '', selectedCity: '', }
            });
            console.log(err);
        }

    }

    function onChangeHandler(e) {
        setlogState((prev) => {
            return { ...prev, [e.target.name]: e.target.value }
        })
    }

    async function incrementalRETSelectHandler(e) {
        setIncrementalState((prev) => {
            return {
                ...prev, selectedDomain: e.target.value
            }
        })
        try {

            const { data } = await axios({
                url: `${baseNodeapiUrl}/admin/api/inc_buyer_by_domain`,
                method: "POST",
                data: {
                    "domain": `ONDC:${e.target.value}`
                }

            })
            if (data?.message === "success") {
                setIncrementalState((prev) => {
                    return {
                        ...prev, buyerAps: [...data?.data]
                    }
                })
            }
            console.log(data)

        } catch (err) {
            toast.error("Something went wrong in loading list of Buyer apps!");
            console.log(err);
        }

    }
    async function incrementalOtherSelectHandler(e) {
        setIncrementalState((prev) => {
            return {
                ...prev, [e.target.name]: e.target.value
            }
        })

    }

    async function sendIncrementalHandler() {
        setIncrementalState((prev) => {
            return {
                ...prev, isSendingRequest: true
            }
        })
        let d = new Date();
        let formattedDate = d.toISOString().slice(0, 10);
        console.log(formattedDate);
        let { start_time, end_time } = incrementalState;
        let modified_start_time = `${formattedDate}T${start_time}:00`
        let modified_end_time = `${formattedDate}T${end_time}:00`;

        const payload = {
            "domain": `ONDC:${incrementalState?.selectedDomain}`,
            "start_time": modified_start_time,
            "end_time": modified_end_time,
            "seller_id": incrementalState?.selectedProviderId
        }
        console.log("payload------", payload)
        try {
            const { data } = await axios({
                url: `${baseNodeapiUrl}/v1/incremental_search`,
                method: "POST",
                data: payload

            })

            if (data?.status === "success") {
                toast.success("Successful!");
                setIncrementalState((prev) => {
                    return {
                        ...prev, isSendingRequest: false
                    }
                })
            } else {
                toast.error("Incorrect Payload!!");
                setIncrementalState((prev) => {
                    return {
                        ...prev, isSendingRequest: false
                    }
                })
            }

            console.log(data)

        } catch (err) {
            toast.error("Something went wrong in sending incremental search!");
            console.log(err);
            setIncrementalState((prev) => {
                return {
                    ...prev, isSendingRequest: false
                }
            })
        }
    }

    function disabledHandler() {
        const { selectedProviderId, start_time, end_time } = incrementalState;
        if (selectedProviderId !== "" && start_time !== "" && end_time !== "") {
            return true
        } else {
            return false
        }
    }

    console.log("increamental state------", incrementalState)
    return (
        <>
            <ThemeMaker>
                <div className="row w-100 m-0 mt-1">
                    <div className="col-md-12 ">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3 d-flex justify-content-between">
                                <h6 className='font-weight-bold text-primary'>Logs</h6>

                            </div>
                            <div className={`card-body p-3 w-100`} style={{ minHeight: "70vh" }}>
                            <div>
                                    <p className='genericlabel'>Generate Logs</p>
                                </div>
                                <div className='d-flex col-md-12' style={{ gap: "20px" }}>
                                    <div>
                                        <select name="selectedBpp" value={logState?.selectedBpp} id="" className='form-control' onChange={onChangeHandler}>
                                            <option value="" selected disabled>Select BPP</option>
                                            <option value="ondc.paytm.com">Paytm</option>
                                            <option value="prd.mystore.in">Mystore</option>
                                            {/* <option value="">Magic Pin</option>
                                            <option value="">Pincode</option>
                                            <option value="">Khojle</option> */}
                                            <option value="ondcstore.snapdeal.com">Snapdeal</option>
                                            <option value="mappls.com">Mappls</option>
                                        </select>
                                    </div>
                                    <div>
                                        <select name="selectedCity" id="" value={logState?.selectedCity} className='form-control' onChange={onChangeHandler}>
                                            <option value="" selected disabled>Select City</option>
                                            {
                                                allCities.map(item => {
                                                    return <option value={item?.city_code}>{item?.city_name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div>
                                        <select name="selectedCategroy" value={logState?.selectedCategroy} id="" className='form-control' onChange={onChangeHandler}>
                                            <option value="" selected disabled>Select Category</option>
                                            <option value="RET10" >Grocery</option>
                                            <option value="RET12" >Fashion</option>
                                            <option value="RET13" >BPC</option>
                                            <option value="RET14" >Electronics</option>
                                            <option value="RET15" >Appliances</option>
                                            <option value="RET16" >Home & Kitchen</option>
                                            <option value="RET18" >Health & Wellness</option>

                                        </select>
                                    </div>
                                    <div>
                                        <select name="selectedProviderId" value={logState?.selectedProviderId} id="" className='form-control' onChange={onChangeHandler}>
                                            <option value="" selected disabled>Select Seller</option>
                                            {
                                                sellerList.map(item => {
                                                    return <option value={item?.provider_id}>{item?.company_name}</option>
                                                })
                                            }

                                        </select>
                                    </div>

                                    {(logState?.selectedBpp !== "" && logState?.selectedCity !== "" && logState?.selectedCategroy !== "") ? <div className=''>
                                        <button className='btn btn-primary' onClick={() => {
                                            getLogs()
                                        }}>  {logState?.isDataLoading ? "Please Wait..." : "Download Logs"}</button>
                                    </div> : null}

                                </div>


                                <hr />

                                <div>
                                    <p className='genericlabel'>Incremental Search</p>
                                </div>

                                <div className='d-flex col-md-12  align-items-end' style={{ gap: "20px" }}>

                                    <div>
                                        <select value={incrementalState?.selectedDomain} id="" className='form-control' onChange={incrementalRETSelectHandler}>
                                            <option value="" selected disabled>Select Domain </option>
                                            <option value="RET10" >Grocery</option>
                                            <option value="RET12" >Fashion</option>
                                            <option value="RET13" >BPC</option>
                                            <option value="RET14" >Electronics</option>
                                            <option value="RET15" >Appliances</option>
                                            <option value="RET16" >Home & Kitchen</option>
                                            <option value="RET18" >Health & Wellness</option>

                                        </select>
                                    </div>

                                    <div className='w-75'>
                                        {incrementalState?.buyerAps?.length > 0 && <div className='d-flex align-items-end ' style={{ gap: "15px" }}>
                                            <select name="selectedBPP" value={incrementalState?.selectedBPP} id="" className='form-control d-none' onChange={incrementalOtherSelectHandler}>
                                                <option value="" selected disabled>Select Buyer App </option>
                                                {
                                                    incrementalState?.buyerAps?.map(item => {
                                                        return <option value={item?.bap_id} key={item?.bap_id} >{item?.bap_id} </option>
                                                    })
                                                }

                                            </select>

                                            <select name="selectedProviderId" value={incrementalState?.selectedProviderId} id="" className='form-control w-25' onChange={incrementalOtherSelectHandler}>
                                                <option value="" selected disabled>Select Seller</option>
                                                {
                                                    sellerList.map(item => {
                                                        return <option value={item?.seller_id}>{item?.company_name}</option>
                                                    })
                                                }

                                            </select>

                                            <div >
                                                <div >
                                                    <label className='text-dark' style={{ color: "black", fontWeight: 600 }}>Start Time   </label>
                                                    <input type="time" className="form-control" name="start_time" placeholder="Enter Start Time"
                                                        onChange={incrementalOtherSelectHandler} />
                                                </div>
                                            </div>

                                            <div >
                                                <div >
                                                    <label className='text-dark' style={{ color: "black", fontWeight: 600 }}>End Time</label>
                                                    <input type="time" name="end_time" className="form-control"
                                                        placeholder="Enter End Time"
                                                        onChange={incrementalOtherSelectHandler}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <button className='btn btn-primary' onClick={sendIncrementalHandler} disabled={!disabledHandler()} > {incrementalState?.isSendingRequest ? "Please Wait..." : "Send Request" }  </button>
                                            </div>
                                        </div>
                                        }
                                    </div>

                                </div>



                            </div>

                        </div>
                    </div>
                </div>

            </ThemeMaker>

        </>
    )
}
