import React from 'react'
import { useState, useEffect } from 'react'
import eye from "../../icons/eye.png"
import styles from "../TaxlistTemplate/TaxlistTemplate.module.css"
import deleteicon from "../../icons/delete.png"
import styled from 'styled-components'
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { toast } from 'react-toastify';
import axios from 'axios';
import { config } from '../../utils/config'

const { apiEndpoint } = config
const Title = styled.h2`    color: black;
font-size: 20px;
text-align: center;
font-weight: 700;`

const buttonStyle = {
    background: '#cccccc',
    padding: '6px 10px',
    color: 'black',
    fontWeight: 700,
    borderRadius: '26px',
    fontSize: '13px',
    letterSpacing: '0.3px',
    cursor: 'pointer'
};
const Sstyle = {
    position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', borderRadius: "10px", bgcolor: 'background.paper', border: '1px solid #000', boxShadow: 24, p: 2,
};

const anchorStyle = { fontSize: "12px", textDecoration: "underline", cursor: "pointer" }
export default function NewCategorylist({ item, index, editRedirect, deleteTax }) {
    // console.log("item --->", item)
    const [listdata, setlistdata] = useState(item)
    const [parentCatName, setparentCatName] = useState("");
    const [downloadCSVModalOpen, setdownloadCSVModalOpen] = useState(false);
    const [attributesState, setattributeState] = useState({
        data: [],
        attr_is_loading: false,
        submitting: false,
        parent_category_id: "",
        product_type_id: "",
        editing_attr_id: "",
        editing_attr_value: "",
        adding_new_attr: false,
        new_attr_value: ""
    })

    useEffect(() => {
        setlistdata(item);
    }, [item]);

    function showSubcategories(data) {
        // console.log("item on click", data);
        setlistdata(data.subcategory);
        setparentCatName(data.cat_name)
    }

    function handleShipementModalClose() {
        setdownloadCSVModalOpen(false);
        setattributeState({
            data: [],
            submitting: false,
            parent_category_id: "",
            product_type_id: ""
        })
    }

    async function submitAttributesHandler() {
        const { new_attr_value } = attributesState;

        if (new_attr_value === "") {
            toast.error("Attribute name is required!");
            return
        }

        try {
            setattributeState((prev) => {
                return { ...prev, submitting: true }
            })

            const response = await axios({
                url: `${apiEndpoint}/createmandattr`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
                },
                data: {
                    "parent_category_id": attributesState?.parent_category_id,
                    "product_type_id": attributesState?.product_type_id,
                    "mandatory_attribute": new_attr_value
                }
            });

            if (response?.data?.status == 200) {
                toast.success("Attributes added !");
                setattributeState((prev) => {
                    return { ...prev, submitting: false, new_attr_value: '', }
                })
            } else {
                toast.error("Something went wrong in payload!")
            }

        } catch (err) {
            toast.error("Server error, please try later!");
            setattributeState((prev) => {
                return { ...prev, submitting: false }
            })
        }

    }
    async function updateAttributesHandler() {

        if (attributesState?.editing_attr_id === "") {
            toast.error("Oops, something is wrong!");
            return
        }
        try {
            const response = await axios({
                url: `${apiEndpoint}/updatemandateattr`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
                },
                data: {
                    "id": attributesState?.editing_attr_id,
                    "mandatory_attribute": attributesState?.editing_attr_value,
                    "product_type_id": attributesState?.product_type_id
                }
            });

            if (response?.data?.status == 200) {
                toast.success("Attributes updated !");
                // setattributeState((prev) => {
                //     return {
                //         ...prev, editing_attr_id: ""
                //     }
                // })
                listMandatoryAttr()
            } else {
                toast.error("Something went wrong in payload!");
                setattributeState((prev) => {
                    return {
                        ...prev, editing_attr_id: ""
                    }
                })
            }

        } catch (err) {
            toast.error("Server error, please try later!");
        }

    }

    async function listMandatoryAttr() {


        try {
            const { data } = await axios({
                url: `${apiEndpoint}/listmandattr`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
                },
                data: {
                    "parent_category_id": attributesState?.parent_category_id,
                    "product_type_id": attributesState?.product_type_id
                }
            });

            if (data?.status === 200) {
                setattributeState((prev) => {
                    return { ...prev, editing_attr_id: "", data: data?.response }
                })
            } else {
                toast.error("Something went wrong!")
            }

        } catch (error) {
            toast.error("Server error please try later!")
        }
    }

    const openModalHandler = async (item) => {
        setdownloadCSVModalOpen(true);
        setattributeState((prev) => {
            return { ...prev, attr_is_loading: true, parent_category_id: item?.parent_id, product_type_id: item?.id }
        })


        try {
            const { data } = await axios({
                url: `${apiEndpoint}/listmandattr`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
                },
                data: {
                    "parent_category_id": item?.parent_id,
                    "product_type_id": item?.id
                }
            });

            if (data?.status === 200) {
                setattributeState((prev) => {
                    return { ...prev, attr_is_loading: false, parent_category_id: item?.parent_id, product_type_id: item?.id, data: data?.response }
                })
            } else {
                toast.error("Something went wrong!");
                setattributeState((prev) => {
                    return { ...prev, attr_is_loading: false, parent_category_id: item?.parent_id, product_type_id: item?.id, data: data?.response }
                })
            }

        } catch (error) {
            toast.error("Server error please try later!");
            setattributeState((prev) => {
                return { ...prev, attr_is_loading: false, parent_category_id: item?.parent_id, product_type_id: item?.id }
            })
        }
    }

    async function deleteAttrHandler(id, name) {


        try {
            const response = await axios({
                url: `${apiEndpoint}/delmandateattr`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
                },
                data: {
                    "id": id,
                    "mandatory_attribute": name
                }
            });

            if (response?.data?.status == 200) {
                toast.success("Attributes Deleted !");
                listMandatoryAttr()
            } else {
                toast.error("Something went wrong in payload!")
            }

        } catch (err) {
            toast.error("Server error, please try later!");
        }
    }

    let Parentid = listdata.every(item => item.parent_id == 0)

    return (
        <>

            {listdata.length > 0 ? (parentCatName != "") && !Parentid && <Title>Sub Categories of {parentCatName}</Title> : (parentCatName !== "" && listdata.length < 1) ? <Title>No Items to Show</Title> : <Title>Loading...</Title>}
            <div className='w-100 d-flex justify-content-evenly ' style={{ flexWrap: "wrap", gap: "20px" }}>

                {
                    listdata.length > 0 && listdata.map(item => {
                        return <div className={`d-flex justify-content-between  ${styles.taxlistTemplatemain} ${styles.categoryWidth}`} key={item.id}>
                            {/* <div className={`${styles.taxlistTemplateImage}`}>
                            <span>0{index + 1}</span>
                        </div> */}
                            <div className={`${styles.taxlistTemplateDesc}`}>
                                <div>
                                    <p className='m-0'>  <span>Category:</span> <span style={{ color: "black", fontWeight: "700" }}>{item.cat_name
                                    }</span></p>
                                </div>
                                <div>
                                    <p className='mb-0'> <span>Status:</span>  {item.status ? "Active" : "Deactive"}</p>
                                </div>

                                <div className='mt-2 d-flex align-items-center' style={{ gap: "10px", cursor: "pointer", letterSpacing: '1px', flexWrap: "wrap" }}>

                                    {(item?.subcategory?.length > 0) && <span className="badge badge-primary p-2 text-center " onClick={() => {
                                        showSubcategories(item)
                                    }}>Sub Categories</span>}

                                    <div className='d-flex align-items-center' style={{ gap: "10px", cursor: "pointer" }}>

                                        {!Parentid && <div onClick={() => deleteTax(item.id, item.parent_id)}>
                                            <DeleteIcon style={{ color: "red" }} />
                                        </div>}

                                        {Parentid ? <span onClick={() => {
                                            editRedirect(item.id, parentCatName, true)

                                        }}> <EditNoteRoundedIcon style={{ color: "#105293", cursor: "pointer" }} titleAccess='Update' /></span> : <span onClick={() => {
                                            editRedirect(item.id, parentCatName, false)

                                        }}> <EditNoteRoundedIcon style={{ color: "#105293", cursor: "pointer" }} titleAccess='Update' /> </span>}

                                        {!Parentid && <span style={buttonStyle} onClick={() => openModalHandler(item)}>Attributes</span>}

                                    </div>
                                </div>

                            </div>


                        </div>
                    })}

            </div>

            <Modal
                open={downloadCSVModalOpen}
                onClose={handleShipementModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={Sstyle} className="col-md-4 ">

                    <div className="modal-content " >
                        <div className="modal-header p-2 d-flex align-items-center justify-content-between">
                            <p className="modal-title genericlabel  " style={{ letterSpacing: "1px" }}> Mandatory Attributes   </p>
                            <div>

                                {!attributesState.adding_new_attr ?
                                    <span className='tex-primary' style={anchorStyle}
                                        onClick={() => { setattributeState((prev) => { return { ...prev, adding_new_attr: true } }) }} >Add Attributes</span>
                                    : <span style={anchorStyle}
                                        onClick={() => { setattributeState((prev) => { return { ...prev, adding_new_attr: false }; }); listMandatoryAttr() }} >Attribute List</span>
                                }

                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span style={{ cursor: "pointer" }} ><CloseIcon onClick={handleShipementModalClose} /></span>
                                </button>
                            </div>
                        </div>
                        {!attributesState?.adding_new_attr ? <div className="modal-body" >
                            <div style={{ maxHeight: "250px", overflow: "scroll" }}>
                                {(attributesState?.data?.length > 0 && attributesState.attr_is_loading === false) ? <>   {
                                    attributesState?.data?.map(item => {
                                        return <div key={item?.id}>
                                            <p className='genericlabel d-flex justify-content-between align-items-center'>
                                                {item.id === attributesState?.editing_attr_id ? <input type="text" value={attributesState?.editing_attr_value} className='form-control w-25' onChange={(e) => { setattributeState((prev) => { return { ...prev, editing_attr_value: e.target.value } }) }} /> : <span>{item?.mandatory_attribute}</span>}
                                                {item.id === attributesState?.editing_attr_id ? <span className='badge badge-primary' style={{ cursor: "pointer" }} onClick={updateAttributesHandler}>Update</span> : <span className='d-flex' style={{ gap: "10px" }}>
                                                    <EditNoteRoundedIcon style={{ color: "green", cursor: "pointer" }} onClick={() => { setattributeState((prev) => { return { ...prev, editing_attr_id: item?.id, editing_attr_value: item?.mandatory_attribute } }) }} />
                                                    <DeleteIcon style={{ color: "red", cursor: "pointer" }} onClick={() => deleteAttrHandler(item?.id, item?.mandatory_attribute)} />
                                                </span>}
                                            </p>
                                            <hr />
                                        </div>
                                    })
                                }</> : null}

                                {(attributesState?.data?.length < 1 && attributesState.attr_is_loading === false) ?
                                    <p className='text-primary text-center'>No attributes</p>
                                    : null}

                                {(attributesState?.data?.length < 1 && attributesState.attr_is_loading === true) ?
                                    <p className='text-primary text-center'>Loading...</p>
                                    : null}
                            </div>


                        </div> : <div className="modal-body">

                            <div style={{ maxHeight: "200px", overflow: "scroll" }}>

                                <input type="text" placeholder='Enter Attribute Name' className='form-control' value={attributesState?.new_attr_value} onChange={(e) => { setattributeState((prev) => { return { ...prev, new_attr_value: e.target.value } }) }} />
                            </div>


                            <div className="modal-header p-2 d-flex align-items-center justify-content-end">
                                <button className='btn btn-success' onClick={submitAttributesHandler}>  {attributesState?.submitting ? "Please wait" : "Submit"}</button>
                            </div>

                        </div>}

                    </div>
                </Box>
            </Modal>
        </>
    )
}
