/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect, useCallback } from "react";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import ThemeMaker from "../../components/thememaker";
import Styles from "./style.module.css";
import { Link } from "react-router-dom";
import { config } from "../../utils/config";
import axios from "axios";
import { Paginated } from "../../components/Paginated";
import "../../index.css";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import moment from "moment"
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const { apiEndpoint } = config;


const index = () => {

    const [toggleState, setToggleState] = useState(1);
    const [details, setDetails]: any = useState([]);
    const [isDetailsLoading, setIsDetailsLoading] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [transactionDetails, setTransactionDetails]: any = useState([]);
    const [manageFilter, setManageFilter]: any = useState([]);
    const [settlementAmount, setSettlementAmount]: any = useState('');
    const [open, setOpen] = useState(false);
    const modelOpen = () => setOpen(true);

    const handleClose = () => {
        setOpen(false);
    };
    const toggleTab = (e: any) => {
        setToggleState(e);
    };

    const Mstyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "40%",
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    // 
    const handleStartDateChange = (event: any) => {
        setStartDate(event.target.value);
        console.log("event.target.value", event.target.value)
    };

    const handleEndDateChange = (event: any) => {
        setEndDate(event.target.value);
        console.log("event.target.value", event.target.value)
    };

    const getTransactionbyId = async (order_id: any) => {
        try {
            setIsDetailsLoading(true)
            const { data } = await axios({
                url: `${apiEndpoint}/transactiondetail`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('us_st_d')}`,
                },
                data: {
                    order_id
                }
            });
            setDetails(data?.data)
            setIsDetailsLoading(false)
        } catch (error) {
            console.log("Something Went Wrong!", error)
            setIsDetailsLoading(false)
        }
    }

    const totalSettlementAmount = async () => {
        try {
            setIsDetailsLoading(true)
            const { data } = await axios({
                url: `${apiEndpoint}/totalSettlementAmount`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('us_st_d')}`,
                },
                data: {
                    start_date: startDate,
                    end_date: endDate
                }
            });
            const hasData = data?.listing && data?.listing?.length > 0;
            console.log("hasData", hasData)
            setTransactionDetails(hasData ? [...(data?.listing || [])] : []);
            setManageFilter(hasData ? [...(data?.listing || [])] : []);
            setSettlementAmount(hasData ? data : []);
            setIsDetailsLoading(false)
        } catch (error) {
            console.log("Something Went Wrong!", error)
            setIsDetailsLoading(false)
        }
    };

    // FILTER

    const filterBySearch = (event: any) => {
        const querySearch = event.target.value;
        let SearchFiltere = transactionDetails.filter((item: any) => {
            return (
                item?.order_id.toLowerCase().includes(querySearch.toLowerCase()) ||
                item?.seller_amount?.toString().toLowerCase().includes(querySearch.toLowerCase()) ||
                item?.seller_name?.toString().toLowerCase().includes(querySearch.toLowerCase()) ||
                item?.ondc_gateway_amount?.toString().toLowerCase().includes(querySearch.toLowerCase()) ||
                item?.settlement_status?.toString().toLowerCase().includes(querySearch.toLowerCase()) ||
                item?.utr?.toString().toLowerCase().includes(querySearch.toLowerCase())
            );
        });
        setManageFilter(SearchFiltere);
    };

    const exportToCSV = (orderList: any) => {
        const headers = [
            'S.No',
            'Company Name	',
            'Order Id',
            'Receive Amount',
            'Order Total',
            'Settlement Status',
            'UTR',
            'Provider Id',
        ];
        // 
        const data = manageFilter.map((item: any, i: any) => {
            return [
                i + 1,
                item?.seller_name,
                item?.order_id,
                item?.seller_amount,
                item?.order_total,
                item?.settlement_status,
                item?.utr,
                item?.provider_id,
            ].join(',');
        });

        const csvContent = [headers.join(','), ...data].join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `orders_${new Date().getTime()}.csv`;
        a.click();
        window.URL.revokeObjectURL(url);
    }

    const COLUMNS = [
        {
            Header: "Sn.",
            accessor: (row: any, count: any) => {
                return <>{count + 1}</>
            },
            disableFilters: true,
            sticky: "left"
        },
        {
            Header: "Company Name",
            accessor: (item: any) => (item?.seller_name ? item?.seller_name : ""),
            sticky: "left"
        },
        {
            Header: "Order Id",
            accessor: (a: any) => (a?.order_id ? a?.order_id : ""),
            sticky: "left"
        },
        {
            Header: "Receive Amount",
            accessor: (a: any) => a?.seller_amount ? a.seller_amount : null,
            sticky: "left"
        },
        {
            Header: "Settlement Status",
            accessor: (item: any) => (item?.settlement_status ? "Settled" : "Not Settled"),
            sticky: "left"
        },
        {
            Header: "UTR",
            accessor: (a: any) => (a?.utr ? a?.utr : ""),
            sticky: "left"
        },
        {
            Header: "Action",
            accessor: (a: any) => {
                return <><button className="btn btn-success btn-circle"
                    onClick={() => { getTransactionbyId(a.order_id); modelOpen() }}
                >
                    <i className="fas fa-pen"></i>
                </button></>
            },
            sticky: "left"
        },
    ];

    useEffect(() => {
        totalSettlementAmount();
    }, [startDate, endDate])

    console.log("settlementAmount?.inprocess_amount", parseFloat(settlementAmount?.inprocess_amount));
    console.log("isDetailsLoading", isDetailsLoading);
    return (
        <>
            <ThemeMaker>
                <div className="row w-100 m-0 mt-2">
                    <div className="col-md-12 ">
                        <div className="shadow mb-4">
                            <div className="card-header">
                                <div className="row d-flex justify-content-between">
                                    <h6 className="m-0 font-weight-bold text-info">
                                        Payments
                                    </h6>
                                    <div>
                                        {/* {isAdmin === "true" ? (
                                                <button className="btn btn-danger" onClick={() => exportToCSV(orderList)}>Download Sheet</button>
                                            ) : null} */}
                                    </div>
                                </div>
                                <div className="row justify-content-between">
                                    <div className="col-6 col-md-6 col-sm-6">
                                        <div className="col-6 col-md-6 p-0">
                                            <label htmlFor="start-date">Search Filter </label>
                                            <input type="search" className="form-control" placeholder="Search" id="" onChange={(event) => filterBySearch(event)} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 d-flex justify-content-end p-0">
                                        <div className="col-6">
                                            <label htmlFor="start-date"> Start Date : </label>
                                            <input type="date" className="form-control" placeholder="Select a date" id=""
                                                value={startDate} onChange={(e: any) => handleStartDateChange(e)} />
                                        </div>
                                        <div className="col-6">
                                            <label htmlFor="end-date"> End Date : </label>
                                            <input type="date" className="form-control" value={endDate} id="" onChange={(e: any) => handleEndDateChange(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-md-6">
                                        <ul className={`${Styles?.bloc_tabs} m-0`}>
                                            <li className={toggleState === 1 ? `${Styles.tabs} ${Styles.active_tabs}` : Styles.tabs} onClick={() => toggleTab(1)}>
                                                Settlements
                                            </li>
                                            {/* <li className={toggleState === 2 ? `${Styles.tabs} ${Styles.active_tabs}` : Styles.tabs} onClick={() => toggleTab(2)}>
                                                Order Wise Payments
                                            </li> */}
                                        </ul>
                                    </div>
                                    <div className="col-md-6 d-flex justify-content-end align-items-end">
                                        <button type="button" className="badge btn btn-outline-info btnHeight" onClick={() => exportToCSV(manageFilter)}>Download Details</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.content_tabs}`}>
                        <div className={toggleState === 1 ? `${Styles.contentShow}  ${Styles.active_content}` : `${Styles.contentShow}`}>
                            <div className="container">
                                <div className="row" style={{ gap: "10px" }}>
                                    <div className="card border-primary mb-3 w-25 bg-success">
                                        <div className="card-body text-light">
                                            <small className="card-text">Total Amout Settlements</small>
                                            <h5 className="card-title">₹ {isDetailsLoading ? <small>Loading</small> : parseFloat(settlementAmount?.settled_amount || 0)?.toFixed(2)} </h5>
                                            <small className="card-text">Amount Credit Your Account</small>
                                        </div>
                                    </div>
                                    <div className="card border-secondary mb-3 w-25 bg-transparent" >
                                        <div className="card-body text-warning">
                                            <small className="card-text text-secondary">Amount in Process</small>
                                            <h5 className="card-title">₹ {isDetailsLoading ? <small>Loading</small> : parseFloat(settlementAmount?.inprocess_amount || 0)?.toFixed(2)}</h5>
                                            <small className="card-text text-secondary">(Amount will be Credited in 2-5 days.)</small>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <Paginated data={manageFilter} columns={COLUMNS} />
                                </div>
                            </div>
                        </div>
                        {/* <div className={toggleState === 2 ? `${Styles.contentShow}  ${Styles.active_content}` : `${Styles.contentShow}`}>
                            <div className="container">
                           <h1>Review</h1>
                        </div> 
                        </div> */}
                    </div>
                </div>
                <Modal open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description">
                    <Box sx={Mstyle}>
                        <div className="modal-header position-relative">
                            <p className="modal-title " style={{ fontSize: "14px", fontWeight: "500", color: "black" }}>Company Name - {details?.company_name} </p>
                            <button type="button" className="close position-absolute" style={{ right: "0", top: "0" }} onClick={() => handleClose()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        {!isDetailsLoading ? <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow >
                                        <TableCell align="center" className="bg-light">Setteled Amount</TableCell>
                                        <TableCell align="center" className="bg-light font-weight-bold" >
                                            {details?.setteled_amount}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell align="center" className="bg-light">Recieved Amount</TableCell>
                                        <TableCell align="center" className="bg-light font-weight-bold" >
                                            {details?.recieved_amt}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell align="center" className="bg-light">Ondc Gateway Amount</TableCell>
                                        <TableCell align="center" className="bg-light font-weight-bold" >
                                            {details?.ondc_gateway_amount}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center" className="bg-light">TDS Deduction</TableCell>
                                        <TableCell align="center" className="bg-light font-weight-bold">{details?.tcs}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center" className="bg-light">TCS Deduction</TableCell>
                                        <TableCell align="center" className="bg-light font-weight-bold">{details?.tds}</TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>

                            {/* 
                            <div className="d-flex justify-content-end p-2 w-100" style={{ gap: "13px" }}>
                                {!settleClicked ? <>{calculation.receive_status !== 1 && <button className="btn btn-secondary" onClick={receiveHandler} >Receive</button>}
                                    {calculation.settlement_status !== "1" ? <button className="btn btn-success" onClick={() => {
                                        setsettleClicked(true)
                                    }} >Settle</button> : null} </> : null}
                                {
                                    settleClicked ? <div className="d-flex justify-content-center" style={{ gap: "5px" }}>
                                        <input type="text" className="form-control" placeholder="Transaction ID" onChange={(e) => { setTransactionid(e.target.value) }} />
                                        <input type="date" name="" id="" className="form-control bg-white" max={getCurrentDate()} onChange={(e) => { setTransactionidDate(e.target.value) }} />
                                        <button className="btn btn-primary" disabled={((Transactionid === "") || (TransactionidDate === "")) ? true : false} onClick={submitCheckedDataSingle}>Submit</button>
                                    </div> : null
                                }
                            </div> */}
                        </TableContainer> : null
                        }
                        {
                            isDetailsLoading ? <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "60vh" }}><CircularProgress /></div> : null
                        }
                    </Box>
                </Modal>
            </ThemeMaker >
        </>
    )
}

export default index;