import { useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import ThemeMaker from "../../components/thememaker";
import { useParams } from "react-router-dom";
import { registerSeller } from "../../store/features/seller/sellerSlice";
import { useSelector, useDispatch } from "react-redux";
import { config } from "../../utils/config";
import { AnyAaaaRecord, AnyARecord } from "dns";
import { toast } from "react-toastify";
import axios from "axios";
import "../../index.css"
const { apiEndpoint, awsKeyId, awsSecretKey, awsRegion, awsBucket } = config

const aws = require('aws-sdk');
interface ICustomer {
    id: any;

}

const AddSeller = () => {
    const dispatch = useDispatch<any>();
    const _sellerData = useSelector((state: any) => state.sellerReducer);
    // console.log("_sellerData", _sellerData);

    let params: ICustomer = useParams();

    const [isgstValid, setisgstValid] = useState(true)
    const [ispanValid, setispanValid] = useState(true)
    const [isPanPresent, setispanPresent] = useState(false)
    const rawData = {
        name: '',
        email: '',
        company_name: '',
        contact_no: '',
        // provider_id: '',
        gst_no: '',
        pan_no: '',
        pan_name: '',
        pan_image: '',
        address_proof: '',
        address_proof_image: '',
        commission: 1,
        // seller_commision: "",
        seller_id: 0,
        role_id: 2,
        status: 1
    }
    const rawErrorData = {
        name: false,
        email: false,
        company_name: false,
        contact_no: false,
        // provider_id: false,
        gst_no: false,
        pan_no: false,
        pan_name: false,
        pan_image: false,
        address_proof: false,
        address_proof_image: false,
        commission: false,
        // seller_commision: false,
    }
    const [sellerData, setSellerData]: any = useState(rawData);
    const [sellerDataError, setSellerDataError]: any = useState(rawErrorData);


    const inputHandler = (e: any) => {
        setSellerData({ ...sellerData, [e.target.name]: e.target.value });
    }
    const errorHandler = (e: any) => {
        console.log(e.target.name)
        const gstregex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
        var panregex = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;

        if (e.target.value !== "") {
            if (e.target.name === "gst_no") {
                if (gstregex.test(e.target.value)) {
                    setisgstValid(true);
                } else {
                    setisgstValid(false);
                }
            }

            if (e.target.name === "pan_no") {
                if (panregex.test(e.target.value)) {
                    setispanValid(true);
                } else {
                    setispanValid(false);
                }
            }
        }


        if (e.target.value) {
            setSellerDataError({ ...sellerDataError, [e.target.name]: "valid" });
        } else {
            setSellerDataError({ ...sellerDataError, [e.target.name]: true });
        }
    }

    // console.log("isGSTValid--->", isgstValid)
    const newUserCreatehandler = async () => {
        // console.log("sellerData => ", sellerData);
        // console.log("sellerDataError => ", sellerDataError);
        const { name,
            email,
            company_name,
            contact_no,
            // provider_id,
            gst_no,
            pan_no,
            pan_name,
            pan_image,
            address_proof,
            address_proof_image,
            commission,
            // seller_commision,
            seller_id,
            role_id,
            status } = sellerData;
        if (name && email && company_name && contact_no && gst_no && pan_no && pan_image && pan_name && address_proof && address_proof_image && commission && seller_id === 0 && role_id && status && isgstValid && ispanValid) {
            dispatch(registerSeller(sellerData));
            // console.log(">>>>>>>>>>>>", sellerData)
        } else {
            toast.error("Please fill all the fields !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    const updateUserHandler = async () => {

    }
    const ImageHandler = async (e: any) => {
        try {
            const file = e.target.files[0];
            let myFile = file.name.split(".");
            let myFileType = myFile[myFile.length - 1];
            const s3 = new aws.S3({
                accessKeyId: awsKeyId,
                secretAccessKey: awsSecretKey,
                region: awsRegion
              });
            const params = {
                Bucket: "ondc-marketplace",
                Key: `images/${uuidv4()}.${myFileType}`,
                Body: file,
                ContentType: file?.type
            }
            let { Location } = await s3.upload(params).promise();
            return Location;
        } catch (exception) {
            console.log(exception);
            return false;
        }
    };

    const setImage = async (e: any) => {
        const image: any = await ImageHandler(e);
        // console.log("Image----", image);
        setSellerData({ ...sellerData, [e.target.name]: image });
    }

    async function panvalidator() {
        // console.log(isgstValid, sellerData.gst_no)
        try {
            if (isgstValid && sellerData.gst_no !== "") {
                let GST = (sellerData.gst_no)
                // console.log(`https://gst-return-status.p.rapidapi.com/free/gstin/${GST}`)
                const { data } = await axios({
                    method: "GET",
                    url: `https://gst-return-status.p.rapidapi.com/free/gstin/${GST}`,
                    headers: {
                        "X-RapidAPI-Key": "94523712ebmsh4d5f7883670d8b8p15ff44jsnec2992be994a",
                        "X-RapidAPI-Host": "gst-return-status.p.rapidapi.com"
                    }
                })

                if (data.success === true) {
                    setisgstValid(true)
                    if (data?.data?.pan) {
                        setispanPresent(true)
                        setSellerData({ ...sellerData, pan_no: data?.data?.pan })
                    }

                } else {
                    setisgstValid(false)
                }
            }
        } catch (err) {
            console.log("GST Validation Error", err)
        }
    }
    useEffect(() => {

    }, []);
    return (
        <>
            <ThemeMaker>

                <div className="row m-0 w-100 mt-1">
                    <div className="col-md-12">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary">
                                    Add a new seller
                                </h6>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="genericlabel">
                                                Name
                                                <span className="text-danger" > * </span>
                                                {
                                                    sellerDataError?.name !== "valid" && sellerDataError?.name !== false ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input type="text" className="form-control" name="name" placeholder="Name"
                                                value={sellerData.name}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="genericlabel">Company name<span className="text-danger" >*</span>
                                                {
                                                    sellerDataError?.company_name !== "valid" && sellerDataError?.company_name !== false ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input type="text" className="form-control" name="company_name" placeholder="Company name"
                                                value={sellerData.company_name}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="genericlabel" >Email address<span className="text-danger" >*</span>
                                                {
                                                    sellerDataError?.email !== "valid" && sellerDataError?.email !== false ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input type="text" name="email" className="form-control" placeholder="Email"
                                                value={sellerData.email}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="col-md-6">
                                        <div className="form-group">
                                            <label >UID<span className="text-danger" >*</span>
                                                {
                                                    sellerDataError?.provider_id !== "valid" && sellerDataError?.provider_id !== false ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input type="text" name="provider_id" className="form-control" placeholder="UID"
                                                value={sellerData.provider_id}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            />
                                        </div>
                                    </div> */}

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="genericlabel" >Contact No.<span className="text-danger" >*</span>
                                                {
                                                    sellerDataError?.contact_no !== "valid" && sellerDataError?.contact_no !== false ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input type="number" name="contact_no" className="form-control" placeholder="Contact number"
                                                value={sellerData.contact_no}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                onKeyDown={(e: any) => {
                                                    if (e.target.value.length >= 12 && e.keyCode !== 8 && e.keyCode !== 46) {
                                                        e.preventDefault();
                                                    }
                                                }}

                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="genericlabel" >GST<span className="text-danger" >*</span>
                                                {
                                                    sellerDataError?.gst_no !== "valid" && sellerDataError?.gst_no !== false ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                                {
                                                    isgstValid ? null : <span className="text-danger tiny_size" > The GST you have entered is invalid ! </span>
                                                }
                                            </label>
                                            <input type="text" className="form-control" name="gst_no" placeholder="GST number"
                                                value={sellerData.gst_no}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => { errorHandler(e); panvalidator() }}
                                                onFocus={() => setisgstValid(true)}
                                                maxLength={15}

                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >GST image

                                            </label>
                                            <input type="file" name="gts_image" className="form-control" disabled
                                            // onChange={(e) => setImage(e)}
                                            // onBlur={(e) => errorHandler(e)}

                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="genericlabel">PAN Number <span className="text-danger" >*</span>
                                                {
                                                    sellerDataError?.pan_no !== "valid" && sellerDataError?.pan_no !== false ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }

                                                {
                                                    ispanValid ? null : <span className="text-danger tiny_size" > The PAN you have entered is invalid ! </span>
                                                }
                                            </label>
                                            <input type="text" className="form-control" name="pan_no" placeholder="PAN number"
                                                value={sellerData.pan_no}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => { errorHandler(e); }}
                                                maxLength={10}
                                                readOnly={isPanPresent ? true : false}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="genericlabel">Name as per PAN <span className="text-danger" >*</span>
                                                {
                                                    sellerDataError?.pan_name !== "valid" && sellerDataError?.pan_name !== false ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input type="text" className="form-control" name="pan_name" placeholder="Name as per pan"
                                                value={sellerData.pan_name}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="genericlabel">PAN image <span className="text-danger" >*</span>
                                                {
                                                    sellerDataError?.pan_image !== "valid" && sellerDataError?.pan_image !== false ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input type="file" name="pan_image" className="form-control"
                                                onChange={(e) => setImage(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <p className="genericlabel"> Address Proof <span className="text-danger" >*</span>
                                            {
                                                sellerDataError?.address_proof !== "valid" && sellerDataError?.address_proof !== false ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                        </p>
                                        <div className="form-group">
                                            <select name="address_proof" id="" className="form-control"
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            >
                                                <option value="">Select address proof</option>
                                                <option value="Aadhar card">Aadhar card</option>
                                                <option value="Ration card">Ration card</option>
                                                <option value="Telephone bill">Telephone bill</option>
                                                <option value="Bank Passbook">Bank Passbook</option>
                                                <option value="Electricity bill">Electricity bill</option>
                                                <option value="Lease or license agreement">Lease or license agreement</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <p className="genericlabel"> Address Proof image<span className="text-danger" >*</span>
                                            {
                                                sellerDataError?.address_proof_image !== "valid" && sellerDataError?.address_proof_image !== false ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                        </p>
                                        <div className="form-group">
                                            <input type="file" name="address_proof_image" className="form-control"
                                                onChange={(e) => setImage(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            />
                                        </div>
                                    </div>


                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="genericlabel">Commission ( % )<span className="text-danger" > *</span>
                                                {
                                                    sellerDataError?.commission !== "valid" && sellerDataError?.commission !== false ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input type="number" name="commission" className="form-control" placeholder="Commission"
                                                value={sellerData.commission}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="col-md-6">
                                        <div className="form-group">
                                            <label >Seller Commission ( % )<span className="text-danger" > *</span>
                                                {
                                                    sellerDataError?.seller_commision !== "valid" && sellerDataError?.seller_commision !== false ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input type="number" name="seller_commision" className="form-control" placeholder="Seller Commission" max={5}
                                                value={sellerData.seller_commision}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            />
                                        </div>
                                    </div> */}

                                    <div className="col-md-12">

                                        <button type="submit" className="btn btn-primary"

                                            onClick={newUserCreatehandler}>
                                            {
                                                _sellerData?.loading ? 'Saving...' : "Create"
                                            }
                                        </button>
                                    </div>
                                    {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </ThemeMaker>
        </>
    )
}

export default AddSeller;