

export const config = {
    apiEndpoint: "https://xpressbazarprod.hulsecure.in/public/index.php/api",
    // apiEndpoint: "https://preprodshikharapp.hulsecure.in/public/index.php/api",
    nodeapiEndpoint: "https://ondc.xpressbaazaar.com/igm",
    // nodeapiEndpoint: "https://preprod.xpressbaazaar.com/igm",
    baseNodeapiUrl: "https://ondc.xpressbaazaar.com",
    // baseNodeapiUrl: "https://preprod.xpressbaazaar.com",
    // awsKeyId: "AKIAYJUL5VJOBYQMJDT7",
    // awsSecretKey: "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"
    awsKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    awsSecretKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    awsRegion: process.env.REACT_APP_AWS_REGION,
    awsBucket: process.env.REACT_APP_AWS_BUCKET,
}