import { useState, useEffect, useRef } from "react";
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import axios from "axios";
import ThemeMaker from "../../components/thememaker";
import { tokenDecode } from "../../utils/helper";
import { toast } from "react-toastify";
import { config } from "../../utils/config";
import btnstyle from "../../components/Buttons/Buttons.module.css"
import styles from "../transaction/transaction.module.css"
import Brandlist from "../../components/Brandlist Template/Brandlist";
import { v4 as uuidv4 } from 'uuid';
import { show, hide } from "react-functional-modal"
import styled from "styled-components"
import { Paginated } from "../../components/Paginated";
import "../../index.css";
var $ = require('jquery');
const { apiEndpoint, awsKeyId, awsSecretKey, awsRegion, awsBucket } = config;


const s3Config = {
    bucketName: 'bucket-name',
    dirName: 'directory-name',      /* Optional */
    region: 'ap-south-1',
    accessKeyId: 'ABCD12EFGH3IJ4KLMNO5',
    secretAccessKey: 'a12bCde3f4+5GhIjKLm6nOpqr7stuVwxy8ZA9bC0',
    s3Url: 'https:/your-aws-s3-bucket-url/'     /* Optional */
}
const aws = require('aws-sdk');

const ImageContainer = styled.div
    `
 border: 1px solid black;
    border-radius: 27px;
  width: 83%;
  background: white;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

    
    img{
      width: 100%!important;
    height: 80%;
    object-fit: contain;
  }

  button{
    position: absolute;
    top: 4px;
    right: 11px;
    background: none;
    outline: none;
    border: none;
  }
    `

const Brand = () => {
    const decoded = tokenDecode();
    const { data } = decoded;
    const { permission: { category } } = data;
    const [listing, setListing] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [listingLoading, setListingLoading] = useState(false);
    const [taxData, setTaxData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [brand, setBrand] = useState({
        brand_name: '',
        status: true,
        images: ''
    });
    const taxD = {
        brand_name: false
    }
    const [BrandErrorData, setBrandErrorData] = useState(taxD);
    const errorHandler = (e) => {
        if (e.target.value) {
            setBrandErrorData({ ...BrandErrorData, [e.target.name]: false });
        } else {
            setBrandErrorData({ ...BrandErrorData, [e.target.name]: true });
        }
    }
    const createBrandHandler = async () => {
        try {
            setLoading(true);
            // console.log("TAX", tax);
            if (brand?.brand_name) {
                // console.log("brand data to send-->", brand);
                const { data } = await axios({
                    url: `${apiEndpoint}/addbrand`,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('us_st_d')}`
                    },
                    data: {
                        "brand_name": brand.brand_name,
                        "status": brand.status,
                        "images": brand.images
                    }
                });
                // console.log("data---", data);
                if (data.status === 200) {
                    toast.success("New Brand created !", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    toast.error("403 Error !", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            } else {
                toast.error("Please fill the required field !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error("403 Error !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const brandListHandler = async () => {
        try {
            setListing(false);
            setListingLoading(true);
            const { data } = await axios({
                url: `${apiEndpoint}/getBrandList`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('us_st_d')}`
                }
            });
            // console.log("brand listing data --->", data);
            setListingLoading(false);
            if (data.status === 200) {
                setTaxData(data.data);
            }

        } catch (error) {
            setListingLoading(false);


        }
    }
    const setCrete = () => {
        setListing(true);
        setIsUpdate(false);
        setBrand({
            brand_name: '',
            status: true,
            images: ""
        })
    }
    const editRedirect = async (id) => {
        setListing(true)
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/getBrands`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('us_st_d')}`
                },
                data: {
                    "id": id
                }
            });
            // console.log("editRedirect data", data);
            if (data.status === 200) {
                setBrand({
                    brand_name: data?.data?.brand_name,
                    status: data?.data?.status,
                    images: data?.data?.images,
                    id: data?.data?.id
                })
            }
        } catch (error) {

        }
        setIsUpdate(true);
        // setListing(false);

    }

    const updateTax = async () => {
        try {
            if (brand?.brand_name) {
                setLoading(true);
                // console.log("tax", tax);
                const { data } = await axios({
                    url: `${apiEndpoint}/UpdateBrand`,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('us_st_d')}`
                    },
                    data: {
                        "brand_name": brand.brand_name,
                        "status": brand.status,
                        "images": brand.images,
                        id: brand.id
                    }
                });
                // console.log("data----", data);
                if (data.status === 200) {
                    toast.success("Brand Updated !", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    toast.error("403 Error !", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            } else {
                toast.error("Please fill the required field !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error("403 Error !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const deleteTax = async (id) => {
        try {
            setLoading(true);
            // console.log("category", tax);
            const { data } = await axios({
                url: `${apiEndpoint}/deletebrand`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('us_st_d')}`
                },
                data: { id }
            });
            // console.log("data----", data);
            if (data.status === 200) {
                setTaxData(taxData.filter((item) => item.id !== id));
                toast.success("Brand Deleted !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error("403 Error !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            toast.error("403 Error !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }


    const productImageHandler = async (e) => {
        try {
            const file = e.target.files[0];
            let myFile = file.name.split(".");
            let myFileType = myFile[myFile.length - 1];
            const s3 = new aws.S3({
                accessKeyId: awsKeyId,
                secretAccessKey: awsSecretKey,
                region: awsRegion
            });
            const params = {
                Bucket: "ondc-marketplace",
                Key: `images/${uuidv4()}.${myFileType}`,
                Body: file,
                ContentType: file?.type
            }
            let result = await s3.upload(params).promise();
            const { Location } = result;
            // console.log(Location);

            setBrand({ ...brand, [e.target.name]: Location })

        } catch (exception) {
            console.log(exception);
        }
    };

    const open = () => {
        show(
            <ImageContainer>
                <img src={brand.images} alt="" />
                <button onClick={() => {
                    hide("key#1")
                }}>x</button>
            </ImageContainer>,
            { key: "key#1" }
        )
    }


    const COLUMNS = [
        {
            Header: "Serial No.",
            Footer: "Serial No.",
            accessor: (row, count) => {
                return <>{count + 1}</>;
            },
            disableFilters: true,
            sticky: "left",
        },
        {
            Header: "Brand Name",
            Footer: "Brand Name",
            accessor: "brand_name",
            sticky: "left",
        },
        {
            Header: "Status",
            Footer: "Status",
            accessor: (row) => row.status === true ? "Active" : "Inactive",
            sticky: "left",
        },

        {
            Header: "Action",
            Footer: "Action",
            accessor: (row) => {
                return (
                    <>
                        <button className="btn-sm m-1 btn btn-success btn-circle" onClick={() => editRedirect(row.id)}  >
                            <i className="fas fa-pen"></i>
                        </button>


                        <button
                            className="btn-sm m-1 btn btn-danger btn-circle"
                            onClick={() => deleteTax(row.id)}
                        >
                            <i className="fas fa-trash"></i>
                        </button>





                    </>
                );
            },
            sticky: "left",
        },
    ];
    useEffect(() => {
        brandListHandler()
    }, [])

    // console.log(listing)
    return (
        <>
            <ThemeMaker>
                <div className="row w-100 m-0 m-1">
                    <div className="col-md-12 ">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary d-flex justify-content-between align-items-center" style={{ gap: "10px", flexDirection: listing ? "row-reverse" : "row" }}>

                                    {listing ? <button onClick={brandListHandler} style={{ fontSize: "15px" }}
                                        className="btn btn-primary"
                                    >
                                        Brand List
                                    </button> : <span>Brand List</span>}

                                    {!listing ? <button
                                        style={{ fontSize: "14px" }}
                                        onClick={setCrete}
                                        className="btn btn-primary"
                                    >
                                        Create New Brand
                                    </button> : <span>Create New Brand</span>}



                                </h6>
                            </div>
                            {
                                !listing ? (
                                    <>  <div className={`card-body ${styles.transactionListing_desktop}`} >
                                        <div className="table-responsive">

                                            <div className="">
                                                <Paginated data={taxData} columns={COLUMNS} />
                                            </div>

                                        </div>
                                    </div>
                                        <div className={`${styles.transactionListing_mobile}`} style={{ padding: "10px", background: 'white' }}>  {
                                            taxData.map((item, index) => {
                                                return <Brandlist item={item} index={index} editRedirect={editRedirect} deleteTax={deleteTax} />
                                            })
                                        }</div>
                                    </>
                                ) : (
                                    <div className="card-body" style={{ minHeight: "70vh" }}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="genericlabel" >Brand name</label>
                                                    <span className="text-danger" > * </span>
                                                    {
                                                        BrandErrorData?.brand_name ? (
                                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                                        ) : null
                                                    }
                                                    <input type="text" className="form-control" name="brand_name" placeholder="Enter Brand Name"
                                                        value={brand.brand_name}
                                                        onChange={(e) => setBrand({ ...brand, [e.target.name]: e.target.value })}
                                                        onBlur={(e) => errorHandler(e)}

                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="genericlabel">Image
                                                        {/* <span className="text-danger" > * </span>
                                                        {
                                                            BrandErrorData?.images ? (
                                                                <span className="text-danger tiny_size" > This field is required ! </span>
                                                            ) : null
                                                        } */}
                                                    </label>
                                                    {brand.images && <a
                                                        // href={product.image}
                                                        onClick={() => open()}
                                                        target="_blank"
                                                        style={{
                                                            fontSize: "10px",
                                                            marginLeft: "3px",
                                                            textDecoration: "underline",
                                                        }}
                                                    >
                                                        View Document
                                                    </a>}
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        onChange={(e) => productImageHandler(e)}
                                                        accept="image/*"
                                                        name="images"
                                                        multiple
                                                        onBlur={(e) => errorHandler(e)}
                                                        id="image"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="genericlabel" >Status</label>
                                                    <input type="checkbox" checked={brand.status} name="status" placeholder="Name"
                                                        value=""
                                                        onChange={(e) => setBrand({ ...brand, [e.target.name]: !brand.status })}
                                                        style={{ marginLeft: "10px" }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                {
                                                    !isUpdate && listing ? (
                                                        <button type="submit" className="btn btn-primary"
                                                            onClick={createBrandHandler}
                                                        >
                                                            Save
                                                        </button>
                                                    ) : (
                                                        <button type="submit" className="btn btn-primary"
                                                            onClick={updateTax}
                                                        >
                                                            Update
                                                        </button>
                                                    )
                                                }

                                            </div>
                                            {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </ThemeMaker>
        </>
    )
}

export default Brand;