import { useState, useEffect } from "react";
import ThemeMaker from "../../components/thememaker";
import { tokenDecode } from "../../utils/helper";
import { toast } from 'react-toastify';
import axios from "axios";
import { config } from "../../utils/config";
import "../../index.css";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
const { apiEndpoint } = config;
interface ICustomer {
    id: any;
}
const Roles = () => {
    const [isUpdate, setIsUpdate] = useState(false);
    const [isListig, setIsListing] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [listingLoading, setListingLoading] = useState(false);
    const [listingData, setListingData] = useState([]);

    const decode = tokenDecode();
    const { data } = decode;
    const { permission: { role } } = data;
    const { seller_id, parent_id, role_id } = data.user;


    let roleData = {
        id: '',
        seller_id: decode?.data?.user?.seller_id,
        role: '',
        status: false,
        permission: {
            seller: {
                read: false,
                write: false,
                update: false,
                delete: false
            },
            user: {
                read: false,
                write: false,
                update: false,
                delete: false
            },
            product: {
                read: false,
                write: false,
                update: false,
                delete: false
            },
            order: {
                read: false,
                write: false,
                update: false,
                delete: false
            },
            category: {
                read: false,
                write: false,
                update: false,
                delete: false
            },
            tax: {
                read: false,
                write: false,
                update: false,
                delete: false
            },
            role: {
                read: false,
                write: false,
                update: false,
                delete: false
            },
            location: {
                read: false,
                write: false,
                update: false,
                delete: false
            },
            igm: {
                read: false,
                write: false,
                update: false,
                delete: false
            },
            transaction: {
                read: false,
                write: false,
                update: false,
                delete: false
            }
        }
    }
    const [roles, setRoles] = useState(roleData);
    const rolesError = {
        role: false
    }
    const [rolesErrorData, setRolesErrorData] = useState(rolesError);
    const errorHandler = (e: any) => {
        if (e.target.value) {
            setRolesErrorData({ ...rolesErrorData, [e.target.name]: false });
        } else {
            setRolesErrorData({ ...rolesErrorData, [e.target.name]: true });
        }
    }
    const creareRoleHandler = async () => {
        try {
            if (roles.role) {
                setIsLoading(true);
                const { data } = await axios({
                    url: `${apiEndpoint}/createrole`,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('us_st_d')}`
                    },
                    data: roles
                });
                setIsLoading(false);
                // console.log("role data", data);
                if (data.status === 200) {
                    toast.success("Role created successfully !", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    toast.error("403 error !", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            } else {
                toast.error("Please fill the required field !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setRoles(roleData);
        } catch (error) {
            setIsLoading(false);
            toast.error("403 error !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    const ListHanler = async () => {
        try {
            setIsListing(false);
            setListingLoading(true);
            const { data } = await axios({
                url: `${apiEndpoint}/getrolelist`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('us_st_d')}`
                },
                data: {
                    seller_id: decode?.data?.user?.seller_id,
                }
            });
            // console.log("role listing", data);
            setListingLoading(false);
            if (data.status === 200) {
                setListingData(data.data);
            }

        } catch (error) {
            setListingLoading(false);


        }
    }
    const setCrete = () => {
        setIsListing(true)
        setIsUpdate(false);
        setRoles(roleData);
        // console.log(isListig);

    }
    const editRedirect = async (id: any) => {
        try {
            setIsListing(true);
            setIsUpdate(true);
            // console.log("u");
            const { data } = await axios({
                url: `${apiEndpoint}/getrole`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('us_st_d')}`
                },
                data: {
                    "id": id
                }
            });
            // console.log("editRedirect data", data.data);
            if (data.status === 200) {
                setRoles(data.data);
            }
        } catch (error) {

        }

    }

    const updateRole = async () => {
        try {
            setIsLoading(true);
            // console.log("roles", roles);
            const { data } = await axios({
                url: `${apiEndpoint}/updaterole`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('us_st_d')}`
                },
                data: roles
            });
            // console.log("dupdate data----", data);
            if (data.status === 200) {
                toast.success("Role updated successfully!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error("403 Error !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            toast.error("403 Error !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const deleteRole = async (id: any) => {
        try {
            setIsLoading(true);
            // console.log("roels id delete", roles);
            const { data } = await axios({
                url: `${apiEndpoint}/deleterole`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('us_st_d')}`
                },
                data: { id }
            });
            // console.log("data----", data);
            if (data.status === 200) {
                setListingData(listingData.filter((item: any) => item.id !== id));
                toast.success("Role deleted !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error("403 Error !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            toast.error("403 Error !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    return (
        <>
            <ThemeMaker>

                <div className="row mr-0 w-100" style={{ overflow: "hidden" }}>
                    <div className="col-md-12 pl-4 mt-3">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <button className="btn btn-primary"
                                    onClick={() => setCrete()}
                                >
                                    Create new role
                                </button>
                                <button className="btn btn-primary ml-2"
                                    onClick={ListHanler}
                                >
                                    Roles list
                                </button>
                            </div>
                            {
                                isListig ? (
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="genericlabel">Name</label>
                                                    <span className="text-danger" > * </span>
                                                    {
                                                        rolesErrorData?.role ? (
                                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                                        ) : null
                                                    }
                                                    <input type="text" className="form-control" name="role" placeholder="Enter Name"
                                                        value={roles.role}
                                                        onChange={(e) => setRoles({ ...roles, [e.target.name]: e.target.value })}
                                                        onBlur={(e) => errorHandler(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="genericlabel">Status</label>


                                                    <input type="checkbox" className="" style={{ marginLeft: "10px" }}
                                                        checked={roles?.status}
                                                        onChange={(e) => setRoles({ ...roles, status: !roles?.status })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <label className="genericlabel" >Permissions</label>
                                                <div className="row m-3 d-none">
                                                    {
                                                        seller_id === 0 && parent_id === 1 || role_id === 1 ? (
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label className="font-weight-bold genericlabel" >Seller</label>
                                                                    <ul className="ml-4" >
                                                                        <li>
                                                                            <input type="checkbox" className=""
                                                                                checked={roles?.permission?.seller?.read}
                                                                                onChange={(e) => setRoles({
                                                                                    ...roles, permission: {
                                                                                        ...roles.permission,
                                                                                        seller: {
                                                                                            ...roles.permission.seller,
                                                                                            read: !roles.permission.seller.read
                                                                                        }
                                                                                    }
                                                                                })}
                                                                            />
                                                                            <label >Read</label>
                                                                        </li>
                                                                        <li>
                                                                            <input type="checkbox" className=""
                                                                                checked={roles?.permission?.seller?.write}
                                                                                onChange={(e) => setRoles({
                                                                                    ...roles, permission: {
                                                                                        ...roles.permission,
                                                                                        seller: {
                                                                                            ...roles.permission.seller,
                                                                                            write: !roles.permission.seller.write
                                                                                        }
                                                                                    }
                                                                                })}
                                                                            />
                                                                            <label >Write</label>
                                                                        </li>
                                                                        <li>
                                                                            <input type="checkbox" className=""
                                                                                checked={roles?.permission?.seller?.update}
                                                                                onChange={(e) => setRoles({
                                                                                    ...roles, permission: {
                                                                                        ...roles.permission,
                                                                                        seller: {
                                                                                            ...roles.permission.seller,
                                                                                            update: !roles.permission.seller.update
                                                                                        }
                                                                                    }
                                                                                })}
                                                                            />
                                                                            <label >Update</label>
                                                                        </li>
                                                                        <li>
                                                                            <input type="checkbox" className=""
                                                                                checked={roles?.permission?.seller?.delete}
                                                                                onChange={(e) => setRoles({
                                                                                    ...roles, permission: {
                                                                                        ...roles.permission,
                                                                                        seller: {
                                                                                            ...roles.permission.seller,
                                                                                            delete: !roles.permission.seller.delete
                                                                                        }
                                                                                    }
                                                                                })}
                                                                            />
                                                                            <label >Delete</label>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        ) : null
                                                    }

                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label className="font-weight-bold genericlabel" >User</label>
                                                            <ul className="ml-4" >
                                                                <li>
                                                                    <input type="checkbox" className=""
                                                                        checked={roles?.permission?.user?.read}
                                                                        onChange={(e) => setRoles({
                                                                            ...roles, permission: {
                                                                                ...roles.permission,
                                                                                user: {
                                                                                    ...roles.permission.user,
                                                                                    read: !roles.permission.user.read
                                                                                }
                                                                            }
                                                                        })}
                                                                    />
                                                                    <label >Read</label>
                                                                </li>
                                                                <li>
                                                                    <input type="checkbox" className=""
                                                                        checked={roles?.permission?.user?.write}
                                                                        onChange={(e) => setRoles({
                                                                            ...roles, permission: {
                                                                                ...roles.permission,
                                                                                user: {
                                                                                    ...roles.permission.user,
                                                                                    write: !roles.permission.user.write
                                                                                }
                                                                            }
                                                                        })}
                                                                    />
                                                                    <label >Write</label>
                                                                </li>
                                                                <li>
                                                                    <input type="checkbox" className=""
                                                                        checked={roles?.permission?.user?.update}
                                                                        onChange={(e) => setRoles({
                                                                            ...roles, permission: {
                                                                                ...roles.permission,
                                                                                user: {
                                                                                    ...roles.permission.user,
                                                                                    update: !roles.permission.user.update
                                                                                }
                                                                            }
                                                                        })}
                                                                    />
                                                                    <label >Update</label>
                                                                </li>
                                                                <li>
                                                                    <input type="checkbox" className=""
                                                                        checked={roles?.permission?.user?.delete}
                                                                        onChange={(e) => setRoles({
                                                                            ...roles, permission: {
                                                                                ...roles.permission,
                                                                                user: {
                                                                                    ...roles.permission.user,
                                                                                    delete: !roles.permission.user.delete
                                                                                }
                                                                            }
                                                                        })}
                                                                    />
                                                                    <label >Delete</label>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label className="font-weight-bold genericlabel" >Product</label>
                                                            <ul className="ml-4" >
                                                                <li>
                                                                    <input type="checkbox" className=""
                                                                        checked={roles?.permission?.product?.read}
                                                                        onChange={(e) => setRoles({
                                                                            ...roles, permission: {
                                                                                ...roles.permission,
                                                                                product: {
                                                                                    ...roles.permission.product,
                                                                                    read: !roles.permission.product.read
                                                                                }
                                                                            }
                                                                        })}
                                                                    />
                                                                    <label >Read</label>
                                                                </li>
                                                                <li>
                                                                    <input type="checkbox" className=""
                                                                        checked={roles?.permission?.product?.write}
                                                                        onChange={(e) => setRoles({
                                                                            ...roles, permission: {
                                                                                ...roles.permission,
                                                                                product: {
                                                                                    ...roles.permission.product,
                                                                                    write: !roles.permission.product.write
                                                                                }
                                                                            }
                                                                        })}
                                                                    />
                                                                    <label >Write</label>
                                                                </li>
                                                                <li>
                                                                    <input type="checkbox" className=""
                                                                        checked={roles?.permission?.product?.update}
                                                                        onChange={(e) => setRoles({
                                                                            ...roles, permission: {
                                                                                ...roles.permission,
                                                                                product: {
                                                                                    ...roles.permission.product,
                                                                                    update: !roles.permission.product.update
                                                                                }
                                                                            }
                                                                        })}
                                                                    />
                                                                    <label >Update</label>
                                                                </li>
                                                                <li>
                                                                    <input type="checkbox" className=""
                                                                        checked={roles?.permission?.product?.delete}
                                                                        onChange={(e) => setRoles({
                                                                            ...roles, permission: {
                                                                                ...roles.permission,
                                                                                product: {
                                                                                    ...roles.permission.product,
                                                                                    delete: !roles.permission.product.delete
                                                                                }
                                                                            }
                                                                        })}
                                                                    />
                                                                    <label >Delete</label>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label className="font-weight-bold genericlabel" >Order</label>
                                                            <ul className="ml-4" >
                                                                <li>
                                                                    <input type="checkbox" className=""
                                                                        checked={roles?.permission?.order?.read}
                                                                        onChange={(e) => setRoles({
                                                                            ...roles, permission: {
                                                                                ...roles.permission,
                                                                                order: {
                                                                                    ...roles.permission.order,
                                                                                    read: !roles.permission.order.read
                                                                                }
                                                                            }
                                                                        })}
                                                                    />
                                                                    <label >Read</label>
                                                                </li>
                                                                <li>
                                                                    <input type="checkbox" className=""
                                                                        checked={roles?.permission?.order?.write}
                                                                        onChange={(e) => setRoles({
                                                                            ...roles, permission: {
                                                                                ...roles.permission,
                                                                                order: {
                                                                                    ...roles.permission.order,
                                                                                    write: !roles.permission.order.write
                                                                                }
                                                                            }
                                                                        })}
                                                                    />
                                                                    <label >Write</label>
                                                                </li>
                                                                <li>
                                                                    <input type="checkbox" className=""
                                                                        checked={roles?.permission?.order?.update}
                                                                        onChange={(e) => setRoles({
                                                                            ...roles, permission: {
                                                                                ...roles.permission,
                                                                                order: {
                                                                                    ...roles.permission.order,
                                                                                    update: !roles.permission.order.update
                                                                                }
                                                                            }
                                                                        })}
                                                                    />
                                                                    <label >Update</label>
                                                                </li>
                                                                <li>
                                                                    <input type="checkbox" className=""
                                                                        checked={roles?.permission?.order?.delete}
                                                                        onChange={(e) => setRoles({
                                                                            ...roles, permission: {
                                                                                ...roles.permission,
                                                                                order: {
                                                                                    ...roles.permission.order,
                                                                                    delete: !roles.permission.order.delete
                                                                                }
                                                                            }
                                                                        })}
                                                                    />
                                                                    <label >Delete</label>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>


                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label className="font-weight-bold genericlabel" >IGM</label>
                                                            <ul className="ml-4" >
                                                                <li>
                                                                    <input type="checkbox" className=""
                                                                        checked={roles?.permission?.igm?.read}
                                                                        onChange={(e) => setRoles({
                                                                            ...roles, permission: {
                                                                                ...roles.permission,
                                                                                igm: {
                                                                                    ...roles.permission.igm,
                                                                                    read: !roles.permission.igm.read
                                                                                }
                                                                            }
                                                                        })}
                                                                    />
                                                                    <label >Read</label>
                                                                </li>
                                                                <li>
                                                                    <input type="checkbox" className=""
                                                                        checked={roles?.permission?.igm?.write}
                                                                        onChange={(e) => setRoles({
                                                                            ...roles, permission: {
                                                                                ...roles.permission,
                                                                                igm: {
                                                                                    ...roles.permission.igm,
                                                                                    write: !roles.permission.igm.write
                                                                                }
                                                                            }
                                                                        })}
                                                                    />
                                                                    <label >Write</label>
                                                                </li>
                                                                <li>
                                                                    <input type="checkbox" className=""
                                                                        checked={roles?.permission?.igm?.update}
                                                                        onChange={(e) => setRoles({
                                                                            ...roles, permission: {
                                                                                ...roles.permission,
                                                                                igm: {
                                                                                    ...roles.permission.igm,
                                                                                    update: !roles.permission.igm.update
                                                                                }
                                                                            }
                                                                        })}
                                                                    />
                                                                    <label >Update</label>
                                                                </li>
                                                                <li>
                                                                    <input type="checkbox" className=""
                                                                        checked={roles?.permission?.igm?.delete}
                                                                        onChange={(e) => setRoles({
                                                                            ...roles, permission: {
                                                                                ...roles.permission,
                                                                                igm: {
                                                                                    ...roles.permission.igm,
                                                                                    delete: !roles.permission.igm.delete
                                                                                }
                                                                            }
                                                                        })}
                                                                    />
                                                                    <label >Delete</label>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label className="font-weight-bold genericlabel">Transaction</label>
                                                            <ul className="ml-4">
                                                                <li>
                                                                    <input type="checkbox" className="" checked={roles?.permission?.transaction?.read}
                                                                        onChange={(e) => setRoles({
                                                                            ...roles, permission: {
                                                                                ...roles.permission,
                                                                                transaction: {
                                                                                    ...roles.permission.transaction,
                                                                                    read: !roles.permission.transaction.read
                                                                                }
                                                                            }
                                                                        })}
                                                                    />
                                                                    <label>Read</label>
                                                                </li>
                                                                <li>
                                                                    <input type="checkbox" className="" checked={roles?.permission?.transaction?.write}
                                                                        onChange={(e) => setRoles({
                                                                            ...roles, permission: {
                                                                                ...roles.permission,
                                                                                transaction: {
                                                                                    ...roles.permission.transaction,
                                                                                    write: !roles.permission.transaction.write
                                                                                }
                                                                            }
                                                                        })}
                                                                    />
                                                                    <label>Write</label>
                                                                </li>
                                                                <li>
                                                                    <input type="checkbox" className="" checked={roles?.permission?.transaction?.update}
                                                                        onChange={(e) => setRoles({
                                                                            ...roles, permission: {
                                                                                ...roles.permission,
                                                                                transaction: {
                                                                                    ...roles.permission.transaction,
                                                                                    update: !roles.permission.transaction.update
                                                                                }
                                                                            }
                                                                        })}
                                                                    />
                                                                    <label>Update</label>
                                                                </li>
                                                                <li>
                                                                    <input type="checkbox" className="" checked={roles?.permission?.transaction?.delete}
                                                                        onChange={(e) => setRoles({
                                                                            ...roles, permission: {
                                                                                ...roles.permission,
                                                                                transaction: {
                                                                                    ...roles.permission.transaction,
                                                                                    delete: !roles.permission.transaction.delete
                                                                                }
                                                                            }
                                                                        })}
                                                                    />
                                                                    <label>Delete</label>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    {
                                                        seller_id === 0 && parent_id === 1 || role_id === 1 ? (
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label className="genericlabel" >Category</label>
                                                                    <ul className="ml-4" >
                                                                        <li>
                                                                            <input type="checkbox" className=""
                                                                                checked={roles?.permission?.category?.read}
                                                                                onChange={(e) => setRoles({
                                                                                    ...roles, permission: {
                                                                                        ...roles.permission,
                                                                                        category: {
                                                                                            ...roles.permission.category,
                                                                                            read: !roles.permission.category.read
                                                                                        }
                                                                                    }
                                                                                })}
                                                                            />
                                                                            <label >Read</label>
                                                                        </li>
                                                                        <li>
                                                                            <input type="checkbox" className=""
                                                                                checked={roles?.permission?.category?.write}
                                                                                onChange={(e) => setRoles({
                                                                                    ...roles, permission: {
                                                                                        ...roles.permission,
                                                                                        category: {
                                                                                            ...roles.permission.category,
                                                                                            write: !roles.permission.category.write
                                                                                        }
                                                                                    }
                                                                                })}
                                                                            />
                                                                            <label >Write</label>
                                                                        </li>
                                                                        <li>
                                                                            <input type="checkbox" className=""
                                                                                checked={roles?.permission?.category?.update}
                                                                                onChange={(e) => setRoles({
                                                                                    ...roles, permission: {
                                                                                        ...roles.permission,
                                                                                        category: {
                                                                                            ...roles.permission.category,
                                                                                            update: !roles.permission.category.update
                                                                                        }
                                                                                    }
                                                                                })}
                                                                            />
                                                                            <label >Update</label>
                                                                        </li>
                                                                        <li>
                                                                            <input type="checkbox" className=""
                                                                                checked={roles?.permission?.category?.delete}
                                                                                onChange={(e) => setRoles({
                                                                                    ...roles, permission: {
                                                                                        ...roles.permission,
                                                                                        category: {
                                                                                            ...roles.permission.category,
                                                                                            delete: !roles.permission.category.delete
                                                                                        }
                                                                                    }
                                                                                })}
                                                                            />
                                                                            <label >Delete</label>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        ) : null
                                                    }
                                                    {
                                                        seller_id === 0 && parent_id === 1 || role_id === 1 ? (
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label className="genericlabel" >Tax</label>
                                                                    <ul className="ml-4" >
                                                                        <li>
                                                                            <input type="checkbox" className=""
                                                                                checked={roles?.permission?.tax?.read}
                                                                                onChange={(e) => setRoles({
                                                                                    ...roles, permission: {
                                                                                        ...roles.permission,
                                                                                        tax: {
                                                                                            ...roles.permission.tax,
                                                                                            read: !roles.permission.tax.read
                                                                                        }
                                                                                    }
                                                                                })}
                                                                            />
                                                                            <label >Read</label>
                                                                        </li>
                                                                        <li>
                                                                            <input type="checkbox" className=""
                                                                                checked={roles?.permission?.tax?.write}
                                                                                onChange={(e) => setRoles({
                                                                                    ...roles, permission: {
                                                                                        ...roles.permission,
                                                                                        tax: {
                                                                                            ...roles.permission.tax,
                                                                                            write: !roles.permission.tax.write
                                                                                        }
                                                                                    }
                                                                                })}
                                                                            />
                                                                            <label >Write</label>
                                                                        </li>
                                                                        <li>
                                                                            <input type="checkbox" className=""
                                                                                checked={roles?.permission?.tax?.update}
                                                                                onChange={(e) => setRoles({
                                                                                    ...roles, permission: {
                                                                                        ...roles.permission,
                                                                                        tax: {
                                                                                            ...roles.permission.tax,
                                                                                            update: !roles.permission.tax.update
                                                                                        }
                                                                                    }
                                                                                })}
                                                                            />
                                                                            <label >Update</label>
                                                                        </li>
                                                                        <li>
                                                                            <input type="checkbox" className=""
                                                                                checked={roles?.permission?.tax?.delete}
                                                                                onChange={(e) => setRoles({
                                                                                    ...roles, permission: {
                                                                                        ...roles.permission,
                                                                                        tax: {
                                                                                            ...roles.permission.tax,
                                                                                            delete: !roles.permission.tax.delete
                                                                                        }
                                                                                    }
                                                                                })}
                                                                            />
                                                                            <label >Delete</label>
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                            </div>
                                                        ) : null
                                                    }
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label className="font-weight-bold genericlabel" >Role</label>
                                                            <ul className="ml-4" >
                                                                <li>
                                                                    <input type="checkbox" className=""
                                                                        checked={roles?.permission?.role?.read}
                                                                        onChange={(e) => setRoles({
                                                                            ...roles, permission: {
                                                                                ...roles.permission,
                                                                                role: {
                                                                                    ...roles.permission.role,
                                                                                    read: !roles.permission.role.read
                                                                                }
                                                                            }
                                                                        })}
                                                                    />
                                                                    <label >Read</label>
                                                                </li>
                                                                <li>
                                                                    <input type="checkbox" className=""
                                                                        checked={roles?.permission?.role?.write}
                                                                        onChange={(e) => setRoles({
                                                                            ...roles, permission: {
                                                                                ...roles.permission,
                                                                                role: {
                                                                                    ...roles.permission.role,
                                                                                    write: !roles.permission.role.write
                                                                                }
                                                                            }
                                                                        })}
                                                                    />
                                                                    <label >Write</label>
                                                                </li>
                                                                <li>
                                                                    <input type="checkbox" className=""
                                                                        checked={roles?.permission?.role?.update}
                                                                        onChange={(e) => setRoles({
                                                                            ...roles, permission: {
                                                                                ...roles.permission,
                                                                                role: {
                                                                                    ...roles.permission.role,
                                                                                    update: !roles.permission.role.update
                                                                                }
                                                                            }
                                                                        })}
                                                                    />
                                                                    <label >Update</label>
                                                                </li>
                                                                <li>
                                                                    <input type="checkbox" className=""
                                                                        checked={roles?.permission?.role?.delete}
                                                                        onChange={(e) => setRoles({
                                                                            ...roles, permission: {
                                                                                ...roles.permission,
                                                                                role: {
                                                                                    ...roles.permission.role,
                                                                                    delete: !roles.permission.role.delete
                                                                                }
                                                                            }
                                                                        })}
                                                                    />
                                                                    <label >Delete</label>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label className="font-weight-bold genericlabel" >Location</label>
                                                            <ul className="ml-4" >
                                                                <li>
                                                                    <input type="checkbox" className=""
                                                                        checked={roles?.permission?.location?.read}
                                                                        onChange={(e) => setRoles({
                                                                            ...roles, permission: {
                                                                                ...roles.permission,
                                                                                location: {
                                                                                    ...roles.permission.location,
                                                                                    read: !roles.permission.location.read
                                                                                }
                                                                            }
                                                                        })}
                                                                    />
                                                                    <label >Read</label>
                                                                </li>
                                                                <li>
                                                                    <input type="checkbox" className=""
                                                                        checked={roles?.permission?.location?.write}
                                                                        onChange={(e) => setRoles({
                                                                            ...roles, permission: {
                                                                                ...roles.permission,
                                                                                location: {
                                                                                    ...roles.permission.location,
                                                                                    write: !roles.permission.location.write
                                                                                }
                                                                            }
                                                                        })}
                                                                    />
                                                                    <label >Write</label>
                                                                </li>
                                                                <li>
                                                                    <input type="checkbox" className=""
                                                                        checked={roles?.permission?.location?.update}
                                                                        onChange={(e) => setRoles({
                                                                            ...roles, permission: {
                                                                                ...roles.permission,
                                                                                location: {
                                                                                    ...roles.permission.location,
                                                                                    update: !roles.permission.location.update
                                                                                }
                                                                            }
                                                                        })}
                                                                    />
                                                                    <label >Update</label>
                                                                </li>
                                                                <li>
                                                                    <input type="checkbox" className=""
                                                                        checked={roles?.permission?.location?.delete}
                                                                        onChange={(e) => setRoles({
                                                                            ...roles, permission: {
                                                                                ...roles.permission,
                                                                                location: {
                                                                                    ...roles.permission.location,
                                                                                    delete: !roles.permission.location.delete
                                                                                }
                                                                            }
                                                                        })}
                                                                    />
                                                                    <label >Delete</label>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* -------------------------NEW CODE--------------*/}
                                                <div className="my-2 w-100 w-md-75 ">
                                                    <TableContainer component={Paper}>
                                                        <Table sx={{ maxWidth: "100%", background: "white" }} aria-label="simple table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell align="center" className="bg-white font-weight-bold">Roles</TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold"> Read</TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold"> Write</TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold"> Update</TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold"> Delete</TableCell>
                                                                </TableRow>
                                                                {seller_id === 0 && parent_id === 1 || role_id === 1 && <TableRow>
                                                                    <TableCell align="center" className="bg-white">Seller</TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold">

                                                                        <input type="checkbox" className=""
                                                                            checked={roles?.permission?.seller?.read}
                                                                            onChange={(e) => setRoles({
                                                                                ...roles, permission: {
                                                                                    ...roles.permission,
                                                                                    seller: {
                                                                                        ...roles.permission.seller,
                                                                                        read: !roles.permission.seller.read
                                                                                    }
                                                                                }
                                                                            })} />

                                                                    </TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold">
                                                                        <input type="checkbox" className=""
                                                                            checked={roles?.permission?.seller?.write}
                                                                            onChange={(e) => setRoles({
                                                                                ...roles, permission: {
                                                                                    ...roles.permission,
                                                                                    seller: {
                                                                                        ...roles.permission.seller,
                                                                                        write: !roles.permission.seller.write
                                                                                    }
                                                                                }
                                                                            })} />
                                                                    </TableCell>

                                                                    <TableCell align="center" className="bg-white font-weight-bold">
                                                                        <input type="checkbox" className=""
                                                                            checked={roles?.permission?.category?.update}
                                                                            onChange={(e) => setRoles({
                                                                                ...roles, permission: {
                                                                                    ...roles.permission,
                                                                                    category: {
                                                                                        ...roles.permission.category,
                                                                                        update: !roles.permission.category.update
                                                                                    }
                                                                                }
                                                                            })}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold">

                                                                        <input type="checkbox" className=""
                                                                            checked={roles?.permission?.category?.delete}
                                                                            onChange={(e) => setRoles({
                                                                                ...roles, permission: {
                                                                                    ...roles.permission,
                                                                                    category: {
                                                                                        ...roles.permission.category,
                                                                                        delete: !roles.permission.category.delete
                                                                                    }
                                                                                }
                                                                            })}
                                                                        />

                                                                    </TableCell>
                                                                </TableRow>}

                                                                <TableRow>
                                                                    <TableCell align="center" className="bg-white">User</TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold">
                                                                        <input type="checkbox" className=""
                                                                            checked={roles?.permission?.user?.read}
                                                                            onChange={(e) => setRoles({
                                                                                ...roles, permission: {
                                                                                    ...roles.permission,
                                                                                    user: {
                                                                                        ...roles.permission.user,
                                                                                        read: !roles.permission.user.read
                                                                                    }
                                                                                }
                                                                            })}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold">
                                                                        <input type="checkbox" className=""
                                                                            checked={roles?.permission?.user?.write}
                                                                            onChange={(e) => setRoles({
                                                                                ...roles, permission: {
                                                                                    ...roles.permission,
                                                                                    user: {
                                                                                        ...roles.permission.user,
                                                                                        write: !roles.permission.user.write
                                                                                    }
                                                                                }
                                                                            })}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold">  <input type="checkbox" className=""
                                                                        checked={roles?.permission?.user?.update}
                                                                        onChange={(e) => setRoles({
                                                                            ...roles, permission: {
                                                                                ...roles.permission,
                                                                                user: {
                                                                                    ...roles.permission.user,
                                                                                    update: !roles.permission.user.update
                                                                                }
                                                                            }
                                                                        })}
                                                                    />
                                                                    </TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold">
                                                                        <input type="checkbox" className=""
                                                                            checked={roles?.permission?.user?.delete}
                                                                            onChange={(e) => setRoles({
                                                                                ...roles, permission: {
                                                                                    ...roles.permission,
                                                                                    user: {
                                                                                        ...roles.permission.user,
                                                                                        delete: !roles.permission.user.delete
                                                                                    }
                                                                                }
                                                                            })}
                                                                        /></TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell align="center" className="bg-white">Product</TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold">
                                                                        <input type="checkbox" className=""
                                                                            checked={roles?.permission?.product?.read}
                                                                            onChange={(e) => setRoles({
                                                                                ...roles, permission: {
                                                                                    ...roles.permission,
                                                                                    product: {
                                                                                        ...roles.permission.product,
                                                                                        read: !roles.permission.product.read
                                                                                    }
                                                                                }
                                                                            })}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold">
                                                                        <input type="checkbox" className=""
                                                                            checked={roles?.permission?.product?.write}
                                                                            onChange={(e) => setRoles({
                                                                                ...roles, permission: {
                                                                                    ...roles.permission,
                                                                                    product: {
                                                                                        ...roles.permission.product,
                                                                                        write: !roles.permission.product.write
                                                                                    }
                                                                                }
                                                                            })}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold">

                                                                        <input type="checkbox" className=""
                                                                            checked={roles?.permission?.product?.update}
                                                                            onChange={(e) => setRoles({
                                                                                ...roles, permission: {
                                                                                    ...roles.permission,
                                                                                    product: {
                                                                                        ...roles.permission.product,
                                                                                        update: !roles.permission.product.update
                                                                                    }
                                                                                }
                                                                            })}
                                                                        />

                                                                    </TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold">

                                                                        <input type="checkbox" className=""
                                                                            checked={roles?.permission?.product?.delete}
                                                                            onChange={(e) => setRoles({
                                                                                ...roles, permission: {
                                                                                    ...roles.permission,
                                                                                    product: {
                                                                                        ...roles.permission.product,
                                                                                        delete: !roles.permission.product.delete
                                                                                    }
                                                                                }
                                                                            })}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell align="center" className="bg-white">Order</TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold">
                                                                        <input type="checkbox" className=""
                                                                            checked={roles?.permission?.order?.read}
                                                                            onChange={(e) => setRoles({
                                                                                ...roles, permission: {
                                                                                    ...roles.permission,
                                                                                    order: {
                                                                                        ...roles.permission.order,
                                                                                        read: !roles.permission.order.read
                                                                                    }
                                                                                }
                                                                            })}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold">
                                                                        <input type="checkbox" className=""
                                                                            checked={roles?.permission?.order?.write}
                                                                            onChange={(e) => setRoles({
                                                                                ...roles, permission: {
                                                                                    ...roles.permission,
                                                                                    order: {
                                                                                        ...roles.permission.order,
                                                                                        write: !roles.permission.order.write
                                                                                    }
                                                                                }
                                                                            })}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold">
                                                                        <input type="checkbox" className=""
                                                                            checked={roles?.permission?.order?.update}
                                                                            onChange={(e) => setRoles({
                                                                                ...roles, permission: {
                                                                                    ...roles.permission,
                                                                                    order: {
                                                                                        ...roles.permission.order,
                                                                                        update: !roles.permission.order.update
                                                                                    }
                                                                                }
                                                                            })}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold">
                                                                        <input type="checkbox" className=""
                                                                            checked={roles?.permission?.order?.delete}
                                                                            onChange={(e) => setRoles({
                                                                                ...roles, permission: {
                                                                                    ...roles.permission,
                                                                                    order: {
                                                                                        ...roles.permission.order,
                                                                                        delete: !roles.permission.order.delete
                                                                                    }
                                                                                }
                                                                            })}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell align="center" className="bg-white">IGM</TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold">
                                                                        <input type="checkbox" className=""
                                                                            checked={roles?.permission?.igm?.read}
                                                                            onChange={(e) => setRoles({
                                                                                ...roles, permission: {
                                                                                    ...roles.permission,
                                                                                    igm: {
                                                                                        ...roles.permission.igm,
                                                                                        read: !roles.permission.igm.read
                                                                                    }
                                                                                }
                                                                            })} />
                                                                    </TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold">
                                                                        <input type="checkbox" className=""
                                                                            checked={roles?.permission?.igm?.write}
                                                                            onChange={(e) => setRoles({
                                                                                ...roles, permission: {
                                                                                    ...roles.permission,
                                                                                    igm: {
                                                                                        ...roles.permission.igm,
                                                                                        write: !roles.permission.igm.write
                                                                                    }
                                                                                }
                                                                            })} />
                                                                    </TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold">
                                                                        <input type="checkbox" className=""
                                                                            checked={roles?.permission?.igm?.update}
                                                                            onChange={(e) => setRoles({
                                                                                ...roles, permission: {
                                                                                    ...roles.permission,
                                                                                    igm: {
                                                                                        ...roles.permission.igm,
                                                                                        update: !roles.permission.igm.update
                                                                                    }
                                                                                }
                                                                            })} />
                                                                    </TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold">
                                                                        <input type="checkbox" className=""
                                                                            checked={roles?.permission?.igm?.delete}
                                                                            onChange={(e) => setRoles({
                                                                                ...roles, permission: {
                                                                                    ...roles.permission,
                                                                                    igm: {
                                                                                        ...roles.permission.igm,
                                                                                        delete: !roles.permission.igm.delete
                                                                                    }
                                                                                }
                                                                            })} />
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell align="center" className="bg-white">Transaction</TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold">
                                                                        <input type="checkbox" className="" checked={roles?.permission?.transaction?.read}
                                                                            onChange={(e) => setRoles({
                                                                                ...roles, permission: {
                                                                                    ...roles.permission,
                                                                                    transaction: {
                                                                                        ...roles.permission.transaction,
                                                                                        read: !roles.permission.transaction.read
                                                                                    }
                                                                                }
                                                                            })}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold">
                                                                        <input type="checkbox" className="" checked={roles?.permission?.transaction?.write}
                                                                            onChange={(e) => setRoles({
                                                                                ...roles, permission: {
                                                                                    ...roles.permission,
                                                                                    transaction: {
                                                                                        ...roles.permission.transaction,
                                                                                        write: !roles.permission.transaction.write
                                                                                    }
                                                                                }
                                                                            })}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold">
                                                                        <input type="checkbox" className="" checked={roles?.permission?.transaction?.update}
                                                                            onChange={(e) => setRoles({
                                                                                ...roles, permission: {
                                                                                    ...roles.permission,
                                                                                    transaction: {
                                                                                        ...roles.permission.transaction,
                                                                                        update: !roles.permission.transaction.update
                                                                                    }
                                                                                }
                                                                            })}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold">
                                                                        <input type="checkbox" className="" checked={roles?.permission?.transaction?.delete}
                                                                            onChange={(e) => setRoles({
                                                                                ...roles, permission: {
                                                                                    ...roles.permission,
                                                                                    transaction: {
                                                                                        ...roles.permission.transaction,
                                                                                        delete: !roles.permission.transaction.delete
                                                                                    }
                                                                                }
                                                                            })}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                                {seller_id === 0 && parent_id === 1 || role_id === 1 && <TableRow>
                                                                    <TableCell align="center" className="bg-white">Category</TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold">
                                                                        <input type="checkbox" className=""
                                                                            checked={roles?.permission?.category?.read}
                                                                            onChange={(e) => setRoles({
                                                                                ...roles, permission: {
                                                                                    ...roles.permission,
                                                                                    category: {
                                                                                        ...roles.permission.category,
                                                                                        read: !roles.permission.category.read
                                                                                    }
                                                                                }
                                                                            })}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold">
                                                                        <input type="checkbox" className=""
                                                                            checked={roles?.permission?.category?.write}
                                                                            onChange={(e) => setRoles({
                                                                                ...roles, permission: {
                                                                                    ...roles.permission,
                                                                                    category: {
                                                                                        ...roles.permission.category,
                                                                                        write: !roles.permission.category.write
                                                                                    }
                                                                                }
                                                                            })}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold">
                                                                        <input type="checkbox" className=""
                                                                            checked={roles?.permission?.category?.update}
                                                                            onChange={(e) => setRoles({
                                                                                ...roles, permission: {
                                                                                    ...roles.permission,
                                                                                    category: {
                                                                                        ...roles.permission.category,
                                                                                        update: !roles.permission.category.update
                                                                                    }
                                                                                }
                                                                            })}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold">
                                                                        <input type="checkbox" className=""
                                                                            checked={roles?.permission?.category?.delete}
                                                                            onChange={(e) => setRoles({
                                                                                ...roles, permission: {
                                                                                    ...roles.permission,
                                                                                    category: {
                                                                                        ...roles.permission.category,
                                                                                        delete: !roles.permission.category.delete
                                                                                    }
                                                                                }
                                                                            })}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>}


                                                                {seller_id === 0 && parent_id === 1 || role_id === 1 && <TableRow>
                                                                    <TableCell align="center" className="bg-white">Tax</TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold">
                                                                        <input type="checkbox" className=""
                                                                            checked={roles?.permission?.tax?.read}
                                                                            onChange={(e) => setRoles({
                                                                                ...roles, permission: {
                                                                                    ...roles.permission,
                                                                                    tax: {
                                                                                        ...roles.permission.tax,
                                                                                        read: !roles.permission.tax.read
                                                                                    }
                                                                                }
                                                                            })}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold">
                                                                        <input type="checkbox" className=""
                                                                            checked={roles?.permission?.tax?.write}
                                                                            onChange={(e) => setRoles({
                                                                                ...roles, permission: {
                                                                                    ...roles.permission,
                                                                                    tax: {
                                                                                        ...roles.permission.tax,
                                                                                        write: !roles.permission.tax.write
                                                                                    }
                                                                                }
                                                                            })}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold">
                                                                        <input type="checkbox" className=""
                                                                            checked={roles?.permission?.tax?.update}
                                                                            onChange={(e) => setRoles({
                                                                                ...roles, permission: {
                                                                                    ...roles.permission,
                                                                                    tax: {
                                                                                        ...roles.permission.tax,
                                                                                        update: !roles.permission.tax.update
                                                                                    }
                                                                                }
                                                                            })}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold">
                                                                        <input type="checkbox" className=""
                                                                            checked={roles?.permission?.tax?.delete}
                                                                            onChange={(e) => setRoles({
                                                                                ...roles, permission: {
                                                                                    ...roles.permission,
                                                                                    tax: {
                                                                                        ...roles.permission.tax,
                                                                                        delete: !roles.permission.tax.delete
                                                                                    }
                                                                                }
                                                                            })}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>}


                                                                <TableRow>
                                                                    <TableCell align="center" className="bg-white">Role</TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold">
                                                                        <input type="checkbox" className=""
                                                                            checked={roles?.permission?.role?.read}
                                                                            onChange={(e) => setRoles({
                                                                                ...roles, permission: {
                                                                                    ...roles.permission,
                                                                                    role: {
                                                                                        ...roles.permission.role,
                                                                                        read: !roles.permission.role.read
                                                                                    }
                                                                                }
                                                                            })}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold">
                                                                        <input type="checkbox" className=""
                                                                            checked={roles?.permission?.role?.write}
                                                                            onChange={(e) => setRoles({
                                                                                ...roles, permission: {
                                                                                    ...roles.permission,
                                                                                    role: {
                                                                                        ...roles.permission.role,
                                                                                        write: !roles.permission.role.write
                                                                                    }
                                                                                }
                                                                            })}
                                                                        /></TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold">
                                                                        <input type="checkbox" className=""
                                                                            checked={roles?.permission?.role?.update}
                                                                            onChange={(e) => setRoles({
                                                                                ...roles, permission: {
                                                                                    ...roles.permission,
                                                                                    role: {
                                                                                        ...roles.permission.role,
                                                                                        update: !roles.permission.role.update
                                                                                    }
                                                                                }
                                                                            })}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold">
                                                                        <input type="checkbox" className=""
                                                                            checked={roles?.permission?.role?.delete}
                                                                            onChange={(e) => setRoles({
                                                                                ...roles, permission: {
                                                                                    ...roles.permission,
                                                                                    role: {
                                                                                        ...roles.permission.role,
                                                                                        delete: !roles.permission.role.delete
                                                                                    }
                                                                                }
                                                                            })}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell align="center" className="bg-white">Location</TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold"> <input type="checkbox" className=""
                                                                        checked={roles?.permission?.location?.read}
                                                                        onChange={(e) => setRoles({
                                                                            ...roles, permission: {
                                                                                ...roles.permission,
                                                                                location: {
                                                                                    ...roles.permission.location,
                                                                                    read: !roles.permission.location.read
                                                                                }
                                                                            }
                                                                        })}
                                                                    /></TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold">
                                                                        <input type="checkbox" className=""
                                                                            checked={roles?.permission?.location?.write}
                                                                            onChange={(e) => setRoles({
                                                                                ...roles, permission: {
                                                                                    ...roles.permission,
                                                                                    location: {
                                                                                        ...roles.permission.location,
                                                                                        write: !roles.permission.location.write
                                                                                    }
                                                                                }
                                                                            })}
                                                                        /></TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold">
                                                                        <input type="checkbox" className=""
                                                                            checked={roles?.permission?.location?.update}
                                                                            onChange={(e) => setRoles({
                                                                                ...roles, permission: {
                                                                                    ...roles.permission,
                                                                                    location: {
                                                                                        ...roles.permission.location,
                                                                                        update: !roles.permission.location.update
                                                                                    }
                                                                                }
                                                                            })}
                                                                        /></TableCell>
                                                                    <TableCell align="center" className="bg-white font-weight-bold">
                                                                        <input type="checkbox" className=""
                                                                            checked={roles?.permission?.location?.delete}
                                                                            onChange={(e) => setRoles({
                                                                                ...roles, permission: {
                                                                                    ...roles.permission,
                                                                                    location: {
                                                                                        ...roles.permission.location,
                                                                                        delete: !roles.permission.location.delete
                                                                                    }
                                                                                }
                                                                            })}
                                                                        /></TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </div>



                                            </div>
                                            <div className="col-md-12">

                                                {
                                                    isUpdate ? (
                                                        <button type="submit" className="btn btn-success"
                                                            onClick={updateRole}
                                                        >
                                                            Update
                                                        </button>
                                                    ) : (
                                                        <button type="submit" className="btn btn-primary"
                                                            onClick={creareRoleHandler}
                                                        >
                                                            Save
                                                        </button>
                                                    )
                                                }

                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="card-body" style={{ minHeight: "70vh" }}>
                                        <div className="table-responsive showdiv">
                                            <table className="table table-bordered " id="dataTable" width="100%" >
                                                <thead>
                                                    <tr>
                                                        <th>Serial No.</th>
                                                        <th>Name</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        listingData.map((item: any, index: any) => (
                                                            <>
                                                                <tr key={index} >
                                                                    <td>{index + 1}</td>
                                                                    <td>{item?.role_name}</td>
                                                                    <td>
                                                                        {
                                                                            item?.status ?
                                                                                <span className="badge badge-success">Active</span> :
                                                                                <span className="badge badge-danger">Deactive</span>
                                                                        }
                                                                    </td>
                                                                    <td>

                                                                        <div className="row justify-content-center">
                                                                            {
                                                                                role?.update ? (
                                                                                    <div className="">
                                                                                        <button className="btn btn-success btn-circle"
                                                                                            onClick={() => editRedirect(item?.id)}
                                                                                        >
                                                                                            <i className="fas fa-eye"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                ) : (null)
                                                                            }
                                                                            {
                                                                                role?.delete ? (
                                                                                    <div className="">
                                                                                        <button className="btn btn-danger btn-circle"
                                                                                            onClick={() => deleteRole(item?.id)}
                                                                                        >
                                                                                            <i className="fas fa-trash"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                ) : (null)
                                                                            }


                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        ))
                                                    }
                                                  

                                                </tbody>
                                            </table>

                                        </div>

                                        <div className="hidediv">  
                                                    {
                                                        listingData.map((item: any, index: any) => (
                                                            <>
                                                                <div className="border p-1">
                                                                    <div className="d-flex  mt-1 align-items-center" style={{ gap: "10px" }}>
                                                                        <span className="genericlabel" style={{ width: "28%" }} >Role Name</span>
                                                                        <span className="" style={{ fontSize: "14px", width: '70%' }}>{item?.role_name}</span>
                                                                    </div>
                                                                    <div className="d-flex  mt-1" style={{ gap: "10px" }}>
                                                                        <span className="genericlabel " style={{ width: "28%" }} > Status</span>
                                                                        <span className="" style={{ fontSize: "14px", width: '70%' }}>{item?.status ? "Active" : "Inactive"}</span>
                                                                    </div>
                                                                    
                                                                    <div className="mt-2 d-flex" style={{gap: "10px"}}>
                                                                       <p style={{ width: "70px", fontWeight: 600, borderRadius: "17px", color: "white", background: "blue", padding: "3px", textAlign: "center" }} 
                                                                       onClick={() => editRedirect(item?.id)}>Edit</p>

                                                                       <p style={{ width: "70px", fontWeight: 600, borderRadius: "17px", color: "white", background: "red", padding: "3px", textAlign: "center" }} 
                                                                        onClick={() => deleteRole(item?.id)}>Delete</p>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ))
                                                    }

</div>   


                                    </div>
                                )
                            }




                        </div>
                    </div>
                </div>

            </ThemeMaker>
        </>
    )
}

export default Roles;