import React, { useEffect, useRef } from "react";
import { useState } from "react";
import "./switch.css";
import { tokenDecode } from "../../utils/helper";
import { toast } from "react-toastify";
import edit from "../../icons/edit_ui.png";
import Circularprogress from "../../components/Generics/Circularprogress";
import axios from "axios";
import { Link } from "react-router-dom";
import editfull from "../../icons/edit.png";
import ReactPaginate from 'react-paginate';
import styled from "styled-components";
import { config } from "../../utils/config";
import Applyfilter from "../../components/Applyfilter/Applyfilter";
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import "../../index.css"
import ThemeMaker from "../../components/thememaker";
import { data } from "jquery";
import CloseIcon from '@mui/icons-material/Close';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import DeleteIcon from '@mui/icons-material/Delete';
import QRCode from "react-qr-code";
import QrCode2Icon from '@mui/icons-material/QrCode2';
import { toPng } from 'html-to-image';
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import styles from "./style.module.css";
const { apiEndpoint, baseNodeapiUrl } = config

const modalStyle = {
  position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 1000, bgcolor: 'background.paper', boxShadow: 24, borderRadius: "10px", p: 1, color: "black",
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ImageContainer = styled.div`
  max-height: 125px;
  width: 25%;
  
  img{
    width: 100%!important;
    height: 125px;
  object-fit: contain;
}
  `


const Instorecatalog = (props) => {
  const decoded = tokenDecode();
  let current_sellerid = decoded.data.user.seller_id;
  const { permission: { product }, } = decoded?.data;
  const [searchValue, setsearchValue] = useState("");
  const [openInput, setopenInput] = useState(null);
  const [productlist, setproductlist] = useState([]);
  const [productisLoading, setproductisLoading] = useState(false);
  const [noproducts, setNoproducts] = useState(false);
  const [file, setFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [Alertopen, setAlertOpen] = useState(false);
  const [syncing, setsyncing] = useState(false);
  const [ConfirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const handleAlertOpen = () => {
    setOpen(false);
    setAlertOpen(true);

  };

  const handleAlertClose = () => {
    setAlertOpen(false);
    setsyncingData({
      seller_id: current_sellerid.toString(),
      price: false,
      inventory: false,
      sku: false,
      title: false,
      weight: false
    })
  };

  const [syncingData, setsyncingData] = useState({
    seller_id: current_sellerid.toString(),
    price: false,
    inventory: false,
    sku: false,
    title: false,
    weight: false

  })
  const handleSyncModalOpen = () => setOpen(true);
  const handleSyncModalClose = () => {
    setOpen(false);
    setsyncingData({
      seller_id: current_sellerid.toString(),
      price: false,
      inventory: false,
      sku: false,
      title: false,
      weight: false
    })
  }
  const syncmodalstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: "10px",
    boxShadow: 24,
    p: 2,
  };

  async function getproductlist() {
    let parentId = decoded?.data?.user?.parent_id;
    let SellerId = decoded?.data?.user?.seller_id;

    let payload;
    if (parentId > 1) {
      payload = { seller_id: parentId }

    } else {
      payload = { seller_id: SellerId }
    }
    setproductisLoading(true)
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/listproductsallsvar`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
        },
        data: payload,
      });
      if (data.status === 200) {
        setproductisLoading(false)
        setItemOffset(0)
        setproductlist(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }


  function maxPriceHandler(id) {
    setopenInput(openInput === id ? null : id);
    const maxPriceProduct = productlist.filter((item) => item.id === id);
    // setquickUpdatedMaxPrice2(...maxPriceProduct);
    let mp;
    // console.log("maxPriceProduct---", maxPriceProduct)
    if (maxPriceProduct?.tax_included_price) {
      mp = maxPriceProduct.tax_included_price;
      // console.log("mp--->", mp)
      // setquickUpdatedMaxPrice(mp);
    } else if (maxPriceProduct?.mrp) {
      mp = maxPriceProduct.mrp;
      // setquickUpdatedMaxPrice(mp);
    }
  }

  function handleSort(e) {

    if (e.target.value == 0) {
      const sortedLowToHigh = [...productlist].sort((a, b) => {
        return parseInt(a.selling_price) - parseInt(b.selling_price);
      });
      setproductlist(sortedLowToHigh);
    } else if (e.target.value == 1) {
      const sortedHightoLow = [...productlist].sort((a, b) => {
        return parseInt(b.selling_price) - parseInt(a.selling_price);
      });

      setproductlist(sortedHightoLow);
    } else {
      getproductlist();
    }
  }

  function searchHandler(e) {
    setsearchValue(e.target.value);
    if (e.target.value == "") {
      getproductlist();
      setNoproducts(false)
    }
  }

  async function searchResult(e) {
    if (e.key == "Enter") {
      try {
        const { data } = await axios({
          url: `${apiEndpoint}/searchproductpro`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
          },
          data: {
            title: searchValue,
            seller_id: current_sellerid,
          },
        });
        if (data) {
          setproductlist(data?.data);
          setItemOffset(0)
          // console.log(data.data)
          if (data?.data?.length < 1) {
            setNoproducts(true)
          }
        }
      } catch (error) { }
    }

  }

  //---------------------------------------------- code  for pagination------------------------------------------------
  const [itemCheckboxes, setItemCheckboxes] = useState({});

  useEffect(() => {
    const checkboxes = {};
    productlist.forEach((item) => {
      checkboxes[item.id] = item.status;
    });
    setItemCheckboxes(checkboxes);
  }, [productlist]);

  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + 10;

  const [currentItems, setcurrentItems] = useState([]);

  useEffect(() => {
    let z = productlist.slice(itemOffset, endOffset)
    setcurrentItems([...z])
  }, [productlist, itemOffset])

  const pageCount = Math.ceil(productlist.length / 10);

  const handlePageClick = (event) => {
    window.scrollTo(0, 0)
    const newOffset = (event.selected * 10) % productlist.length;
    setItemOffset(newOffset);
  };

  //---------------------------------------------- code for pagination------------------------------------------------

  function updateProductList(productList) {
    setproductlist(productList)
  }

  const exportToExcel = async () => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/allproductexport`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
        },
      })
      console.log(data)
      const headers = Object.keys(data.message[0]);
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(data.message, { header: headers });
      XLSX.utils.book_append_sheet(wb, ws, 'Orders');
      const fileName = `seller_instoreProducts.xlsx`;
      XLSX.writeFile(wb, fileName);
      toast.success("Products Exported Successfully")
    } catch (err) {
      toast.error("Server Error, please try later!")
    }
  };



  const handleImport = async () => {
    // console.log(file)
    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      complete: ({ data }) => {
        console.log(data)

        let x = data.map(item => {
          return {
            reference_id: item.reference_id,
            position: item?.position,
            selling_price: item?.selling_price
          }
        })
        sendDataToDb(x)
      },
    });
  };


  async function sendDataToDb(jsonData) {
    try {

      console.log(jsonData)
      let result = await axios({
        url: `${apiEndpoint}/updateprice`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,

        },
        data: jsonData,

      })
      getproductlist();
    } catch (error) {
      toast.error("403 error !");
    }
  }


  async function syncPriceHandler() {
    setsyncing(true)
    try {
      const data = await axios({
        url: `${baseNodeapiUrl}/shopify/sync`,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`
        },
        data: {
          "seller_id": syncingData.seller_id,
          "price": syncingData.price.toString(),
          "inventory": syncingData.inventory.toString(),
          "sku": syncingData.sku.toString(),
          "title": syncingData.title.toString(),
          "weight": syncingData.weight.toString()
        }

      })
      if (data.status === 200 && data.data.status === 200) {
        toast.success("Product Synced Successfully");
        setAlertOpen(false);
        setsyncing(false)
        getproductlist();
        setsyncingData({
          seller_id: current_sellerid.toString(),
          price: false,
          inventory: false,
          sku: false,
          title: false,
          weight: false
        })
      } else {
        toast.error("Something Went Wrong")
        setsyncing(true)
      }
    } catch (err) {
      console.log(err)
      setsyncing(true)
    }
  }


  function syncCheckHandler(e) {
    const { name } = e.target;
    console.log(name)
    console.log(e.target.value)
    setsyncingData((prev) => {
      return {
        ...prev, [name]: !prev[name]
      }
    })
  }


  async function deleteProductHandler(item) {
    try {
      const data = await axios({
        url: `${apiEndpoint}/deleteVarNonPro`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`
        },
        data: {
          id: item.id,
          "has_variations": item.has_variations,
        }

      })
      if (data.status === 200) {
        toast.success("Product Deleted Successfully");
        getproductlist();
        handleShipementModalClose()
      } else {
        toast.error("Something Went Wrong")

      }
    } catch (err) {
      console.log(err)
      toast.error("Something Went Wrong")
    }
  }

  async function bulkdeleteHandler(item) {
    try {
      const data = await axios({
        url: `${apiEndpoint}/bulk_delete`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`
        },
        data: selectedBox

      })
      if (data.status === 200) {
        toast.success("Selected Product Deleted!");
        getproductlist();
        handleShipementModalClose()
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (err) {
      console.log(err)
      toast.error("Something Went Wrong")
    }
  }

  function handleShipementModalClose() {
    setConfirmDeleteModalOpen(false);
    setConfirmDeleteModalOpen(null)
  }
  const [selectedBox, setselectedBox] = useState([])
  function checkHandler(row) {
    console.log("dhadha", row.id);
    let hasId = selectedBox.some((item) => item == row.id)
    if (!hasId) {
      setselectedBox((prev) => {
        return [...prev, row.id]
      })
    } else {
      setselectedBox((prev) => {
        return prev.filter((item) => item !== row.id)
      })
    }

  }
  const [QRModalOpen, setQRModalOpen] = useState({
    openstatus: false,
    value: "",
    isLoading: false
  });
  function handleQRModalClose() {
    setQRModalOpen({ ...QRModalOpen, openstatus: false })
  }
  async function getQRHandler(item) {
    let ProviderId = decoded?.data?.seller_data?.provider_id

    setQRModalOpen({ ...QRModalOpen, openstatus: true, isLoading: true })

    try {
      const data = await axios({
        url: `${baseNodeapiUrl}/api/product_qr_Code`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`
        },
        data: {
          "id": ProviderId,
          "descriptor_name": item?.name,
          "location_id": ProviderId,
          "bppId": "ondc.xpressbaazaar.com"
        }

      })
      setQRModalOpen({ openstatus: true, value: data.data.data, isLoading: false })
    } catch (err) {
      setQRModalOpen({ ...QRModalOpen, openstatus: false, isLoading: false })
      toast.error("Error in getting QR, please try later!")
    }
  }
  const qrRef = useRef(null);
  const handleDownload = () => {
    if (qrRef.current) {
      toPng(qrRef.current)
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.href = dataUrl;
          link.download = 'qr-code.png';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((err) => {
          console.error('Failed to download QR code', err);
        });
    }
  };


  useEffect(() => {
    getproductlist();
  }, []);

  return (
    <ThemeMaker>
      <>
        <div className="row mx-auto p-3">


          {/* -------------------------------------------- search & filter start ---------------------------------------- */}
          <div className="col-12">
            <input type="search"
              name="name" placeholder="Search Product" className={`${styles.productsearchInput} mr-2 rounded-pill`} value={searchValue} onChange={searchHandler} onKeyPress={searchResult} style={{ fontSize: "14px" }} />

            <div className={styles.buttonsContainers}>
              <button className="btn btn-primary btn-sm" onClick={() => setTimeout(() => {
                exportToExcel()
              }, 2000)}>Export Data</button>

              {product.update && <button className="ml-2  btn btn-secondary btn-sm" onClick={handleSyncModalOpen}>Sync Product</button>}


            </div>

            {/* <button className="ml-2  btn btn-secondary" onClick={handleAlertOpen}>Sync Product</button> */}
            {/* <div className="importingSection">
            <input type="file" id="inputFile" onChange={(e) => {
              // console.log("change working");
              setFile(e.target.files[0]);
            }} style={{ marginTop: "30px" }} />
            <button className="btn btn-secondary" onClick={handleImport} > Submit </button>

          </div> */}


          </div>
          <div className="col-12 btn-toolbar p-0 d-none justify-content-between align-items-center" role="toolbar">

            <Applyfilter updateProductList={updateProductList} getproductlist={getproductlist} url="filterinstore"></Applyfilter>

            <div className="btn-group" role="group" style={{ width: "37%" }}>
              <select type="button" className="btn btn-secondary w-100 " style={{ fontSize: "14px", margin: "5px 0px" }} onChange={handleSort}              >
                <option value="" disabled selected>Sort</option>
                <option value="0">Low - High</option>
                <option value="1">High - Low</option>
              </select>
            </div>
          </div>

          {/* ------------------------------ search & filter end ---------------------------------------- */}
          {selectedBox.length > 0 ? <div className="d-flex w-100 justify-content-start mt-2" style={{ gap: "10px" }}>
            <span className="functionalButtons" onClick={() => {
              let z = productlist.filter(item => item);
              let m = z.map((item) => item?.id);
              setselectedBox(m)
            }} >Select All</span>
            <span className="functionalButtons" onClick={() => setselectedBox([])} >Clear</span>
            <span className="functionalButtons " onClick={() => { setConfirmDeleteModalOpen(true) }}>Delete
              {/* <DeleteIcon style={{width: "19px", height: "19px", color: "#ff1111"}} /> */}
            </span>
          </div> : null}

          {/* -------------------------------------------- product mapping start ----------------------------- */}
          {productisLoading ?
            <Circularprogress />
            : <> {!noproducts ? <> {currentItems?.map((item, index) => {
              {/* const images =  item.image.split(",") */ }
              const idpresent = selectedBox.some((ids) => ids === item.id)

              return (
                <div className="d-flex w-100  my-2 py-2"
                  style={{ gap: "3px", position: "relative", background: "white", padding: "4px", }}
                  key={item?.id}  >

                  <div className="d-flex align-items-start p-2">
                    <input type="checkbox" style={{ cursor: "pointer", width: "15px", height: "15px" }} checked={idpresent} onChange={() => checkHandler(item)} />
                  </div>
                  <ImageContainer>
                    {/* <img src={item?.has_variations ? item?.proImage?.split(",")[0] : item?.image?.split(",")[0]} alt="" className="w-100" /> */}
                    <img src={item?.has_variations === true || item?.has_variations ? item?.proImage?.split(",")[0] : item.image?.split(",")[0]} alt="" className="w-100" />
                  </ImageContainer>
                  <div className="prod_desc flex-column">
                    <p className="m-0"><span className="genericlabel">{item?.name}</span> </p>
                    <p className="m-0"> <span className="genericlabel">  Product ID :</span>  {item?.ondc_product_id}</p>
                    {item?.cat_name ? <p className="m-0"> <span className="genericlabel">Category : </span> {item?.cat_name ? item?.cat_name : ""} </p> : " "}
                    <p className="m-0"> <span className="genericlabel"> Mrp : </span> Rs {item.has_variations === true ? item?.price : item?.mrp}</p>
                    <p className="m-0"> <span className="genericlabel">Selling Price :</span> Rs {item?.selling_price ? item?.selling_price : item?.selprice} </p>
                    {/* <p className="m-0"> <span className="genericlabel">Transfer Price : </span> {item.transfer_price} </p> */}
                    <p className="m-0"> <span className="genericlabel">Variations :</span>  {item.has_variations === true ? "Available" : "No Variants"} </p>
                    <span className="genericlabel">Quantity : <span style={{ fontWeight: "500" }}> {item?.has_variations === true ? item?.proquantity : item.quantity} </span> </span>
                    <span className="genericlabel" >  <> <span >Reference Id:</span> <span style={{ fontWeight: "500" }}>{item?.has_variations === true ? item?.ref_id : item.reference_id}</span>  </> </span>

                    <br />
                    {product?.update ? <div
                    className={`${styles.editContainerMobile}`}
                    style={{ top: "3px", right: "5px" }}
                  >
                    <label className="switch">
                      <input type="checkbox" checked={itemCheckboxes[item.id]} onChange={async (e) => {
                        const itemId = item.id;
                        const isChecked = !itemCheckboxes[item.id];
                        setItemCheckboxes({ ...itemCheckboxes, [itemId]: isChecked });
                        const { data } = await axios({
                          url: `${apiEndpoint}/changeproductstatus`,
                          method: "POST",
                          headers: {
                            Authorization: `Bearer ${sessionStorage.getItem(
                              "us_st_d"
                            )}`,
                            "Content-type": "application/json",
                          },
                          data: { product_id: itemId, isChecked: isChecked },
                        });

                      }}
                      />
                      <span className="slider round"></span>
                    </label>

                    {/* <DeleteIcon style={{ color: "red", cursor: "pointer", fontSize: "30px" }} onClick={() => { setConfirmDeleteModalOpen(true); setProductToDelete(item) }}></DeleteIcon> */}
                    <DeleteIcon className={`${styles.productDeleteIcon}`} onClick={() => { setConfirmDeleteModalOpen(true); setProductToDelete(item) }}></DeleteIcon>
                    <Link to={`createproduct/${item.id}?q=${item.has_variations}`}> <img src={edit} alt="" className={`${styles.productEditIcon}`} /></Link>
                    <QrCode2Icon onClick={() => { getQRHandler(item) }} style={{ }} />

                    {/* <span>Delete</span> */}

                    {/* <img
                      src={edit}
                      alt=""
                      onClick={(e) => {
                        e.preventDefault();
                        maxPriceHandler(item.id);
                      }}
                    /> */}

                    <div style={{ display: "none" }}>
                      <Link to={`/updatestoreproduct/${item.id}`}>
                        <div>
                          <img src={editfull} alt="" />
                        </div>
                      </Link>
                    </div>
                  </div> : null}
                    {/* <Link to={`addinstoreproduct/${item.id}?q=${item.has_variations}`}>Check Product</Link> */}
                    <br />
                  </div>
                  {product?.update ? <div
                    className={`${styles.editContainer}`}
                    style={{ top: "3px", right: "5px" }}
                  >
                    <label className="switch">
                      <input type="checkbox" checked={itemCheckboxes[item.id]} onChange={async (e) => {
                        const itemId = item.id;
                        const isChecked = !itemCheckboxes[item.id];
                        setItemCheckboxes({ ...itemCheckboxes, [itemId]: isChecked });
                        const { data } = await axios({
                          url: `${apiEndpoint}/changeproductstatus`,
                          method: "POST",
                          headers: {
                            Authorization: `Bearer ${sessionStorage.getItem(
                              "us_st_d"
                            )}`,
                            "Content-type": "application/json",
                          },
                          data: { product_id: itemId, isChecked: isChecked },
                        });

                      }}
                      />
                      <span className="slider round"></span>
                    </label>

                    {/* <DeleteIcon style={{ color: "red", cursor: "pointer", fontSize: "30px" }} onClick={() => { setConfirmDeleteModalOpen(true); setProductToDelete(item) }}></DeleteIcon> */}
                    <DeleteIcon style={{ color: "red", cursor: "pointer", fontSize: "30px" }} onClick={() => { setConfirmDeleteModalOpen(true); setProductToDelete(item) }}></DeleteIcon>
                    <Link to={`createproduct/${item.id}?q=${item.has_variations}`}> <img src={edit} alt="" style={{ width: "32px", height: "32px" }} /></Link>
                    <QrCode2Icon onClick={() => { getQRHandler(item) }} style={{ cursor: "pointer", fontSize: "30px" }} />

                    {/* <span>Delete</span> */}

                    {/* <img
                      src={edit}
                      alt=""
                      onClick={(e) => {
                        e.preventDefault();
                        maxPriceHandler(item.id);
                      }}
                    /> */}

                    <div style={{ display: "none" }}>
                      <Link to={`/updatestoreproduct/${item.id}`}>
                        <div>
                          <img src={editfull} alt="" />
                        </div>
                      </Link>
                    </div>
                  </div> : null}
                </div>
              );
            })}</> : <p className="w-100" style={{ fontSize: "16px", color: "black", fontWeight: "600", textAlign: "center" }}>No such products</p>} </>}

          {/* -------------------------------------------- product mapping end ---------------------------------------- */}

          {/* -------------------------------------------- pagination start ---------------------------------------- */}

          {!productisLoading && <div className='pagination_main'>
            <ReactPaginate breakLabel="..." nextLabel=">" onPageChange={handlePageClick} pageRangeDisplayed={1} marginPagesDisplayed={1} pageCount={pageCount} previousLabel="<" renderOnZeroPageCount={null} activeLinkClassName="current_active" />

          </div>}
          {/* -------------------------------------------- pagination end ---------------------------------------- */}

        </div>

        <Modal open={open} onClose={handleSyncModalClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description"
        >
          <Box sx={syncmodalstyle}>
            <div>
              <p className="text-center" style={{ fontSize: "20px", color: 'black', fontWeight: "500" }}>Sync Inventory</p>
            </div>

            <div>
              <div className="syncContainers mt-3">
                <input type="checkbox" name="price" checked={syncingData.price} onChange={syncCheckHandler} />  <label htmlFor="" className="p-0 m-0" >Update Price</label>
              </div>
              <div className="syncContainers mt-3" >
                <input type="checkbox" name="inventory" checked={syncingData.inventory} onChange={syncCheckHandler} />  <label htmlFor="" className="p-0 m-0" >Update Quantity</label>
              </div>
              <div className="syncContainers mt-3">
                <input type="checkbox" name="title" checked={syncingData.title} onChange={syncCheckHandler} />  <label htmlFor="" className="p-0 m-0" >Update Title</label>
              </div>
              <div className="syncContainers mt-3" >
                <input type="checkbox" disabled name="sku" checked={syncingData.sku} onChange={syncCheckHandler} />  <label htmlFor="" className="p-0 m-0" >Update SKU</label>
              </div>
              <div className="syncContainers mt-3">
                <input type="checkbox" disabled name="weight" checked={syncingData.weight} onChange={syncCheckHandler} />  <label htmlFor="" className="p-0 m-0">Update Weight</label>
              </div>
            </div>

            <div className="mt-3 d-flex justify-content-end">
              {/* <button className="btn btn-primary" onClick={() => syncPriceHandler()}>Sync</button> */}
              <button className="btn btn-primary" onClick={handleAlertOpen}>Sync</button>
            </div>

          </Box>
        </Modal>


        <Dialog
          open={Alertopen}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleAlertClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Are you sure?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              On Clicking Agree, selected parameter will be updated for the products.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAlertClose}>Disagree</Button>
            <button onClick={syncPriceHandler} className="btn btn-danger"> {syncing ? "Syncing" : "Agree"} </button>
          </DialogActions>
        </Dialog>


        {/* -------------------------------------DELETE CONFIRMATION MODAL-------------------------------------- */}
        <Modal
          open={ConfirmDeleteModalOpen}
          onClose={handleShipementModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle} className="col-md-4 ">

            <div className="modal-content " >
              <div className="modal-header p-2 d-flex align-items-center justify-content-between">
                <p className="modal-title genericlabel  " style={{ letterSpacing: "1px" }}>Delete Product..</p>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span style={{ cursor: "pointer" }} ><CloseIcon onClick={handleShipementModalClose} /></span>
                </button>
              </div>
              <div className="modal-body">
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <ReportProblemRoundedIcon style={{ fontSize: "40px", color: '#dd3737' }} />
                  <h5 style={{ fontWeight: '700' }}> Products once deleted can't be restored.
                    <h6 className="text-center font-weight-bold mt-1">Are you sure?</h6>
                  </h5>
                </div>
              </div>
              <div className="modal-footer">
                {selectedBox.length > 0 ? <button className="btn btn-danger" onClick={() => { bulkdeleteHandler() }} > Bulk Delete </button> : <button className="btn btn-danger" onClick={() => { deleteProductHandler(productToDelete) }} > Delete </button>}
              </div>
            </div>
          </Box>
        </Modal>

        {/* -------------------------------------QR MODAL-------------------------------------- */}
        <Modal
          open={QRModalOpen.openstatus}
          onClose={handleQRModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...modalStyle, width: 300 }} className="col-md-3 ">

            <div className="modal-content  position-relative" >
              <div className="modal-header p-2 d-flex align-items-center justify-content-between">
                <p className="modal-title genericlabel  " style={{ letterSpacing: "1px" }}>Product QR..</p>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span style={{ cursor: "pointer" }} ><CloseIcon onClick={() => setQRModalOpen({ ...QRModalOpen, openstatus: false, isLoading: false })} /></span>
                </button>
              </div>
              {QRModalOpen?.isLoading === false ? <div className="modal-body">
                <div className="d-flex flex-column justify-content-center align-items-center ">
                  <div ref={qrRef} className="p-3" style={{ background: "white" }}>
                    <QRCode
                      // size={100}
                      style={{
                        height: 'auto',
                        maxWidth: '100%',
                        width: '100%',
                      }}
                      value={QRModalOpen.value}
                    // viewBox="0 0 50 100"
                    />
                  </div>

                  <p className="genericlabel mt-2">Scan QR to check store.</p>
                  <DownloadForOfflineRoundedIcon onClick={handleDownload} style={{ fontSize: "40px", position: "absolute", bottom: "5px", right: "5px", color: "#105293", cursor: "pointer" }} titleAccess="Save QR" />

                </div>
              </div> : <p className="p-4 text-center">Loading...</p>}

            </div>
          </Box>
        </Modal>
        {/* -------------------------------------QR MODAL-------------------------------------- */}
      </>
    </ThemeMaker>
  );
};

export default Instorecatalog;
