import { Link } from "react-router-dom";
export default function Onboarding() {
  let z = sessionStorage.getItem("showthank");
  console.log(z);
  if (sessionStorage.getItem("showthank")) {
    sessionStorage.removeItem("showthank");
  }

  return (
    <div className="d-flex justify-content-center w-100 flex-column align-items-center">
      {/* Already Present  HUL-410530D-P43751356 */}
      {/* Already Present:- HUL-410530D-P43750010 */}
      {/* Already  Present:- HUL-410530D-P43752121 */}
      {/* <Link to="/checkseller?hulid=HUL-410530D-P00010773"><button className='btn btn-success mt-3'>Click Here to go to Shikhar App</button></Link> */}

      {/* OLD */}
      <Link to="/sellerauthcheck?HULID=DEMO-14&ACCESSTOKEN=eyJhbGciOiJIUzI1NiJ9.eyJkYXRhIjoiI3tjaGVja3BhcmFtfSIsImV4cCI6MTU5NDY0MDY3NzA2fQ.0QjbFM17KftX_7ZO1dBX3rwNH3GpwFeszK4SHzl53Gw">
        <button className="btn btn-success mt-3">
          Click Here to go to Shikhar App
        </button>
      </Link>

      {/* NEW */}
      {/* <Link to="/sellerauthcheck?HULID=DEMO-U1HAR2045&ACCESSTOKEN=eyJhbGciOiJIUzI1NiJ9.eyJkYXRhIjoiI3tjaGVja3BhcmFtfSIsImV4cCI6MTU5NDQ5Nzk3OTk2fQ.ZiBOd9FScfdwu3qWvDa9fIoIKrVpaeRZr12ChNBZXBk"><button className='btn btn-success mt-3'>Click Here to go to Shikhar App</button></Link>

      <Link to="/sellerauthcheck?HULID=DEMO-15&ACCESSTOKEN=eyJhbGciOiJIUzI1NiJ9.eyJkYXRhIjoiI3tjaGVja3BhcmFtfSIsImV4cCI6MTU5NDY0MDY3NzA2fQ.0QjbFM17KftX_7ZO1dBX3rwNH3GpwFeszK4SHzl53Gw"><button className='btn btn-success mt-3'>Click Here to go to Shikhar App</button></Link>
     */}
    </div>
  );
}
